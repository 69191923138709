import React from "react";

import { Route, Switch } from "react-router-dom";

import RedirectHome from "./components/Pages/RedirectHome";
import LoginPage from "./containers/LoginPage/index";
import AuthWrapper from "./hoc/AuthWrapper";

class Routes extends React.Component {
  render() {
    return (
      <div className="page-wrapper">
        {!this.props.online ? <span className="offline">You're Offline</span> : null}
        <Switch>
          <Route exact path="/" component={RedirectHome} />
          <Route exact path="/login" component={LoginPage} />
          <Route component={AuthWrapper} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
