import axios from "axios";
import * as constant from "./constants";
import { dispatchPromise, dispatchInit, dispatchError, dispatchSuccess } from "../../../utils";
import { saveDataInStore, getDataFromStore, clearObjectStore } from "../../../offline/db";
import { OBJECTSTORE_CODE_LIST_TYPES, assignCodeListTypeToLocalStore } from "../../../offline/os/common/codeListTypes";
import { USER_UPDATE } from "../../LoginPage/constants";

export const fetchCodeListTypes = (user, parentProjectId) => dispatch => {
  // Initiating action which will display the loader image on UI
  dispatchInit(dispatch, constant.CODE_LIST_TYPE_FETCH_INIT);
  // Generating API urls to get the Code list types
  // We then fetch the RoQ price list by parentProjectId instead project id.
  let codeListTypesApiUrl = `/roqpricelists?projectId=${parentProjectId}&expand=codelisttypeName&forUserRegistration=true`;
  // axios class which will execute API and execute a promise on success.
  axios
    .get(codeListTypesApiUrl)
    .then(res => {
      let codeListTypes = res.data;
      codeListTypes = codeListTypes.map(x => x = { ...x, name: x.codeListTypeName });
      // Save the Code list types to the offline store.
      clearObjectStore(OBJECTSTORE_CODE_LIST_TYPES).then(() => saveDataInStore(OBJECTSTORE_CODE_LIST_TYPES, codeListTypes));
      // List to bind drop-down
      dispatchPromise(dispatch, constant.CODE_LIST_TYPE_FETCH_SUCCESS, codeListTypes)
        .then(assignCodeListTypeToLocalStore(user.codeListTypeId, codeListTypes, dispatch))
    })
    .catch(error => {
      // API fails to succeed request, so fetch Code list types from offline store and update the component's Redux store to map it on the UI.
      getDataFromStore(OBJECTSTORE_CODE_LIST_TYPES)
        .then(codeListTypes => {
          // List to bind drop-down
          dispatchPromise(dispatch, constant.CODE_LIST_TYPE_FETCH_SUCCESS, codeListTypes)
            .then(assignCodeListTypeToLocalStore(user.codeListTypeId, codeListTypes, dispatch))
        })
        .catch(err => dispatchError(dispatch, constant.CODE_LIST_TYPE_EXCEPTION_WHILE_FETCH, error));
    })
};

export const updateSelectedCodeListType = (user, selectedCodeListType) => dispatch => {
  // Initiate API call
  dispatchInit(dispatch, constant.CODE_LIST_TYPE_UPDATE_INIT);
  // Prepare API Url to update user's selected code list type
  let url = `/users/${user.id}`;
  axios
    .patch(url, { codeListTypeId: selectedCodeListType.codeListTypeId })
    .then(() => dispatch(updateUser(user)))
    .catch(error => dispatchError(dispatch, constant.CODE_LIST_TYPE_EXCEPTION_WHILE_UPDATE, error));
};

export const updateUser = (user) => dispatch => {
  // Prepare API Url to update user's info
  let url = `/users/${user.id}?expand=CompanyId,CompanyName,TeamId,TeamName,Roles,CodeListTypeId,Modules,LanguageCode`;
  axios
    .get(url)
    .then((res) => {
      // Update the logged-in user's information
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatchPromise(dispatch, USER_UPDATE, res.data)
        // Update user's code list type
        .then(() => dispatchSuccess(dispatch, constant.CODE_LIST_TYPE_UPDATE_SUCCESS, res.data.codeListTypeId));
    })
    .catch(error => dispatchError(dispatch, constant.CODE_LIST_TYPE_EXCEPTION_WHILE_UPDATE, error));
};



