import { CODE_LIST_TYPE_SELECT } from "../../../containers/ModuleInitials/CodeListType/constants";

export const OBJECTSTORE_CODE_LIST_TYPES = "CodeListTypes";

/*
 * Match the code list type id fetched in the user api with the code list type API
 * If we find a match then store in local storage, else store empty ({})
 * id: codelisttypeId
 * list: list of codelisttypes return by API
 * dispatch: redux object
 */
export const assignCodeListTypeToLocalStore = (id, list, dispatch) => {
    let userCodeListType = {}
    if (list.some(codeType => codeType.codeListTypeId === id)) {
        userCodeListType = list.filter(codeType => codeType.codeListTypeId === id)[0];
    }
    dispatch({
        type: CODE_LIST_TYPE_SELECT,
        payload: userCodeListType
    });
}
