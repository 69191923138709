import * as constant from "./constants";
import axios from "axios";

import { makeOfflineId, dispatchPromise, dispatchInit, dispatchError, dispatchSuccess } from "../../utils";
import { getDetails, saveDetails, updateDetailsOffline } from "../../offline/os/installationPlan/details";
import { saveActivities, getActivities, updateActivitiesOffline } from "../../offline/os/installationPlan/activities";
import {
  saveImagesMeta,
  getImagesMeta,
  updateImagesMetaOffline,
  updateImagesMetaIdOffline,
} from "../../offline/os/installationPlan/images";
import { saveImage, getImage, deleteImageOffline } from "../../offline/os/installationPlan/downloadImages";

export const fetchDetails = (instPlan_id) => (dispatch) => {
  let url = `/installationplans/${instPlan_id}`;
  // instPlans fetch init (loader)
  dispatchInit(dispatch, constant.DETAILS_FETCH_INIT);

  axios
    .get(url)
    .then((res) => dispatchPromise(dispatch, constant.DETAILS_FETCH_SUCCESS, res.data))
    .then((data) => {
      // save offline
      saveDetails(instPlan_id, data).catch((e) => {});
    })

    .catch((error) => {
      // on network fail: fetch offline
      getDetails(instPlan_id)
        .then((data) => dispatchSuccess(dispatch, constant.DETAILS_FETCH_SUCCESS, data))
        .catch((err) => dispatchError(dispatch, constant.DETAILS_FETCH_ERROR, error));
    });
};

export const updateDetails = (instPlan_id, patchObj) => (dispatch) => {
  let url = `/installationplans/${instPlan_id}`;
  let headers = { "if-match": `"*"` };
  let headerObj = { headers };
  dispatchInit(dispatch, constant.DETAILS_UPDATE_INIT);

  return new Promise((resolve, reject) => {
    axios
      .patch(url, patchObj, headerObj)
      .then((res) => dispatchPromise(dispatch, constant.DETAILS_UPDATE_SUCCESS, res.data))
      .then((data) => {
        updateDetailsOffline(instPlan_id, patchObj);
      })
      .then(resolve)
      .catch((error) => {
        updateDetailsOffline(instPlan_id, patchObj)
          .then((data) => dispatchPromise(dispatch, constant.DETAILS_UPDATE_SUCCESS, data))
          .then(resolve)
          .catch((err) => dispatchError(dispatch, constant.DETAILS_UPDATE_ERROR, error));
      });
  });
};

export const fetchActivities = (instPlan_id) => (dispatch) => {
  let url = `/installationplans/${instPlan_id}/installationactivities`;
  dispatchInit(dispatch, constant.ACTIVITIES_FETCH_INIT);
  axios
    .get(url)
    .then((res) => dispatchPromise(dispatch, constant.ACTIVITIES_FETCH_SUCCESS, res.data))
    .then((data) => {
      // save offline
      saveActivities(instPlan_id, data).catch((e) => {});
    })
    .catch((error) => {
      // on network fail: fetch offline
      getActivities(instPlan_id)
        .then((data) => dispatchSuccess(dispatch, constant.ACTIVITIES_FETCH_SUCCESS, data))
        .catch((err) => dispatchError(dispatch, constant.ACTIVITIES_FETCH_ERROR, error));
    });
};

export const updateActivities = (instPlan_id, activity_id, patchObj, index) => (dispatch) => {
  console.log(instPlan_id, activity_id, patchObj, index);
  let url = `/installationplans/${instPlan_id}/installationactivities/${activity_id}`;
  let headers = { "if-match": `"*"` };
  let headerObj = { headers };
  dispatchInit(dispatch, constant.ACTIVITY_UPDATE_INIT);

  axios
    .patch(url, patchObj, headerObj)
    .then((res) => dispatchPromise(dispatch, constant.ACTIVITY_UPDATE_SUCCESS, res.data))
    .then((res) => dispatchPromise(dispatch, constant.ACTIVITY_UPDATE_SUCCESS, res.data))
    .then((data) => {
      // save offline
      dispatch(fetchActivities(instPlan_id));
      updateActivitiesOffline(instPlan_id, patchObj);
    })
    .catch((error) => {
      updateActivitiesOffline(instPlan_id, activity_id, patchObj)
        .then((data) => dispatchPromise(dispatch, constant.ACTIVITY_UPDATE_SUCCESS, data))
        .then((data) => dispatch(fetchActivities(instPlan_id)))
        .catch((err) => {
          dispatch({
            type: constant.ACTIVITY_UPDATE_ERROR,
            payload: error,
          });
        });
    });
};

export const fetchImagesMeta = (instPlan_id) => (dispatch) => {
  let url = `/installationplans/${instPlan_id}/images`;
  dispatchInit(dispatch, constant.IMAGES_FETCH_INIT);
  axios
    .get(url)
    .then((res) => dispatchPromise(dispatch, constant.IMAGES_FETCH_SUCCESS, res.data))
    .then((data) => {
      saveImagesMeta(instPlan_id, data).catch((e) => {});
    })
    .catch((error) => {
      // on network fail: fetch offline
      getImagesMeta(instPlan_id)
        .then((data) => dispatchSuccess(dispatch, constant.IMAGES_FETCH_SUCCESS, data))
        .catch((err) => dispatchError(dispatch, constant.IMAGES_FETCH_ERROR, error));
    });
};

export const updateImageGroupNote = (instPlan_id, imgGrp_id, updateObj, mainIndex, sectionIndex, groupIndex) => (
  dispatch
) => {
  let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}`;
  dispatchInit(dispatch, constant.UPDATE_IMAGES_INIT);

  return new Promise((resolve, reject) => {
    axios
      .patch(url, updateObj)
      .then((res) => dispatchPromise(dispatch, constant.UPDATE_IMAGES_SUCCESS, res.data))
      .then((data) => {
        // save offline
        updateImagesMetaOffline(instPlan_id, mainIndex, sectionIndex, groupIndex, updateObj);
        return resolve();
      })
      .catch((error) => {
        updateImagesMetaOffline(instPlan_id, mainIndex, sectionIndex, groupIndex, updateObj)
          .then((data) => dispatchPromise(dispatch, constant.UPDATE_IMAGES_SUCCESS, data))
          .then(resolve)
          .catch((err) => dispatchPromise(dispatch, constant.UPDATE_IMAGES_ERROR, error));
      });
  });
};

export const fetchImage = (instPlan_id, imgGrp_id, img_id) => (dispatch) => {
  let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}/${img_id}`;
  dispatchInit(dispatch, constant.IMAGE_FETCH_INIT);
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => dispatchPromise(dispatch, constant.IMAGE_FETCH_SUCCESS, res.data))
      .then((data) => {
        // save offline
        saveImage(instPlan_id, imgGrp_id, img_id, data, true).catch((e) => {});
        return resolve(data);
      })
      .catch((error) => {
        // on network fail: fetch offline
        getImage(instPlan_id, imgGrp_id, img_id)
          .then((data) => dispatchPromise(dispatch, constant.IMAGE_FETCH_SUCCESS, data))
          .then(resolve)
          .catch((err) => dispatchError(dispatch, constant.IMAGE_FETCH_ERROR, error));
      });
  });
};

export const addImage = (instPlan_id, imgGrp_id, imageData, mainIndex, sectionIndex, groupIndex) => (dispatch) => {
  dispatchInit(dispatch, constant.ADD_IMAGE_INIT);
  let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}`;
  let headers = { "Content-Type": "application/json" };
  let headerObj = { headers };
  let postObj = `"${imageData}"`;

  return new Promise((resolve, reject) => {
    axios
      .post(url, postObj, headerObj)
      .then((res) => dispatchPromise(dispatch, constant.IMAGE_FETCH_SUCCESS, res.data))
      .then((img_id) => {
        // save offline
        saveImage(instPlan_id, imgGrp_id, img_id, imageData, true)
          .then((data) => updateImagesMetaIdOffline(instPlan_id, mainIndex, sectionIndex, groupIndex, img_id))
          .catch((e) => {});
        return resolve(img_id);
      })
      .catch((error) => {
        let img_id = makeOfflineId();
        saveImage(instPlan_id, imgGrp_id, img_id, imageData, false).then((data) => {
          updateImagesMetaIdOffline(instPlan_id, mainIndex, sectionIndex, groupIndex, img_id);
          return dispatchPromise(dispatch, constant.ADD_IMAGE_SUCCESS, data)
            .then(resolve)
            .catch((err) => dispatchPromise(dispatch, constant.ADD_IMAGE_ERROR, error));
        });
      });
  });
};

export const updateImage = (instPlan_id, imgGrp_id, img_id, imageData) => (dispatch) => {
  dispatchInit(dispatch, constant.UPDATE_IMAGE_INIT);
  let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}/${img_id}`;
  let headers = { "Content-Type": "application/json" };
  let headerObj = { headers };
  let putObj = `"${imageData}"`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, putObj, headerObj)
      .then((res) => dispatchPromise(dispatch, constant.UPDATE_IMAGE_SUCCESS, res.data))
      .then((data) => {
        // save offline
        saveImage(instPlan_id, imgGrp_id, img_id, imageData, true).catch((e) => {});
        return resolve(data);
      })
      .catch((err) => {
        // on network fail: fetch offline
        saveImage(instPlan_id, imgGrp_id, img_id, imageData, false)
          .then(dispatchPromise(dispatch, constant.UPDATE_IMAGE_SUCCESS))
          .then(resolve)
          .catch((err) => dispatchPromise(dispatch, constant.UPDATE_IMAGE_ERROR, err));
      });
  });
};

export const deleteImage = (instPlan_id, imgGrp_id, img_id) => (dispatch) => {
  dispatchInit(dispatch, constant.DELETE_IMAGE_INIT);
  let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}/${img_id}`;
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((res) => dispatchPromise(dispatch, constant.DELETE_IMAGE_SUCCESS, res.data))
      .then((data) => {
        deleteImageOffline(instPlan_id, imgGrp_id, img_id);
        return resolve(data);
      })
      .catch((error) => {
        deleteImageOffline(instPlan_id, imgGrp_id, img_id)
          .then((data) => dispatchPromise(dispatch, constant.DELETE_IMAGE_SUCCESS, data))
          .then(resolve)
          .catch((err) => dispatchError(dispatch, constant.DELETE_IMAGE_ERROR, error));
      });
  });
};
