/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const DETAILS_FETCH_INIT = "DetailsPage/DETAILS_FETCH_INIT";
export const DETAILS_FETCH_SUCCESS = "DetailsPage/DETAILS_FETCH_SUCCESS";
export const DETAILS_FETCH_ERROR = "DetailsPage/DETAILS_FETCH_ERROR";

export const DETAILS_UPDATE_INIT = "DetailsPage/DETAILS_UPDATE_INIT";
export const DETAILS_UPDATE_SUCCESS = "DetailsPage/DETAILS_UPDATE_SUCCESS";
export const DETAILS_UPDATE_ERROR = "DetailsPage/DETAILS_UPDATE_ERROR";

export const ACTIVITIES_FETCH_INIT = "DetailsPage/ACTIVITIES_FETCH_INIT";
export const ACTIVITIES_FETCH_SUCCESS = "DetailsPage/ACTIVITIES_FETCH_SUCCESS";
export const ACTIVITIES_FETCH_ERROR = "DetailsPage/ACTIVITIES_FETCH_ERROR";

export const ACTIVITY_UPDATE_INIT = "DetailsPage/ACTIVITY_UPDATE_INIT";
export const ACTIVITY_UPDATE_SUCCESS = "DetailsPage/ACTIVITY_UPDATE_SUCCESS";
export const ACTIVITY_UPDATE_ERROR = "DetailsPage/ACTIVITY_UPDATE_ERROR";

export const IMAGES_FETCH_INIT = "DetailsPage/IMAGES_FETCH_INIT";
export const IMAGES_FETCH_SUCCESS = "DetailsPage/IMAGES_FETCH_SUCCESS";
export const IMAGES_FETCH_ERROR = "DetailsPage/IMAGES_FETCH_ERROR";

export const UPDATE_IMAGES_INIT = "DetailsPage/UPDATE_IMAGES_INIT";
export const UPDATE_IMAGES_SUCCESS = "DetailsPage/UPDATE_IMAGES_SUCCESS";
export const UPDATE_IMAGES_ERROR = "DetailsPage/UPDATE_IMAGES_ERROR";

export const IMAGE_FETCH_INIT = "DetailsPage/IMAGE_FETCH_INIT";
export const IMAGE_FETCH_SUCCESS = "DetailsPage/IMAGE_FETCH_SUCCESS";
export const IMAGE_FETCH_ERROR = "DetailsPage/IMAGE_FETCH_ERROR";

export const ADD_IMAGE_INIT = "DetailsPage/ADD_IMAGE_INIT";
export const ADD_IMAGE_SUCCESS = "DetailsPage/ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_ERROR = "DetailsPage/ADD_IMAGE_ERROR";

export const UPDATE_IMAGE_INIT = "DetailsPage/UPDATE_IMAGE_INIT";
export const UPDATE_IMAGE_SUCCESS = "DetailsPage/UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_ERROR = "DetailsPage/UPDATE_IMAGE_ERROR";

export const DELETE_IMAGE_INIT = "DetailsPage/DELETE_IMAGE_INIT";
export const DELETE_IMAGE_SUCCESS = "DetailsPage/DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_ERROR = "DetailsPage/DELETE_IMAGE_ERROR";
