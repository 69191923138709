import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { selectModule } from "../Home/actions";
import { withTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import Tabs from "../../components/Tabs";
import { checkEmptyObject, getDateInFormat, addDays } from "../../utils";
import SiteForm from "./Forms/SiteForm";
import {
    selectProject,
    fetchQuantityReportingDetails,
    fetchProjectAreaSections,
    fetchCodeGroupsCodes,
    insertQuntityEntry,
    deleteQuntityEntry,
    fetchQuantityReportingEntries,
    fetchPeriodTotals,
    updateOfflinePeriodTotals,
    updatePriceCodeListEmptyAlert
} from "./actions";
import DetailTabGridContext from "./DetailTabGridContext";
import PeriodTotalsTabContext from "./PeriodTotalsTabContext";
import { toast } from "react-toastify";
import { ROQ_WEEKLY_TOTAL_ADD, ROQ_WEEKLY_TOTAL_DELETE } from "./constants";
import AlertBox from "../../components/AlertBox";
const periodDays = 7;

class QuantityReporting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            selectedDate: "",
            selectedProjectArea: {},
            selectedProjectAreaSection: {},
            selectedCodeGroup: {},
            selectedCodeGroupCode: {},
            quantity: "",
            notes: "",
            disableToInsert: true,
            selectedDateForPeriodTotals: ""
        };
    }
    componentDidMount() {
        let { customerProject, user, t } = this.props;

        // Date control will always show the current date on component initial load
        let { selectedDate } = this.state;
        let today = getDateInFormat();
        let componentDate = today;
        if (selectedDate !== "") {
            componentDate = selectedDate;
        }

        // Loading the detail tab data on component load
        this.props.fetchQuantityReportingDetails(user.companyId, user.teamId, customerProject.parentProjectId, customerProject.id, user.codeListTypeId, componentDate, t);
        this.props.fetchPeriodTotals(user.teamId, customerProject.id, componentDate);
        this.setState({ selectedDate: componentDate, selectedDateForPeriodTotals: componentDate });
    }
    handleOnToggle = (tab) => {
        let { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
        if (tab === 2) {
            let { customerProject, user, online } = this.props;
            let { selectedDate } = this.state;
            // The date for the period, total always resets with current selected date in detail tab
            this.setState({ selectedDateForPeriodTotals: selectedDate });
            // When user is offline, then always display current week totals
            let periodDate = selectedDate
            if (!online) {
                periodDate = getDateInFormat();
            }
            this.props.fetchPeriodTotals(user.teamId, customerProject.id, periodDate);
        }
    };
    handleOnDateSelect = (date) => {
        let { customerProject, user } = this.props;
        // Loading the detail tab data on date change
        this.props.fetchQuantityReportingEntries(user.companyId, user.teamId, customerProject.id, user.codeListTypeId, date);
        this.setState({ selectedDate: date });
    };
    handleOnProjectAreaSelect = (projectArea) => {
        this.setState({ selectedProjectArea: projectArea, selectedProjectAreaSection: {} });
        // As per requirement, we could have area without sections
        if (projectArea.hasSections) {
            this.props.fetchProjectAreaSections(projectArea.id);
        }
    };
    handleOnProjectAreaSectionSelect = (projectAreaSection) => {
        this.setState({ selectedProjectAreaSection: projectAreaSection });
    };
    handleOnCodeGroupSelect = (codeGroup) => {
        this.setState({ selectedCodeGroup: codeGroup, selectedCodeGroupCode: {} });
        this.props.fetchCodeGroupsCodes(codeGroup.id);
    };
    handleOnCodeGroupCodeSelect = (code) => {
        this.setState({ selectedCodeGroupCode: code });
    };
    handleOnUpdateInputs = (ctrlName, ctrlValue) => {
        if (ctrlName === "txtQuantity") {
            this.setState({ quantity: ctrlValue, disableToInsert: ctrlValue === "" });
        }
        if (ctrlName === "txtNotes") {
            this.setState({ notes: ctrlValue });
        }
    };
    handleOnInsert = () => {
        let { t, online } = this.props;
        let { projectAreas, isLocked } = this.props.local;
        let { selectedDate, selectedProjectArea, selectedProjectAreaSection, selectedCodeGroupCode, quantity, notes } = this.state;
        /*
        * Validations
        * 1. User needs to select project area and project area section if the list is available for the selected project.
        * 2. User needs to select a code from the list.
        * 3. There are some codes which are listed on the map, So for them user also need to add notes.
        * 4. When selected day is locked, then user is not allowed to insert a quantity.
        */
        if (Object.entries(projectAreas).length > 0) {
            if (checkEmptyObject(selectedProjectArea)) {
                toast.error(`${t("text.Invalid")} ${t("label.Area")}!`);
                return false;
            }
            else {
                if (selectedProjectArea.hasSections && checkEmptyObject(selectedProjectAreaSection)) {
                    toast.error(`${t("text.Invalid")} ${t("label.Section")}!`);
                    return false;
                }
            }
        }
        if (checkEmptyObject(selectedCodeGroupCode)) {
            toast.error(`${t("text.Invalid")} ${t("label.Code")}!`);
            return false;
        }
        else {
            if (selectedCodeGroupCode.mapIntegration && notes.trim() === "") {
                toast.error(`${t("text.Invalid")} ${t("label.Notes")}!`);
                return false;
            }
        }
        if (isLocked) {
            toast.error(t("text.Week_locked"));
            return false;
        }

        let { customerProject, user } = this.props;
        let newEntry = {
            id: Math.floor(1000000 + Math.random() * 9000000),
            companyId: user.companyId,
            projectId: customerProject.id,
            teamId: user.teamId,
            codeListTypeId: user.codeListTypeId,
            roqCodeId: selectedCodeGroupCode.roqCodeId,
            roqCodeName: selectedCodeGroupCode.roqCodeName,
            roqCodeUnitCode: selectedCodeGroupCode.roqCodeUnitCode,
            roqCodeUnitName: selectedCodeGroupCode.roqCodeUnitName,
            quantity: quantity,
            date: getDateInFormat(selectedDate),
            notes: notes,
            isDeletableByUser: true,
            isOffline: false,
            isInserted: false,
            isDeleted: false,
            placeOnMap: selectedCodeGroupCode.mapIntegration,
        }
        if (!checkEmptyObject(selectedProjectArea)) {
            newEntry = {
                ...newEntry,
                projectAreaId: selectedProjectArea.id,
            }
        }
        if (!checkEmptyObject(selectedProjectAreaSection)) {
            newEntry = {
                ...newEntry,
                projectAreaSectionId: selectedProjectAreaSection.id,
            }
        }
        this.props.insertQuntityEntry(newEntry);
        if (!online) {
            // Adding quantity to an offline store, so that if the user is offline then it'll reflect into the cached period totals
            this.props.updateOfflinePeriodTotals(user.teamId, customerProject.id, selectedDate, selectedCodeGroupCode.roqCodeId, quantity, ROQ_WEEKLY_TOTAL_ADD);
        }
        this.clearInputData();
    };
    handleOnDelete = (id) => {
        let { customerProject, user, online } = this.props;
        let { selectedDate } = this.state;
        // Deleting quantity from an offline store, so that if the user is offline then it'll reflect into the cached period totals
        if (!online) {
            let entry = this.props.local.quantities.filter(x => x.id === id)[0];
            let { quantity, roqCodeId } = entry;
            this.props.updateOfflinePeriodTotals(user.teamId, customerProject.id, selectedDate, roqCodeId, quantity, ROQ_WEEKLY_TOTAL_DELETE);
        }
        this.props.deleteQuntityEntry(customerProject.id, user.teamId, user.codeListTypeId, selectedDate, id, true);
        this.clearInputData();
    };
    clearInputData = () => {
        this.setState({
            quantity: "",
            notes: "",
            disableToInsert: true
        });
    };
    handleOnPeriodTotalsDateSelect = (date) => {
        this.setState({ selectedDateForPeriodTotals: date });
        let { customerProject, user, online } = this.props;
        // When user is offline, then always display current week totals
        let periodDate = date;
        if (!online) {
            periodDate = getDateInFormat();
        }
        this.props.fetchPeriodTotals(user.teamId, customerProject.id, periodDate);
    }
    handleOnBackwardPeriodTotals = () => {
        let newDateForPeriodTotals = addDays(this.state.selectedDateForPeriodTotals, -periodDays);
        this.handleOnPeriodTotalsDateSelect(newDateForPeriodTotals);
    }
    handleOnForwardPeriodTotals = () => {
        let newDateForPeriodTotals = addDays(this.state.selectedDateForPeriodTotals, periodDays);
        this.handleOnPeriodTotalsDateSelect(newDateForPeriodTotals);
    }
    handleOnOk = () => {
        this.props.updatePriceCodeListEmptyAlert(false);
    }
    render() {
        let { activeTab, selectedDate, quantity, notes, disableToInsert,
            selectedProjectArea, selectedProjectAreaSection, selectedCodeGroup, selectedCodeGroupCode } = this.state;
        let { tabs, local, customerProject, customerTeam, customerCodeListType, online, t } = this.props;
        let { isLoading, isEntriesLoading, isEntryInserting, isEntryDeleting, projectAreas, projectAreaSections, codeGroups, codeGroupCodes, quantities, deletingQuantityId, isTriggerByDetector, isLocked } = local;
        let { periodTotal, isPriceCodeListEmpty } = local;
        // To diplay code's name with units in bracket for dropdown list.
        codeGroupCodes = codeGroupCodes.map(x => x = { ...x, name: `${x.name} (${x.roqCodeUnitCode})` });
        let maxDate = getDateInFormat();
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mt-5 m-even-padding top-margin-1rem">
                            <div className="text-center mt-1 mb-2">{`${customerProject.name} | ${checkEmptyObject(customerTeam) ? "Empty" : customerTeam.name} | ${checkEmptyObject(customerCodeListType) ? "Empty" : customerCodeListType.codeListTypeName}`}</div>
                            <div className="form-box bg-box">
                                <Tabs tabs={tabs} activeTab={activeTab} toggle={this.handleOnToggle} />
                                <TabContent activeTab={activeTab}>
                                    {
                                        activeTab === 2 &&
                                        <>
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <button disabled={!online} type="button" className="btn btn-primary btn-backward" onClick={this.handleOnBackwardPeriodTotals}><i className="fa fa-backward"></i></button>
                                                    <h3 style={{ textTransform: "capitalize", display: "inline" }}>{periodTotal.name}</h3>
                                                    <button disabled={!online} type="button" className="btn btn-primary btn-forward" onClick={this.handleOnForwardPeriodTotals}><i className="fa fa-forward"></i></button>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: ".5rem", marginBottom: ".2rem" }} />
                                        </>
                                    }
                                    <TabPane tabId={1}>
                                        <SiteForm
                                            dateId="txtDate"
                                            isLoading={isLoading}
                                            initialDate={selectedDate}
                                            maxDate={maxDate}
                                            quantity={quantity}
                                            notes={notes}
                                            disabled={disableToInsert}

                                            selectedProjectArea={selectedProjectArea}
                                            selectedProjectAreaSection={selectedProjectAreaSection}
                                            selectedCodeGroup={selectedCodeGroup}
                                            selectedCodeGroupCode={selectedCodeGroupCode}

                                            projectAreas={projectAreas}
                                            projectAreaSections={projectAreaSections}
                                            codeGroups={codeGroups}
                                            codeGroupCodes={codeGroupCodes}

                                            onDateSelect={(date) => this.handleOnDateSelect(date)}
                                            onProjectAreaSelect={(projectArea) => this.handleOnProjectAreaSelect(projectArea)}
                                            onProjectAreaSectionSelect={(projectAreaSection) => this.handleOnProjectAreaSectionSelect(projectAreaSection)}
                                            onCodeGroupSelect={(codeGroup) => this.handleOnCodeGroupSelect(codeGroup)}
                                            onCodeGroupCodeSelect={(codeGroupCode) => this.handleOnCodeGroupCodeSelect(codeGroupCode)}
                                            onUpdate={(ctrlName, ctrlValue) => this.handleOnUpdateInputs(ctrlName, ctrlValue)}
                                            onInsert={() => this.handleOnInsert()}

                                            isEntryInserting={isEntryInserting}
                                            isTriggerByDetector={isTriggerByDetector}
                                        ></SiteForm>
                                        <hr style={{ marginBottom: ".6rem" }} />
                                        <DetailTabGridContext
                                            deletingQuantityId={deletingQuantityId}
                                            isLoading={isEntriesLoading}
                                            quantityReportingEntries={quantities}
                                            selectedDate={selectedDate}
                                            onDelete={(id) => this.handleOnDelete(id)}
                                            isEntryDeleting={isEntryDeleting}
                                            isTriggerByDetector={isTriggerByDetector}
                                            t={t}
                                            isLocked={isLocked}
                                        ></DetailTabGridContext>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        {
                                            checkEmptyObject(periodTotal) ?
                                                <span className="error-msg">{t("text.No_data")}</span> :
                                                <PeriodTotalsTabContext
                                                    list={periodTotal}
                                                    isLoading={isLoading}
                                                    t={t}
                                                ></PeriodTotalsTabContext>
                                        }
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isPriceCodeListEmpty &&
                    <AlertBox
                        okButtonText={t("control.OK")}
                        heading={t("text.Information")}
                        text={t("text.Prerequisite_message_3")}
                        onOk={this.handleOnOk}
                    ></AlertBox>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    online: state.app.online,
    user: state.auth.user,
    customerProject: state.selectProject.selectedProject,
    customerTeam: state.selectTeam.selectedTeam,
    customerCodeListType: state.selectCodeListType.selectedCodeListType,
    tabs: [
        {
            name: "label.Daily_report",
            id: 1,
        },
        {
            name: "label.Weekly_total",
            id: 2,
        },
    ],
    local: state.quantityReporting,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (path) => dispatch(push(path)),
    selectProject: () => dispatch(selectProject()),
    selectModule: (module) => dispatch(selectModule(module)),
    fetchQuantityReportingDetails: (companyId, teamId, parentProjectId, projectId, codeListTypeId, date, t) => dispatch(fetchQuantityReportingDetails(companyId, teamId, parentProjectId, projectId, codeListTypeId, date, t)),
    fetchProjectAreaSections: (projectAreaId) => dispatch(fetchProjectAreaSections(projectAreaId)),
    fetchCodeGroupsCodes: (codeGroupId) => dispatch(fetchCodeGroupsCodes(codeGroupId)),
    insertQuntityEntry: (quantity) => dispatch(insertQuntityEntry(quantity)),
    deleteQuntityEntry: (projectId, teamId, codeListTypeId, date, id, isDispatchInit) => dispatch(deleteQuntityEntry(projectId, teamId, codeListTypeId, date, id, isDispatchInit)),
    fetchQuantityReportingEntries: (companyId, teamId, projectId, codeListTypeId, date) => dispatch(fetchQuantityReportingEntries(companyId, teamId, projectId, codeListTypeId, date)),
    fetchPeriodTotals: (teamId, projectId, date) => dispatch(fetchPeriodTotals(teamId, projectId, date)),
    updateOfflinePeriodTotals: (teamId, projectId, date, roqCodeId, quantity, action) => dispatch(updateOfflinePeriodTotals(teamId, projectId, date, roqCodeId, quantity, action)),
    updatePriceCodeListEmptyAlert: (isShow) => dispatch(updatePriceCodeListEmptyAlert(isShow))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuantityReporting));
