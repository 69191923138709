/*
 * This file act as the DataBase layer file. 
 * And this file communicates with the Offline IndexDB
 * This file should have functions to perform CRUD and DML operations only.
 */

// loading configuration
import { DATABASE_NAME, DATABASE_VERSION } from "./config";

// loading objects stores
import { OBJECTSTORE_CUSTOMERS, OBJECTSTORE_CUSTOMER_PROJECTS } from "./os/common/projects";
import { OBJECTSTORE_INSTPLANS } from "./os/installationPlan/enteries";
import { OBJECTSTORE_DETAILS_IP } from "./os/installationPlan/details";
import { OBJECTSTORE_DETAILS_ACTIVITIES } from "./os/installationPlan/activities";
import { OBJECTSTORE_DETAILS_IMG_META } from "./os/installationPlan/images";
import { OBJECTSTORE_IMAGES } from "./os/installationPlan/downloadImages";
import { OBJECTSTORE_TEAMS } from "./os/common/teams";
import { OBJECTSTORE_CODE_LIST_TYPES } from "./os/common/codeListTypes";
import { OBJECTSTORE_PROJECT_AREAS, OBJECTSTORE_PROJECT_AREA_SECTIONS } from "./os/reportingOfQuantities/areaAndSections";
import { OBJECTSTORE_ROQ_CODE_CATEGORIES, OBJECTSTORE_ROQ_CODES } from "./os/reportingOfQuantities/priceLists";
import { OBJECTSTORE_ROQ_DAYS } from "./os/reportingOfQuantities/days";
import { OBJECTSTORE_ROQ_ENTRIES } from "./os/reportingOfQuantities/entries";
import { OBJECTSTORE_ROQ_PERIOD, OBJECTSTORE_ROQ_PERIOD_TOTALS } from "./os/reportingOfQuantities/periodTotals";

// loading object store factory
import { createObjectStore } from "./util";

// opens db and upgrade db in steps
export const openDatabase = function () {
  return new Promise((resolve, reject) => {
    // Make sure IndexedDB is supported before attempting to use it
    if (!window.indexedDB) return reject(false);

    let request = window.indexedDB.open(DATABASE_NAME, DATABASE_VERSION);
    // on db open error
    request.onerror = function (event) {
      console.log("Database Opening Error: ", event.target.error);
      return reject(event.target.error);
    };
    // on upgrade needed
    request.onupgradeneeded = function (event) {
      let db = event.target.result;
      switch (event.oldVersion) {
        case 0:
          // projects object store
          createObjectStore(db, OBJECTSTORE_CUSTOMERS, "id");
          createObjectStore(db, OBJECTSTORE_CUSTOMER_PROJECTS, ["id", "customerId"], ["customerId"]);
          createObjectStore(db, OBJECTSTORE_INSTPLANS, "id", ["project_id"]);
          createObjectStore(db, OBJECTSTORE_DETAILS_IP, "id");
          createObjectStore(db, OBJECTSTORE_DETAILS_ACTIVITIES, "id");
          createObjectStore(db, OBJECTSTORE_DETAILS_IMG_META, "id");
          createObjectStore(db, OBJECTSTORE_IMAGES, "id", ["instPlan_id", "server", "img_id"]);
          createObjectStore(db, OBJECTSTORE_TEAMS, "id");
          createObjectStore(db, OBJECTSTORE_CODE_LIST_TYPES, "codeListTypeId");
          createObjectStore(db, OBJECTSTORE_PROJECT_AREAS, ["id", "projectId"], ["projectId"]);
          createObjectStore(db, OBJECTSTORE_PROJECT_AREA_SECTIONS, "id");
          createObjectStore(db, OBJECTSTORE_ROQ_CODE_CATEGORIES, ["id", "projectId", "codeListTypeId"], ["projectId, codeListTypeId"]);
          createObjectStore(db, OBJECTSTORE_ROQ_CODES, "roqCodeId");
          createObjectStore(db, OBJECTSTORE_ROQ_DAYS, ["projectId", "companyId", "date"], ["projectId, companyId, date"]);
          createObjectStore(db, OBJECTSTORE_ROQ_ENTRIES, ["id", "projectId", "codeListTypeId", "teamId", "date"], ["projectId, codeListTypeId, teamId, date"]);
          createObjectStore(db, OBJECTSTORE_ROQ_PERIOD, ["projectId", "teamId"], ["projectId, teamId"]);
          createObjectStore(db, OBJECTSTORE_ROQ_PERIOD_TOTALS, ["projectId", "teamId", "roqCodeId"], ["projectId, teamId", "projectId, teamId, roqCodeId"]);
        /* falls through */
        default:
          break;
      }
    };
    return resolve(request);
  });
};

// open object store and returns store as promise
export const openObjectStore = function (storeName, transactionMode) {
  return new Promise((resolve, reject) => {
    openDatabase().then((db) => {
      if (!db) return reject(false);

      db.onsuccess = function (event) {
        let db = event.target.result;
        let tx = db.transaction(storeName, transactionMode);
        var objectStore = tx.objectStore(storeName);
        return resolve(objectStore, tx);
      };
    });
  });
};

// clear object store --> empty
export const clearObjectStore = (storeName) => {
  return new Promise((resolve, reject) => {
    openObjectStore(storeName, "readwrite").then((objectStore) => {
      let request = objectStore.clear();
      request.onsuccess = function (event) {
        // let data = event.target.result;
        if (event) return resolve(true);
        return reject(false);
      };
      request.onerror = function (error) {
        return reject(error);
      };
    });
  });
};

// get an item by Id
export const getItemById = (storeName, id) => {
  return new Promise((resolve, reject) => {
    openObjectStore(storeName).then((objectStore) => {
      let request = objectStore.get(id);
      request.onsuccess = function (event) {
        let data = event.target.result;
        if (!data) return reject(false);
        return resolve(data);
      };
      request.onerror = function (error) {
        return reject(error);
      };
    });
  });
};

// get all item in an objectStore
export const getAllItems = (name) => {
  return new Promise((resolve, reject) => {
    openObjectStore(name)
      .then((objectStore) => {
        objectStore.getAll().onsuccess = function (event) {
          let result = event.target.result;
          if (Array.isArray(result) && !result.length) return reject(false);
          if (!result) return reject(false);
          return resolve(result);
        };
      })
      .catch(reject);
  });
};

// get all items in an objectStore by an index
export const getItemsbyIndex = (storeName, indexName, key, checkEmpty) => {
  return new Promise((resolve, reject) => {
    openDatabase().then((db) => {
      if (!db) return reject(false);

      db.onsuccess = function (event) {
        var db = event.target.result;
        var objectStore = db.transaction(storeName).objectStore(storeName);
        var index = objectStore.index(indexName);
        let query = index.getAll(key);
        query.onsuccess = (event) => {
          let result = event.target.result;
          if (checkEmpty && Array.isArray(result) && !result.length) return reject(false);
          if (!result) return reject(false);
          return resolve(result);
        };
      };
    });
  });
};

// get all keys in an objectStore by an index
export const getKeysbyIndex = (storeName, indexName, id, checkEmpty) => {
  return new Promise((resolve, reject) => {
    openDatabase().then((db) => {
      if (!db) return reject(false);

      db.onsuccess = function (event) {
        var db = event.target.result;
        var objectStore = db.transaction(storeName).objectStore(storeName);
        var index = objectStore.index(indexName);
        let query = index.getAllKeys(id);
        query.onsuccess = (event) => {
          let result = event.target.result;
          if (checkEmpty && Array.isArray(result) && !result.length) return reject(false);
          if (!result) return reject(false);
          return resolve(result);
        };
      };
    });
  });
};

// load objectStore using cursor (to be deprecated/deleted if not used)
// export const loadObjectStore = (name, keyName, keyValue) => {
//   return new Promise((res, rej) => {
//     let list = [];
//     openObjectStore(name).then((objectStore) => {
//       let cursor = objectStore.openCursor();
//       cursor.onsuccess = function (event) {
//         let cursor = event.target.result;
//         if (!cursor) {
//           if (list.length > 0) {
//             return res(list);
//           } else {
//             return rej(false);
//           }
//         }
//         if (keyName) {
//           if (cursor.value[`${keyName}`] === keyValue) {
//             list.push(cursor.value);
//           }
//         } else {
//           list.push(cursor.value);
//         }
//         cursor.continue();
//       };
//     });
//   });
// };

export const putInStore = (name, data) => {
  return new Promise((resolve, reject) => {
    openObjectStore(name, "readwrite")
      .then((objectStore) => {
        objectStore.put(data);
      })
      .then(resolve)
      .catch(reject);
  });
};

export const ifOffline = (id, forward) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_INSTPLANS, id)
      .then((data) => {
        if (data) return resolve(forward);
        else return reject(false);
      })
      .catch((error) => {
        return reject(false);
      });
  });
};


/* To insert collections into store's table
 * tableConstant : const key having table name 
 * data : collections
*/
export const saveDataInStore = (tableConstant, data) => {
  return new Promise((resolve, reject) => {
    openObjectStore(tableConstant, "readwrite")
      .then((objectStore) => Promise.all(data.map((e) => objectStore.put(e))))
      .then((k) => resolve(data))
      .catch(reject);
  });
};

/* To fetch collections from store's table
 * tableConstant : const key having table name 
 * data : collections
 * index : 'index' // in case of multiple: 'index1,index2, etc'
 * key :'key' // in case of multiple ['key1','key2, etc]'
 * checkEmpty: true/false
*/
export const getDataFromStore = (tableConstant, index, key, checkEmpty) => {
  return new Promise((resolve, reject) => {
    if (index === undefined || index === null) {
      getAllItems(tableConstant)
        .then((data) => {
          if (!data) {
            return reject(false);
          }
          return resolve(data);
        })
        .catch(reject);
    }
    else {
      getItemsbyIndex(tableConstant, index, key, checkEmpty)
        .then((data) => {
          if (!data) {
            return reject(false);
          }
          return resolve(data);
        })
        .catch(reject);
    }
  });
};

/* To delete row from store's table
 * tableConstant : const key having table name 
 * id : unique id
*/
export const deleteDataFromStore = (tableConstant, id) => {
  return new Promise((resolve, reject) => {
    openObjectStore(tableConstant, "readwrite")
      .then((objectStore) => objectStore.delete(id))
      .then((k) => resolve(true))
      .catch(reject);
  });
};

