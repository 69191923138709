import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className="container h-100 not-found">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-lg-12 text-center">
          <h3>404</h3>
          <h4>Page Not Found!</h4>
          <Link to="/home">Go To Home</Link>
        </div>
      </div>
    </div>
  );
};
