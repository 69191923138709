import React from "react";
import { connect } from "react-redux";
import { fetchInstPlans, fetchProjectActivities, selectIPlan, selectProject } from "./actions";
import { BoxLoader } from "../../components/Loader";
import InputBox from "../../components/Form/InputBox";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import InstallationTable from "./InstallationTable";
import { fetchDetails, fetchActivities, fetchImagesMeta } from "../DetailsPage/actions";
import TakeOffline from "../../components/TakeOffline";
import { withTranslation } from "react-i18next";
import { selectModule } from "../Home/actions";


class InstPlansPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      search: {},
      filterSelected: {},
      instPlansList: this.props.instPlansList,
      enable: false,
      data: null
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.instPlansList !== this.props.instPlansList) {
      this.setState({ instPlansList: this.props.instPlansList });
    }
  }
  componentDidMount() {
    let { project_id } = this.props;
    this.props.fetchProjectActivities(project_id);
    this.props.fetchInstPlans(project_id, {}, {});
  }
  handleSubmit = e => {
    e.preventDefault();
    let { search, filterSelected } = this.state;
    let { project_id } = this.props;
    this.props.fetchInstPlans(project_id, search, filterSelected);
  };
  updateForm = (name, data) => {
    let search = { ...this.state.search };
    search[name] = data;
    this.setState({ search });
  };
  handleSelected = (full, obj) => {
    let filterSelected = { ...this.state.filterSelected };
    let kk = { ...obj };
    kk["parentId"] = full.id;
    filterSelected[full.id] = kk;
    this.setState({ filterSelected });
  };
  toggle = () => {
    this.setState({ enable: !this.state.enable });
  };
  resetForm = () => {
    this.setState({
      search: {},
      filterSelected: {}
    });
    let { project_id } = this.props;
    this.props.fetchInstPlans(project_id, {}, {});
  };

  saveOffline = data => {
    this.setState({ enable: true, data });
    // openObjectStore(OBJECTSTORE_INSTPLANS, "readwrite")
    //   .then(objectStore => {
    //     data["project_id"] = this.props.project_id;
    //     return objectStore.put(data);
    //   })
    //   .then(k => {
    //     let { instPlansList } = this.state;
    //     let newList = instPlansList.map(e => {
    //       if (e.id === data.id) e["offline"] = true;
    //       return e;
    //     });
    //     let instPlan_id = data.id;
    //     this.props.fetchDetails(instPlan_id);
    //     this.props.fetchActivities(instPlan_id);
    //     this.props.fetchImagesMeta(instPlan_id);
    //     this.setState({ instPlansList: newList });
    //   });
  };

  putOffline = data => {
    let { instPlansList } = this.state;
    let newList = instPlansList.map(e => {
      if (e.id === data.id) e["offline"] = true;
      return e;
    });
    this.setState({ instPlansList: newList });
  };

  render() {
    let { projectActivities, instPlans, t } = this.props;
    let { isActivitiesLoading, isLoading } = instPlans;
    let { search, instPlansList } = this.state;
    return (
      <div className="container-fluid">
        <TakeOffline
          putOffline={this.putOffline}
          toggle={this.toggle}
          data={this.state.data}
          enable={this.state.enable}
        />
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="form-box padding-box bg-box side-bar">
              <div className="row">
                <div className="col-lg-8 col col-8">
                  <h5>{t("label.Installation_plans")}</h5>
                </div>
                <div className="col-lg-4 col col-4 text-right">
                  <span className="cursor-pointer" onClick={this.resetForm}>
                    <i className="fa fa-rotate-left"></i> {t("control.Reset")}
                  </span>
                </div>
              </div>
              <p className="small">{t("text.Search_for_installation_plans_using_the_fields_below")}</p>
              <form className="center-third" onSubmit={this.handleSubmit}>
                <div className="search-group">
                  <InputBox
                    className="w-100"
                    heading={t("text.Search")}
                    placeholder={t("text.Search") + ' ' + t("label.Site_ID") + ', ' + t("label.Area") + ', ' + t("label.Address")}
                    name="search"
                    value={search.search}
                    update={this.updateForm}
                  />
                  {/* <InputBox
                    heading="Area"
                    placeholder="Enter Area"
                    name="subPropertyArea"
                    value={search.subPropertyArea}
                    update={this.updateForm}
                  />
                  <InputBox
                    heading="Address"
                    placeholder="Enter Address"
                    name="subAddress"
                    value={search.subAddress}
                    update={this.updateForm}
                  /> */}
                  {projectActivities.map((o, index) => {
                    return (
                      <Dropdown
                        key={index}
                        selected={this.state.filterSelected[o.id] ? this.state.filterSelected[o.id].name : null}
                        onChange={e => this.handleSelected(o, e)}
                        list={o.activityStatuses}
                        name={o.name}
                      />
                    );
                  })}
                </div>

                <hr />

                <div className="form-group mt-4">
                  <Button
                    type="submit"
                    loading={isLoading}
                    disabled={isActivitiesLoading}
                    className="btn btn-primary btn-one btn-100"
                  >
                    {t("control.Search")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="bg-box overflow-y mtm-5">
              {isLoading ? (
                <BoxLoader />
              ) : (
                  <InstallationTable
                    saveOffline={this.saveOffline}
                    list={instPlansList}
                    selectIPlan={this.props.selectIPlan}
                    trans={t}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  instPlansList: state.instPlans.instPlansList,
  project_id: state.selectProject.selectedProject.id,
  projectActivities: state.instPlans.projectActivities,
  instPlans: state.instPlans
});

const mapDispatchToProps = dispatch => ({
  fetchInstPlans: (project_id, search, filter) => dispatch(fetchInstPlans(project_id, search, filter)),
  fetchProjectActivities: project_id => dispatch(fetchProjectActivities(project_id)),
  selectIPlan: installationPlan => dispatch(selectIPlan(installationPlan)),
  fetchDetails: instPlan_id => dispatch(fetchDetails(instPlan_id)),
  fetchImagesMeta: instPlan_id => dispatch(fetchImagesMeta(instPlan_id)),
  fetchActivities: instPlan_id => dispatch(fetchActivities(instPlan_id)),
  selectProject: () => dispatch(selectProject()),
  selectModule: module => dispatch(selectModule(module))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InstPlansPage));
