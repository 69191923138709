import React, { Fragment } from "react";
import { table, thead, tbody, tr, th, td } from "react-super-responsive-table";
import { getLocaleDate } from "../../utils"
const TimeSheetDetailTab = (props) => {
  let { timeReportEntries, OnDelete, selectedDate, t } = props;
  if (timeReportEntries === undefined) return;
  let isDeleteButtonHide = timeReportEntries.filter((x) => x.isLocked || x.isSubmitted).length;
  let tdSpan = isDeleteButtonHide ? 2 : 3;
  return (
    <table className="table table-theme-1">
      <thead>
        <tr>
          <th scope="col" colSpan="3" className="heading-1">
            {getLocaleDate(selectedDate)}
          </th>
        </tr>
        <tr>
          <th scope="col" className="heading-2">
            {t("label.Code")}
          </th>
          <th scope="col" className="heading-2" style={{ textAlign: "right" }}>
            {t("label.Quantity")}
          </th>
          {!isDeleteButtonHide && (
            <th scope="col" className="heading-2">
              &nbsp;
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {timeReportEntries.length > 0 ? (
          timeReportEntries.map((timeReportEntry) => (
            <Fragment key={timeReportEntry.id}>
              <tr>
                <td className="row-theme-1">{timeReportEntry.timeCodeName}</td>
                <td className="row-theme-1" align="right">
                  {(timeReportEntry.hoursRegular || timeReportEntry.hoursOverTime || timeReportEntry.quantity) +
                    " " +
                    timeReportEntry.unitCode}
                </td>
                {!isDeleteButtonHide && (
                  <td className="row-theme-1" align="right">
                    <button
                      className="btn btn-xs btn-danger"
                      onClick={(id) => OnDelete(timeReportEntry.id)}
                      style={{ padding: "2px 10px" }}
                    >
                      <span className="fa fa-trash"></span>
                    </button>
                  </td>
                )}
              </tr>
              {timeReportEntry.notes !== "" && (
                <tr>
                  <td className="row-theme-2" colSpan={tdSpan}>
                    {`${t("control.Notes")}: ${timeReportEntry.notes}`}
                  </td>
                </tr>
              )}
            </Fragment>
          ))
        ) : (
            <tr>
              <td colSpan={tdSpan} className="text-center">
                {t("label.No_reports")}
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
};

export default TimeSheetDetailTab;
