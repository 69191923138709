import React from "react";

export const ErrorBox = (msg = "Cannot Load Data") => {
  return (
    <div>
      <p style={{ fontSize: "11px", marginTop: "4px" }}>{msg}</p>
    </div>
  );
};
export default ErrorBox;
