import { fetchMethod } from "../../utils";
import { OBJECTSTORE_DETAILS_IP } from "../../offline/os/installationPlan/details";
// import { OBJECTSTORE_INSTPLANS } from "../../offline/os/installationPlan/enteries";
import { OBJECTSTORE_DETAILS_IMG_META } from "../../offline/os/installationPlan/images";
import { OBJECTSTORE_DETAILS_ACTIVITIES } from "../../offline/os/installationPlan/activities";
import { OBJECTSTORE_IMAGES } from "../../offline/os/installationPlan/downloadImages";

import { openObjectStore, putInStore } from "../../offline/db";

export const getDetails = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    let url = `/installationplans/${instPlan_id}`;
    fetchMethod(url).then(resolve).catch(reject);
  });
};

export const getActivities = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    let url = `/installationplans/${instPlan_id}/installationactivities`;
    fetchMethod(url).then(resolve).catch(reject);
  });
};

export const getImages = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    let url = `/installationplans/${instPlan_id}/images`;
    fetchMethod(url).then(resolve).catch(reject);
  });
};

export const getImageData = (obj) => {
  let { instPlan_id, imgGrp_id, img_id } = obj;
  return new Promise((resolve, reject) => {
    let url = `/installationplans/${instPlan_id}/images/${imgGrp_id}/${img_id}`;
    fetchMethod(url)
      .then((data) => {
        obj["data"] = data;
        return resolve(obj);
      })
      .catch(reject);
  });
};

export const saveImages = (id, data) => {
  let obj = { id, data };
  return new Promise((resolve, reject) => {
    return openObjectStore(OBJECTSTORE_DETAILS_IMG_META, "readwrite")
      .then((objectStore) => objectStore.put(obj))
      .then((k) => resolve(true))
      .catch(reject);
  });
};

export const saveImageData = (obj, server = true) => {
  let { instPlan_id, imgGrp_id, img_id, data } = obj;
  let id = `${instPlan_id}.${imgGrp_id}.${img_id}`;
  let save = {
    id,
    instPlan_id,
    imgGrp_id,
    img_id,
    server: server ? true : false,
    data: data,
  };
  return new Promise((resolve, reject) => {
    return putInStore(OBJECTSTORE_IMAGES, save)
      .then((k) => resolve(data))
      .catch(reject);
  });
};

export const saveActivities = (id, data) => {
  let obj = { id, data };
  return new Promise((resolve, reject) => {
    return openObjectStore(OBJECTSTORE_DETAILS_ACTIVITIES, "readwrite")
      .then((objectStore) => objectStore.put(obj))
      .then((k) => resolve(true))
      .catch(reject);
  });
};
export const saveDetails = (obj) => {
  return new Promise((resolve, reject) => {
    return openObjectStore(OBJECTSTORE_DETAILS_IP, "readwrite")
      .then((objectStore) => objectStore.put(obj))
      .then((k) => resolve(true))
      .catch(reject);
  });
};

export const getImagesTotal = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    let final = [];
    getImages(instPlan_id)
      .then((imgMetaData) => {
        imgMetaData.forEach((mainSection, index) => {
          mainSection.sections.forEach((section) => {
            section.imageGroups.forEach((img) => {
              img.images.forEach((im) => {
                final.push({
                  instPlan_id,
                  imgGrp_id: img.id,
                  img_id: im,
                });
              });
            });
          });
          if (index === imgMetaData.length - 1) {
            return resolve(final);
          }
        });
      })
      .catch((error) => reject(error));
  });
};

// export const getOfflineInstallationPlans = ()=>{
//   return new Promise((resolve, reject) => {
//     return openObjectStore(OBJECTSTORE_INSTPLANS, "readwrite")
//       .then((objectStore) => objectStore.put(obj))
//       .then((k) => resolve(true))
//       .catch(reject);
//   });
// }
