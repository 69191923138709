import React from "react";
export const Loader = () => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <div className="loader"></div>
      <p style={{ fontSize: "11px", marginTop: "4px" }}> Loading..</p>
    </div>
  );
};

export default Loader;

export const BoxLoader = ({ className, height = 200 }) => {
  return (
    <div className={`flex-center height-${height} ${className}`}>
      <Loader />
    </div>
  );
};
