import axios from "axios";
import * as constant from "./constants";
import { dispatchPromise, dispatchInit, dispatchError, dispatchSuccess, getDateInFormat } from "../../utils";
import { saveDataInStore, getDataFromStore, deleteDataFromStore } from "../../offline/db";
import { OBJECTSTORE_PROJECT_AREAS, OBJECTSTORE_PROJECT_AREA_SECTIONS } from "../../offline/os/reportingOfQuantities/areaAndSections";
import { OBJECTSTORE_ROQ_CODE_CATEGORIES, OBJECTSTORE_ROQ_CODES } from "../../offline/os/reportingOfQuantities/priceLists";
import { OBJECTSTORE_ROQ_DAYS } from "../../offline/os/reportingOfQuantities/days";
import { OBJECTSTORE_ROQ_ENTRIES } from "../../offline/os/reportingOfQuantities/entries";
import { OBJECTSTORE_ROQ_PERIOD, OBJECTSTORE_ROQ_PERIOD_TOTALS } from "../../offline/os/reportingOfQuantities/periodTotals";

export const selectProject = () => dispatch => {
    dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_SELECT_PROJECT, {}, "/projects", "selectedProject");
};

export const fetchQuantityReportingDetails = (companyId, teamId, parentProjectId, projectId, codeListTypeId, date, t) => dispatch => {
    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.QUANTITY_REPORTING_DATA_INIT_TO_FETCH);

    // Store primary ids and date to Redux store for offline trigger function
    dispatchPromise(dispatch, constant.QUANTITY_REPORTING_ACTIVE_DATA, {
        date: date,
        customerProjectId: projectId,
        codeListTypeId: codeListTypeId,
    });

    // Generating API urls to get quantity reporting data for detail tab
    let projectAreasApiUrl = `/projectareas?projectId=${projectId}&expand=sections`;
    // We then fetch the RoQ price list by parentProjectId instead project id.
    let priceListsAPIUrl = `/roqpricelists?projectId=${parentProjectId}&codelisttype=${codeListTypeId}&forUserRegistration=true&expand=itemcategories.items`;
    let quantitiesApiUrl = `/roqentries?projectId=${projectId}&fromDate=${getDateInFormat(date)}&toDate=${getDateInFormat(date)}&teamId=${teamId}&expand=roqCodeName,roqCodeUnitCode,roqCodeUnitName,CompanyId,isDeletableByUser`;
    let dayPermissionAPIUrl = `/roqdays/${getDateInFormat(date)}?projectId=${projectId}&companyId=${companyId}`

    // Setup the requests to axios
    const projectAreasRequest = axios.get(projectAreasApiUrl);
    const priceListsRequest = axios.get(priceListsAPIUrl);
    const quantitiesRequest = axios.get(quantitiesApiUrl);
    const dayPermissionRequest = axios.get(dayPermissionAPIUrl);

    // axios class which will execute API and execute a promise of success.
    axios
        .all([projectAreasRequest, priceListsRequest, quantitiesRequest, dayPermissionRequest])
        .then(axios.spread((...res) => {
            // Fetch APIs return data     
            let apiProjectAreas = res[0].data;
            let apiPriceLits = res[1].data;
            let quantities = res[2].data;
            let permission = res[3].data;
            // Prepare project area's json to save data into offline store.
            let projectAres = Object.assign(apiProjectAreas.map(x => x =
            {
                id: x.id,
                name: x.name,
                projectId: projectId,
                hasSections: x.sections.length ? true : false
            }));
            let sections = [];
            for (let i = 0; i < apiProjectAreas.length; i++) {
                for (let j = 0; j < apiProjectAreas[i].sections.length; j++) {
                    sections.push({ ...apiProjectAreas[i].sections[j], projectAreaId: apiProjectAreas[i].id });
                }
            }
            // Prepare code group's json to save data into offline store.
            let codeGroups = [];
            let codeGroupCodes = [];
            for (let i = 0; i < apiPriceLits.length; i++) {
                for (let j = 0; j < apiPriceLits[i].itemCategories.length; j++) {
                    codeGroups.push({
                        id: apiPriceLits[i].id + '0' + apiPriceLits[i].codeListTypeId + '0' + j,
                        name: apiPriceLits[i].itemCategories[j].roqCodeCategoryName,
                        projectId: apiPriceLits[i].projectId,
                        codeListTypeId: apiPriceLits[i].codeListTypeId,
                        companyId: apiPriceLits[i].companyId
                    });
                    for (let k = 0; k < apiPriceLits[i].itemCategories[j].items.length; k++) {
                        codeGroupCodes.push({
                            codeGroupId: apiPriceLits[i].id + '0' + apiPriceLits[i].codeListTypeId + '0' + j,
                            name: apiPriceLits[i].itemCategories[j].items[k].roqCodeName,
                            ...apiPriceLits[i].itemCategories[j].items[k]
                        });
                    }
                }
            }
            // Notify user, when there are no price lists found.
            if (codeGroups.length === 0) {
                updatePriceCodeListEmptyAlert(true);
            }
            // Prepare quantity entry list's json to save data into offline store.
            // Also appending codeListTypeId as its not receiving from API
            quantities = quantities.map(x => x =
            {
                ...x,
                codeListTypeId: codeListTypeId,
                isOffline: false,
                isInserted: false,
                isDeleted: false
            });

            // Prepare roq day's permission json to save data into offline store.
            permission = {
                ...permission,
                projectId: projectId,
                companyId: companyId
            }

            // Save data into offline store.
            saveDataInStore(OBJECTSTORE_PROJECT_AREAS, projectAres);
            saveDataInStore(OBJECTSTORE_PROJECT_AREA_SECTIONS, sections);
            saveDataInStore(OBJECTSTORE_ROQ_CODE_CATEGORIES, codeGroups);
            saveDataInStore(OBJECTSTORE_ROQ_CODES, codeGroupCodes);
            saveDataInStore(OBJECTSTORE_ROQ_ENTRIES, quantities);
            saveDataInStore(OBJECTSTORE_ROQ_DAYS, [permission]);

            // Provide data to component's Redux store to map it on the UI
            let data = {
                projectAreas: projectAres,
                codeGroups: codeGroups,
                quantities: quantities
            }
            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DAY_PERMISSION, permission.isLocked)
                .then(dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DATA_FETCH_SUCCESS, data));
        }))
        .catch(error => {
            // API fails to succeed request, so fetch data from offline store and update component Redux.
            let data = {
                projectAreas: [],
                codeGroups: []
            }
            getDataFromStore(OBJECTSTORE_PROJECT_AREAS, "projectId", projectId)
                .then(projectAreas => {
                    data.projectAreas = projectAreas;
                    getDataFromStore(OBJECTSTORE_ROQ_CODE_CATEGORIES, "projectId, codeListTypeId", [parentProjectId, codeListTypeId])
                        .then(codeGroups => {
                            data.codeGroups = codeGroups;
                            getDataFromStore(OBJECTSTORE_ROQ_ENTRIES, "projectId, codeListTypeId, teamId, date", [projectId, codeListTypeId, teamId, getDateInFormat(date)])
                                .then(quantities => {
                                    data.quantities = quantities;
                                    getDataFromStore(OBJECTSTORE_ROQ_DAYS, "projectId, companyId, date", [projectId, companyId, getDateInFormat(date)])
                                        .then(permission => {
                                            let isLocked = true;
                                            if (permission !== undefined && permission !== null) {
                                                isLocked = permission.isLocked
                                            }
                                            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DAY_PERMISSION, isLocked)
                                                .then(dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DATA_FETCH_SUCCESS, data));
                                        })
                                        .catch(console.log)
                                })
                                .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH, error));
                        })
                        .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH, error));
                })
                .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH, error));
        })
};

export const fetchProjectAreaSections = (projectAreaId) => dispatch => {
    // Fetch sections from the offline store as it was saved at the time of component load.
    getDataFromStore(OBJECTSTORE_PROJECT_AREA_SECTIONS)
        .then(projectAreaSections => {
            let data = projectAreaSections.filter(x => x.projectAreaId === projectAreaId);
            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_PROJECT_AREAS_SECTIONS, data);
        })
};

export const fetchCodeGroupsCodes = (codeGroupId) => dispatch => {
    // Fetch codes from the offline store as it was saved at the time of component load.
    getDataFromStore(OBJECTSTORE_ROQ_CODES)
        .then(codeGroupCodes => {
            let data = codeGroupCodes.filter(x => x.codeGroupId === codeGroupId);
            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_CODE_GROUPS_CODES, data);
        });
};

export const fetchQuantityReportingEntries = (companyId, teamId, projectId, codeListTypeId, date) => dispatch => {
    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.QUANTITY_REPORTING_ENTRIES_INIT_TO_FETCH);

    // Store primary ids and date to Redux store for offline trigger function
    dispatchPromise(dispatch, constant.QUANTITY_REPORTING_ACTIVE_DATA, {
        date: date,
        customerProjectId: projectId,
        codeListTypeId: codeListTypeId,
    });

    // Generating API urls to get quantity reporting entries
    let quantitiesApiUrl = `/roqentries?projectId=${projectId}&fromDate=${getDateInFormat(date)}&toDate=${getDateInFormat(date)}&teamId=${teamId}&expand=roqCodeName,roqCodeUnitCode,roqCodeUnitName,CompanyId,isDeletableByUser`;
    let dayPermissionAPIUrl = `/roqdays/${getDateInFormat(date)}?projectId=${projectId}&companyId=${companyId}`

    // Setup the requests to axios
    const quantitiesRequest = axios.get(quantitiesApiUrl);
    const dayPermissionRequest = axios.get(dayPermissionAPIUrl);

    // axios class which will execute API and execute a promise of success.
    axios
        .all([quantitiesRequest, dayPermissionRequest])
        .then(axios.spread((...res) => {
            // Fetch APIs return data     
            let quantities = res[0].data;
            let permission = res[1].data;

            // Prepare quantity entry list's json to save data into offline store.
            // Also appending codeListTypeId as its not receiving from API
            quantities = quantities.map(x => x =
            {
                ...x,
                codeListTypeId: codeListTypeId,
                isOffline: false,
                isInserted: false,
                isDeleted: false
            });

            // Prepare roq day's permission json to save data into offline store.
            permission = {
                ...permission,
                projectId: projectId,
                companyId: companyId
            }

            // Save quntities and permission to offline store.
            saveDataInStore(OBJECTSTORE_ROQ_ENTRIES, quantities);
            saveDataInStore(OBJECTSTORE_ROQ_DAYS, [permission]);

            // Provide data to component's Redux store to map it on the UI
            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DAY_PERMISSION, permission.isLocked)
                .then(dispatchPromise(dispatch, constant.QUANTITY_REPORTING_ENTRIES_FETCH_SUCCESS, quantities));
        }))
        .catch(error => {
            // API fails to succeed request, so fetch data from offline store and update component Redux.
            getDataFromStore(OBJECTSTORE_ROQ_ENTRIES, "projectId, codeListTypeId, teamId, date", [projectId, codeListTypeId, teamId, getDateInFormat(date)])
                .then(quantities => {
                    getDataFromStore(OBJECTSTORE_ROQ_DAYS, "projectId, companyId, date", [projectId, companyId, getDateInFormat(date)])
                        .then(permission => {
                            let isLocked = true;
                            if (permission !== undefined && permission !== null) {
                                isLocked = permission.isLocked
                            }
                            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_DAY_PERMISSION, isLocked)
                                .then(dispatchPromise(dispatch, constant.QUANTITY_REPORTING_ENTRIES_FETCH_SUCCESS, quantities));
                        })
                })
                .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH, error));
        })
};

export const insertQuntityEntry = (quantity) => dispatch => {
    // Initiating action which will display the loader image on UI
    // When executed from a trigger function via network listener then doesn't require to show loader image
    if (!quantity.isOffline) {
        dispatchInit(dispatch, constant.QUANTITY_REPORTING_ENTRY_INIT_TO_INSERT);
    }

    //  Generating API URL to post a quantity reporting entry
    let quantitiesApiUrl = '/roqentries';

    // axios class which will execute API and execute a promise of success.
    axios
        .post(quantitiesApiUrl,
            {
                projectId: parseInt(quantity.projectId),
                roqCodeId: parseInt(quantity.roqCodeId),
                quantity: parseFloat(quantity.quantity),
                date: quantity.date,
                notes: quantity.notes,
                projectAreaId: parseInt(quantity.projectAreaId),
                projectAreaSectionId: parseInt(quantity.projectAreaSectionId),
                placeOnMap: quantity.placeOnMap
            }
        )
        // Since quantity has been inserted, so insert it to offline store, Redux store and update UI.
        .then((res) => {
            // Append quantity entry actual id and offline store flags
            let newQuantity = {
                ...quantity,
                id: res.data.id,
                isOffline: false,
                isInserted: false
            }
            // Delete from the offline store if quantity entry inserted as offline where it stored with a random number
            if (quantity.isOffline && quantity.isInserted) {
                deleteDataFromStore(OBJECTSTORE_ROQ_ENTRIES, [quantity.id, quantity.projectId, quantity.codeListTypeId, quantity.teamId, getDateInFormat(quantity.date)]);
            }
            // Save quantity entry to offline store as received actual id.
            saveDataInStore(OBJECTSTORE_ROQ_ENTRIES, [newQuantity]);
            // Save quantity entry to the Redux store to update the UI
            dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_ENTRY_INSERT_SUCCESS, { existingQuantity: quantity, newQuantity: newQuantity });
        })
        .catch(error => {
            // API fails to succeed request, so insert entry into offline store with offline flags and update component's Redux store.

            // If the offline quantity entry is already marked as inserted, then do nothing else insert to offline stores and update Redux store.
            if (!quantity.isOffline && !quantity.isInserted) {
                // Mark custom flag as offline inserted to post an entry by trigger function execute via network listener
                quantity = { ...quantity, isOffline: true, isInserted: true, isDeleted: false };
                // Save quantity entry to offline Store
                saveDataInStore(OBJECTSTORE_ROQ_ENTRIES, [quantity])
                    // Once quantity entry saved in the offline store, then update Redux store and update UI
                    .then(() => {
                        dispatchPromise(dispatch, constant.QUANTITY_REPORTING_OFFLINE_ENTRY_INSERT_SUCCESS, quantity)
                    })
                    .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_INSERT, error));
            }
        })
};

export const deleteQuntityEntry = (projectId, teamId, codeListTypeId, date, id, isDispatchInit) => dispatch => {
    // Initiating action which will display the loader image on UI
    // When executed from a trigger function via network listener then doesn't require to show loader image
    if (isDispatchInit) {
        dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_ENTRY_INIT_TO_DELETE, id);
    }

    // Generating API url to delete a quantity reporting entry
    let quantitiesApiUrl = `/roqentries/${id}`;

    // axios class which will execute API and execute a promise of success.
    axios
        .delete(quantitiesApiUrl)
        .then((res) => {

            // Since quantity entry has been deleted, so delete it from offline store as well.
            deleteDataFromStore(OBJECTSTORE_ROQ_ENTRIES, [id, projectId, codeListTypeId, teamId, getDateInFormat(date)])
                // Once quantity entry has deleted, then delete it from Redux store and update UI
                .then(() => dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_ENTRY_DELETE_SUCCESS, id))
                .catch(console.log);
        })
        .catch(error => {
            // API fails to succeed request, so mark quantity as deleted entry into an offline store with offline flags and update component's Redux store.
            getDataFromStore(OBJECTSTORE_ROQ_ENTRIES, "projectId, codeListTypeId, teamId, date", [projectId, codeListTypeId, teamId, getDateInFormat(date)])
                .then(quantities => {
                    let quantity = quantities.filter(x => x.id === id)[0];
                    // If quantity entry is already marked as deleted, then do nothing else update in offline store
                    if (!quantity.isDeleted) {
                        // Mark quantity as offline deleted to delete an entry by trigger function execute via network listener
                        quantity = { ...quantity, isOffline: true, isDeleted: true }
                        // Save quantity entry to offline Store
                        saveDataInStore(OBJECTSTORE_ROQ_ENTRIES, [quantity])
                            // Once quantity entry saved in the offline store, then update Redux and UI
                            .then(() => dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_ENTRY_OFFLINE_DELETE_SUCCESS, quantity))
                            .catch(console.log);
                    }
                })
                .catch(err => dispatchError(dispatch, constant.QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_DELETE, err));
        })
};

export const fetchPeriodTotals = (teamId, projectId, date) => dispatch => {
    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.QUANTITY_REPORTING_WEEKLY_TOTAL_INIT_TO_FETCH);

    // Generating API url to get a weekly total of quantities
    let periodTotalApiUrl = `roqperiodtotals?projectId=${projectId}&teamId=${teamId}&fromDate=${getDateInFormat(date)}&period=week`;

    // axios class which will execute API and execute a promise of success.
    axios
        .get(periodTotalApiUrl)
        .then((res) => {
            let periodStartingDate = getDateInFormat(res.data.fromDate),
                periodEndingDate = getDateInFormat(res.data.toDate),
                filterDate = getDateInFormat(date);
            // We are caching only current week period totals, so making sure the date is part of the current period
            if (filterDate >= periodStartingDate && filterDate <= periodEndingDate) {
                // Prepare current week period total json tables to save data into offline store.
                let period = {
                    projectId: projectId,
                    teamId: teamId,
                    name: res.data.name,
                    fromDate: res.data.fromDate,
                    toDate: res.data.toDate
                }
                let periodTotals = res.data.totals.map(x => x = { projectId: projectId, teamId: teamId, ...x });
                // Save current week period total to offline store.
                saveDataInStore(OBJECTSTORE_ROQ_PERIOD, [period]);
                saveDataInStore(OBJECTSTORE_ROQ_PERIOD_TOTALS, periodTotals);
            }
            // Provide data to component's Redux to map it on the UI
            dispatchPromise(dispatch, constant.QUANTITY_REPORTING_WEEKLY_TOTAL_FETCH_SUCCESS, res.data)
        })
        // API fails to succeed request, so fetch current week totals from offline store
        .catch(() => {
            getDataFromStore(OBJECTSTORE_ROQ_PERIOD, "projectId, teamId", [projectId, teamId])
                .then(period => {
                    period = period[0];
                    let periodStartingDate = getDateInFormat(period.fromDate),
                        periodEndingDate = getDateInFormat(period.toDate),
                        filterDate = getDateInFormat(date);
                    // We have cached only current week period totals, so making sure the date is part of the current period
                    if (filterDate >= periodStartingDate && filterDate <= periodEndingDate) {
                        // Fetch period totals from store 
                        getDataFromStore(OBJECTSTORE_ROQ_PERIOD_TOTALS, "projectId, teamId", [projectId, teamId])
                            .then(periodTotals => {
                                // Prepare current week period total json.
                                let returnPeriodTotals = {
                                    name: period.name,
                                    fromDate: period.name,
                                    toDate: period.name,
                                    totals: periodTotals.map(x => x = {
                                        roqCodeId: x.periodTotals,
                                        roqCodeName: x.roqCodeName,
                                        unitCode: x.unitCode,
                                        quantity: x.quantity
                                    })
                                }
                                // Provide data to component's Redux to map it on the UI
                                dispatchPromise(dispatch, constant.QUANTITY_REPORTING_WEEKLY_TOTAL_FETCH_SUCCESS, returnPeriodTotals)
                            })
                            .catch(err => dispatchInit(dispatch, constant.QUANTITY_REPORTING_WEEKLY_TOTAL_EXCEPTION_WHILE_FETCH))
                    }
                })
                .catch(err => dispatchInit(dispatch, constant.QUANTITY_REPORTING_WEEKLY_TOTAL_EXCEPTION_WHILE_FETCH))
        })
};

export const updateOfflinePeriodTotals = (teamId, projectId, date, roqCodeId, quantity, action) => dispatch => {
    getDataFromStore(OBJECTSTORE_ROQ_PERIOD, "projectId, teamId", [projectId, teamId])
        .then(period => {
            period = period[0];
            let periodStartingDate = getDateInFormat(period.fromDate),
                periodEndingDate = getDateInFormat(period.toDate),
                filterDate = getDateInFormat(date);
            // We have cached only current week period totals, so making sure the date is part of the current period
            if (filterDate >= periodStartingDate && filterDate <= periodEndingDate) {
                // Fetch codeId's period totals from store 
                getDataFromStore(OBJECTSTORE_ROQ_PERIOD_TOTALS, "projectId, teamId, roqCodeId", [projectId, teamId, roqCodeId])
                    .then(periodTotals => {
                        if (action === constant.ROQ_WEEKLY_TOTAL_ADD) {
                            // Adding quantity
                            periodTotals = periodTotals.map(x => x = { ...x, quantity: (parseFloat(x.quantity) + parseFloat(quantity)) });
                        }
                        if (action === constant.ROQ_WEEKLY_TOTAL_DELETE) {
                            // Deleting quantity
                            periodTotals = periodTotals.map(x => x = { ...x, quantity: (parseFloat(x.quantity) - parseFloat(quantity)) });
                        }
                        // Saving totals back to store for offline use.
                        saveDataInStore(OBJECTSTORE_ROQ_PERIOD_TOTALS, periodTotals);
                    })
            }
        })
}

export const updatePriceCodeListEmptyAlert = (isShow) => dispatch => {
    dispatch({
        type: constant.QUANTITY_REPORTING_CHECK_PRICE_CODE_LIST,
        payload: isShow
    })
}

/*
* Following functions are used to trigger offline quantities 
* When quantity found inserted, then it will rise post request to the server
* When quantity found deleted, then it will rise delete request to the server
*/
export const triggerOfflineRoqEntries = () => dispatch => {
    // Reading all quantity entries from an offline store which are marked as deleted or inserted.
    getDataFromStore(OBJECTSTORE_ROQ_ENTRIES)
        .then(
            entries => {
                processCachedEntry(entries.filter(x => x.isOffline && (x.isDeleted || x.isInserted)), dispatch);
            })
        .catch(console.log);
};
async function processCachedEntry(entries, dispatch) {
    // Map all array items to promises
    const promises = entries.map(entry => processEntry(entry, dispatch));
    // Wait until all promises are resolved
    await Promise.all(promises);
}
async function processEntry(entry, dispatch) {
    // Wait function to execute and returns its promise
    await serverInAction(entry, dispatch);
}
async function serverInAction(entry, dispatch) {
    return new Promise(resolve => {
        // When quantity entry inserted and deleted offline, then delete it from offline and Redux stores  
        if (entry.isInserted && entry.isDeleted) {
            // Delete quantity entry from an offline store
            deleteDataFromStore(OBJECTSTORE_ROQ_ENTRIES, [entry.id, entry.projectId, entry.codeListTypeId, entry.teamId, getDateInFormat(entry.date)])
                // Once the quantity has deleted, then delete it from Redux store and update the UI.
                .then(() => dispatchSuccess(dispatch, constant.QUANTITY_REPORTING_ENTRY_DELETE_SUCCESS, entry.id))
        }
        else {
            // When quantity entry deleted offline then dispatches delete action  
            if (entry.isDeleted) {
                dispatch(deleteQuntityEntry(entry.projectId, entry.teamId, entry.codeListTypeId, entry.date, entry.id, false))
            }
            // When quantity entry inserted offline then dispatches the insert action  
            else {
                dispatch(insertQuntityEntry(entry))
            }
        }
        resolve(true);
    });
}

