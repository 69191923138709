import React from "react";
import InputBox, { LabelHeading } from "../../../components/Form/InputBox";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Button";
import { withTranslation } from "react-i18next";
import {getStandardDate,getStandardTime} from "../../../../src/utils";

let getSelected = (id, data) => {
  let r = null;
  data.forEach(e => {
    if (e.id === id) {
      r = e;
    }
  });
  if (r) return r;
  return null;
};

class StatusForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }
  handleStatus = (parent, value) => {
    this.props.update(parent, value);
  };
  render() {
    let { data,t } = this.props;
    if (!data) return null;
    return (
      <div>
        {data.map((e, i) => (
          <StatusGroup
            data={e}
            key={i}
            handleChange={this.handleChange}
            handleStatus={this.handleStatus}
            loading={this.props.loading}
            trns={t}
          />
        ))}
      </div>
    );
  }
}

export default (withTranslation() (StatusForm));

class StatusGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remark: this.props.data.remark,
      touched: false,
      loading: this.props.loading
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }
  handleSave = () => {
    this.props.handleStatus(this.props.data, this.state.remark);
  };
  handleChange = (name, value) => {
    this.setState({
      [name]: value,
      touched: !!(this.props.remark !== value)
    });
  };
  render() {
    let { data ,trns} = this.props;
    let { loading, touched } = this.state;
    return (
      <div className="form-box mt-4 border-bottom1">
        <h5>{data.name}</h5>
        <LabelHeading
          lgInline={true}
          heading={trns("label.Booked_date")}
          text={`${getStandardDate(data.scheduledDate)} - ${getStandardTime(data.scheduledTime)}`}
        />

        <Dropdown
          lgInline={true}
          alignCenter={true}
          withIcon={true}
          name={trns("text.Status")}
          disabled={data.userInputLocked}
          selected={getSelected(data.statusId, data.availableStatuses)}
          onChange={k => this.props.handleStatus(data, k)}
          list={data.availableStatuses}
          selectText={trns("text.Select_a_project")}
        />
        <InputBox
          lgInline={true}
          heading={trns("control.Notes")}
          placeholder={trns("control.Notes")}
          name="remark"
          textarea={true}
          disabled={data.userInputLocked}
          rows={2}
          value={this.state.remark}
          update={this.handleChange}
        />
        {touched ? (
          <div className="form-group row row-reset align-items-center">
            <div className="col-lg-1 col-12 padding-reset">
              <label className="no-margin">Save Notes</label>
            </div>
            <div className="col-lg-11 col-12 padding-reset">
              <Button
                onClick={this.handleSave}
                className="btn btn-primary ml-1"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}

        <div className="text-right small">
          <p>
            {trns("label.Last_updated_by")}: {data.updatedByUserName} {`${getStandardDate(data.updatedTime)} ${getStandardTime(data.updatedTime)}`}{" "}
            <i className="fa fa-eye"></i>
          </p>
        </div>
      </div>
    );
  }
}
