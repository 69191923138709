import { getItemsbyIndex, getKeysbyIndex, openObjectStore,getAllItems } from "../../db";

export const OBJECTSTORE_INSTPLANS = "installation_plans";

// export const ifOffline = (id, forward) => {
//   return new Promise((resolve, reject) => {
//     getItemById(OBJECTSTORE_INSTPLANS, id)
//       .then((data) => {
//         if (data) return resolve(forward);
//         else return reject(false);
//       })
//       .catch((error) => {
//         return reject(false);
//       });
//   });
// };

export const takeOfflineInstPlan = (instPlan) => {
  return new Promise((resolve, reject) => {
    if (!instPlan) return reject(false);

    openObjectStore(OBJECTSTORE_INSTPLANS, "readwrite").then((objectStore) => {
      let selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
      instPlan["project_id"] = selectedProject.id;
      instPlan["offline"] = true;
      let request = objectStore.put(instPlan);
      request.onsuccess = () => {
        return resolve(instPlan);
      };
      request.onerror = function (event) {
        console.log("Object store put error: ", event.target.error);
        return reject(event.target.error);
      };
    });
  });
};

export const putOfflineTag = (instPlans, keys) => {
  return new Promise((resolve, reject) => {
    if (!instPlans) return reject(false);
    let newInstPlans = instPlans.map((e) => {
      keys.includes(e.id) ? (e["offline"] = true) : (e["offline"] = false);
      return e;
    });
    return resolve(newInstPlans);
  });
};

export const tagInstPlans = (project_id, instPlans) => {
  return new Promise((resolve, reject) => {
    getKeysbyIndex(OBJECTSTORE_INSTPLANS, "project_id", project_id)
      .then((keys) => putOfflineTag(instPlans, keys))
      .then(resolve)
      .catch(reject);
  });
};

export const getInstPlans = (project_id) => {
  return new Promise((resolve, reject) => {
    getItemsbyIndex(OBJECTSTORE_INSTPLANS, "project_id", project_id, true).then(resolve).catch(reject);
  });
};

export const getAllInstallationPlans = ()=>{
  return new Promise((resolve, reject)=>{
    getAllItems(OBJECTSTORE_INSTPLANS).then(data=>{
      return resolve(data);
    }).catch(reject)
  })
}
