import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({

    onUpdate: registration => {

        console.log("Initial ServiceWorker Registration method called.")

        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {

            console.log("A new version waiting to be installed. " +
                "Showing notification to the user before triggering update.");

            alert("A new version is available. Tap your screen to update.");

            // We setup an event listener to automatically reload the page
            // after the Service Worker has been updated, this will trigger
            // on all the open tabs of our application, so that we don't leave
            // any tab in an incosistent state
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    window.location.reload(true)
                }
            });

            // We send the SKIP_WAITING message to tell the Service Worker
            // to update its cache and flush the old one
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    }
});
