import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./rootReducer";

export const history = createBrowserHistory();
const routerHistoryMiddleware = routerMiddleware(history);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancer(applyMiddleware(thunk, routerHistoryMiddleware) /* enhancer(middleware) */)
  );
}
