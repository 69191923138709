import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

class RedirectHome extends React.Component {
  constructor(props) {
    super(props);
    if (props.isLoggedIn) {
      props.redirect("/home");
    } else {
      props.redirect("/login");
    }
  }
  render() {
    return <div className="test"> {"Loading Data..."} </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (path) => dispatch(push(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectHome);
