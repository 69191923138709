import * as constant from "./constants";
import axios from "axios";
import { genQuery, dispatchInit, dispatchError, dispatchSuccess } from "../../utils";

import { tagInstPlans, getInstPlans } from "../../offline/os/installationPlan/enteries";

export const fetchInstPlans = (project_id, search, filter) => dispatch => {
  // generate query
  let query = genQuery(search, filter);
  let url = `/projects/${project_id}/installationplans?${query}`;
  // instPlans fetch init (loader)
  dispatchInit(dispatch, constant.INSTPLANS_FETCH_INIT);
  axios
    .get(url)
    .then(res => tagInstPlans(project_id, res.data))
    .then(data => dispatchSuccess(dispatch, constant.INSTPLANS_FETCH_SUCCESS, data))
    .catch(error => {
      // on network fail: fetch offline
      getInstPlans(project_id)
        .then(data => dispatchSuccess(dispatch, constant.INSTPLANS_FETCH_SUCCESS, data))
        .catch(err => dispatchError(dispatch, constant.INSTPLANS_FETCH_ERROR, error));
    });
};

export const selectIPlan = instPlan => dispatch => {
  dispatchSuccess(dispatch, constant.INSTPLAN_SELECT_SUCCESS, instPlan, `installationplans/${instPlan.id}`, "selectedInstPlan");
};

export const selectProject = () => dispatch => {
  dispatchSuccess(dispatch, constant.INSTPLAN_SELECT_PROJECT, {}, "/projects", "selectedProject");
};

export const fetchProjectActivities = project_id => dispatch => {
  // projectActivities fetch init (loader)
  dispatchInit(dispatch, constant.PROJECT_ACTIVITIES_FETCH_INIT);
  let url = `/projects/${project_id}/installationactivities`;
  axios
    .get(url)
    .then((res) => dispatchSuccess(dispatch, constant.PROJECT_ACTIVITIES_FETCH_SUCCESS, res.data))
    .catch(error => dispatchError(dispatch, constant.PROJECT_ACTIVITIES_FETCH_ERROR, error));
};
