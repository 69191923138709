/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const CODE_LIST_TYPE_FETCH_INIT = "ModuleInitials/CODE_LIST_TYPE_FETCH_INIT";
export const CODE_LIST_TYPE_FETCH_SUCCESS = "ModuleInitials/CODE_LIST_TYPE_FETCH_SUCCESS";
export const CODE_LIST_TYPE_EXCEPTION_WHILE_FETCH = "ModuleInitials/CODE_LIST_TYPE_EXCEPTION_WHILE_FETCH";
export const CODE_LIST_TYPE_SELECT = "ModuleInitials/CODE_LIST_TYPE_SELECT";
export const CODE_LIST_TYPE_UPDATE_INIT = "ModuleInitials/CODE_LIST_TYPE_UPDATE_INIT";
export const CODE_LIST_TYPE_UPDATE_SUCCESS = "ModuleInitials/CODE_LIST_TYPE_UPDATE_SUCCESS";
export const CODE_LIST_TYPE_EXCEPTION_WHILE_UPDATE = "ModuleInitials/CODE_LIST_TYPE_EXCEPTION_WHILE_UPDATE";
export const LOGOUT_CLEAR_CODE_LIST = "ModuleInitials/LOGOUT_CLEAR_CODE_LIST";
