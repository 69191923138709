import * as constant from "./constants";

const initialState = {
  isLoadingCustomers: false,
  isLoadingProjects: false,
  error: false,
  errorMsg: null,
  customers: [],
  projects: [],
  selectedCustomer: JSON.parse(localStorage.getItem("selectedCustomer")) || {},
  selectedProject: JSON.parse(localStorage.getItem("selectedProject")) || {},
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case constant.CUSTOMER_FETCH_INIT:
      return {
        ...state,
        isLoadingCustomers: true
      };
    case constant.CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        isLoadingCustomers: false,
        error: false,
        errorMsg: null,
        customers: action.payload
      };
    case constant.CUSTOMER_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoadingCustomers: false,
        error: true,
        errorMsg: action.payload,
        customers: []
      };
    case constant.CUSTOMER_PROJECT_FETCH_INIT:
      return {
        ...state,
        isLoadingProjects: true
      };
    case constant.CUSTOMER_PROJECT_FETCH_SUCCESS:
      return {
        ...state,
        isLoadingProjects: false,
        error: false,
        errorMsg: null,
        projects: action.payload
      };
    case constant.CUSTOMER_PROJECT_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoadingProjects: false,
        error: true,
        errorMsg: action.payload,
        projects: []
      };
    case constant.CUSTOMER_SELECT:
      localStorage.setItem("selectedCustomer", JSON.stringify(action.payload));
      return {
        ...state,
        selectedCustomer: action.payload
      };
    case constant.CUSTOMER_PROJECT_SELECT:
      localStorage.setItem("selectedProject", JSON.stringify(action.payload));
      return {
        ...state,
        selectedProject: action.payload
      };
    case constant.CUSTOMER_PROJECT_REMOVE:
      localStorage.removeItem("selectedProject");
      return {
        ...state,
        selectedProject: {}
      };
    case constant.CUSTOMER_REMOVE:
      localStorage.removeItem("selectedCustomer");
      return {
        ...state,
        selectedCustomer: {}
      };
    case constant.LOGOUT_CLEAR_PROJECT:
      localStorage.removeItem("selectedCustomer");
      localStorage.removeItem("selectedProject");
      return {
        ...state,
        selectedCustomer: {},
        selectedProject: {},
        customers: [],
        projects: []
      }
    default:
      return state;
  }
};

export default project;
