import { getItemById, putInStore, openDatabase, ifOffline } from "../../db";

export const OBJECTSTORE_DETAILS_IP = "installation_details";

export const getDetails = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_DETAILS_IP, instPlan_id).then(resolve).catch(reject);
  });
};

export const saveDetails = (instPlan_id, data) => {
  return new Promise((resolve, reject) => {
    ifOffline(instPlan_id, data)
      .then((data) => putInStore(OBJECTSTORE_DETAILS_IP, data))
      .then((k) => resolve(true))
      .catch(reject);
  });
};

export const updateDetailsOffline = (instPlan_id, patchObj) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_DETAILS_IP, instPlan_id)
      .then((obj) => {
        openDatabase().then((db) => {
          if (!db) return reject(false);
          db.onsuccess = function (event) {
            let db = event.target.result;
            let tx = db.transaction(OBJECTSTORE_DETAILS_IP, "readwrite");
            let objectStore = tx.objectStore(OBJECTSTORE_DETAILS_IP);
            objectStore.put({ ...obj, ...patchObj });
            tx.oncomplete = (e) => resolve(patchObj);
            tx.onerror = (e) => reject(false);
          };
        });
      })
      .catch((err) => {
        return reject();
      });
  });
};
