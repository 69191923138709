import * as constant from "./constants";

const initialState = {
  isLoading: false,
  isActivitiesLoading: false,
  projectActivities: [],
  error: null,
  instPlansList: [],
  selectedInstPlan: JSON.parse(localStorage.getItem("selectedInstPlan")) || {}
};

const instPlans = (state = initialState, action) => {
  switch (action.type) {
    case constant.INSTPLANS_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.INSTPLANS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        instPlansList: action.payload
      };
    case constant.INSTPLANS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        instPlansList: [],
        error: action.payload
      };
    case constant.INSTPLAN_SELECT_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.INSTPLAN_SELECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedInstPlan: action.payload
      };
    case constant.INSTPLAN_SELECT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case constant.PROJECT_ACTIVITIES_FETCH_INIT:
      return {
        ...state,
        isActivitiesLoading: true
      };
    case constant.PROJECT_ACTIVITIES_FETCH_SUCCESS:
      return {
        ...state,
        isActivitiesLoading: false,
        error: null,
        projectActivities: action.payload
      };
    case constant.PROJECT_ACTIVITIES_FETCH_ERROR:
      return {
        ...state,
        isActivitiesLoading: false,
        projectActivities: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default instPlans;
