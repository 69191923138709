import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
export default class DropDown extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  trigger = value => {
    this.props.onChange(value);
  };

  render() {
    if (!(this.props.list.length > 0)) return <div>--list empty--</div>;
    let { dropdownOpen } = this.state;
    let { selected, name, selectText, disabled, list, lgInline, alignCenter, withIcon, customClassName } = this.props;
    let displaySelected = <span>{selected}</span>;
    if (withIcon) {
      displaySelected = (
        <span>
          <i className={`${selected.icon} ${selected.iconColor} mr-2`}></i>
          {selected.name}
        </span>
      );
    }
    return (
      <div className={`form-group row row-reset ${alignCenter ? "align-items-center" : ""}${(customClassName !== undefined && customClassName !== null) ? " " + customClassName : ""}`}>
        <div className={`padding-reset ${lgInline ? "col-lg-1 col-12" : "col-lg-12"}`}>
          <label className={`${lgInline ? "no-margin" : ""}`}>
            {name}
            {withIcon ? <span className={`${selected.icon} ${selected.iconColor} ml-2`}></span> : null}
          </label>
        </div>
        <div className={`padding-reset ${lgInline ? "col-lg-11 col-12" : "col-lg-12"}`}>
          <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
            <DropdownToggle className={`btn-drop ${selected ? "selected" : null}`} disabled={disabled} caret>
              {selected ? displaySelected : selectText || "Select"}
            </DropdownToggle>
            <DropdownMenu>
              {list.map((o, i) => (
                <DropdownItem onClick={e => this.trigger(o)} key={i}>
                  {withIcon ? <span className={`${o.icon} ${o.iconColor} mr-2`}></span> : null}
                  {o.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}
