import React from "react";
import Button from "./Button";

let AlertBox = ({ onOk, heading, text, okButtonText }) => {
    return (
        <div className="confirmation-box">
            <div className="main container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h5>{heading}</h5>
                        <hr />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <h6>{text}</h6>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <Button
                            type="button"
                            className="btn btn-primary btn-one btn-100"
                            onClick={onOk}
                        >{okButtonText}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertBox;
