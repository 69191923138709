import React, { Fragment } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { logout, updateUserSelectedLanguage, declineLogout, performLogout } from "../containers/LoginPage/actions";
import Navbar from "../components/Navbar";
import PageNotFound from "../components/Pages/PageNotFound";
import Modules from "../containers/Home";
import { removeModule } from "../containers/Home/actions";
import ProjectSelectionPage from "../containers/ModuleInitials/Project";
import TeamSelectionPage from "../containers/ModuleInitials/Team";
import CodeListTypeSelectionPage from "../containers/ModuleInitials/CodeListType";
import InstPlansPage from "../containers/InstPlansPage";
import DetailsPage from "../containers/DetailsPage";
import ReportingOfQuantities from "../containers/ReportingOfQuantities";
import TimeReporting from "../containers/TimeReporting";


class RequireLogin extends React.Component {
  componentDidMount() {
    let { isLoggedIn, location } = this.props;
    //If user not logged-in, then route user to login page 
    if (!isLoggedIn) {
      this.props.redirect("/login");
    }
    else {
      this.props.redirect(location)
    }
  }
  redirectToHome = () => {
    this.props.removeModule();
    this.props.redirect("/home");
  }

  render() {
    return (
      <Fragment>
        <Navbar {...this.props} redirectToHome={this.redirectToHome} />
        <Switch>
          <Route exact path="/home" component={Modules} />
          <Route exact path="/projects" component={ProjectSelectionPage} />
          <Route exact path="/teams" component={TeamSelectionPage} />
          <Route exact path="/codelisttypes" component={CodeListTypeSelectionPage} />
          <Route exact path="/installationplans" component={InstPlansPage} />
          <Route exact path="/installationplans/:id" component={DetailsPage} />
          <Route exact path="/reportingofquantities" component={ReportingOfQuantities} />
          <Route exact path="/timereporting" component={TimeReporting} />
          <Route component={PageNotFound} />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    lng: state.app.lng,
    online: state.app.online,
    location: state.router.location.pathname,
    user: state.auth.user,
    auth: state.auth,
    isLoggedIn: state.auth.isLoggedIn,
    selectedProject: state.selectProject.selectedProject,
    home: state.modules
  };
};
const mapDispatchToProps = dispatch => {
  return {
    redirect: path => dispatch(push(path)),
    logout: () => dispatch(logout()),
    declineLogout: () => dispatch(declineLogout()),
    performLogout: () => dispatch(performLogout()),
    updateUserSelectedLanguage: (user, lang) => dispatch(updateUserSelectedLanguage(user, lang)),
    removeModule: () => dispatch(removeModule())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RequireLogin));