import * as constant from "./constants";

const initialState = {
  isLoading: false,
  saving: false,
  updatingActivity: false,
  error: null,
  detailsList: null,
  activitiesList: null,
  imagesList: null
  // etags: {}
};

const instPlans = (state = initialState, action) => {
  switch (action.type) {
    case constant.DETAILS_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        detailsList: action.payload
        // detailsList: action.payload.data,
        // etags: { ...state.etags, [action.payload.url]: action.payload.etag }
      };
    case constant.DETAILS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        detailsList: null
      };
    case constant.DETAILS_UPDATE_INIT:
      return {
        ...state,
        saving: true
      };
    case constant.DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        saving: false
      };
    case constant.DETAILS_UPDATE_ERROR:
      return {
        ...state,
        saving: false
      };
    case constant.ACTIVITY_UPDATE_INIT:
      return {
        ...state,
        updatingActivity: true
      };
    case constant.ACTIVITY_UPDATE_SUCCESS:
      return {
        ...state,
        updatingActivity: false
      };
    case constant.ACTIVITY_UPDATE_ERROR:
      return {
        ...state,
        updatingActivity: false
      };
    case constant.ACTIVITIES_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.ACTIVITIES_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        activitiesList: action.payload
      };
    case constant.ACTIVITIES_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        activitiesList: null
      };
    case constant.IMAGES_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.IMAGES_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        imagesList: action.payload
      };
    case constant.IMAGES_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        imagesList: null
      };
    default:
      return state;
  }
};

export default instPlans;
