import React from "react";

let MenuButton = ({ icon, text, onClick, cssClass, theme, loading }) => {
    return (
        <button type="button" className={`btn ${cssClass} btn-lg btn-block menubutton ${theme}`} onClick={onClick}>
            {
                loading ?
                    <div className="loader"></div> :
                    <>
                        <span className={icon} aria-hidden="true"></span> {text}
                    </>
            }
        </button>
    );
};

export default MenuButton;
