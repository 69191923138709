import React from "react";
import InputBox from "../../../components/Form/InputBox";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Button";
import Date from "../../../components/DateInput";
// import all the components we are going to use.
import { withTranslation } from "react-i18next";

class TimeReportSiteForm extends React.Component {
  render() {
    // properties
    let {
      dateId,
      minDate,
      maxDate,
      isLoadingJobNumbers,
      jobNumbers,
      selectedJobNumber,
      isLoadingTimeCodes,
      timeCodes,
      selectedTimeCode,
      regTime,
      overTime,
      quantity,
      notes,
      disableRegularTimeButton,
      disableOverTimeButton,
      regularTimeButton,
      overTimeButton,
      quantityButton,
      disabled,
      t,
    } = this.props;
    // events
    let {
      OnDateSelect,
      OnJobNumberSelect,
      OnTimeCodeSelect,
      OnUpdate
    } = this.props
    let { isTimeCode, isOverTime } = selectedTimeCode;
    if (isTimeCode === undefined || isTimeCode === null) {
      isTimeCode = true;
      isOverTime = false;
    }
    return (
      <form className="center-third">
        <Date
          id={dateId}
          label={t("label.Date")}
          onDateSelect={OnDateSelect}
          minDate={minDate}
          maxDate={maxDate}
        ></Date>
        {isLoadingJobNumbers ? (
          <div className="loader"></div>
        ) : (
            <Dropdown
              selected={selectedJobNumber.name}
              list={jobNumbers}
              name={t("label.Job_number")}
              onChange={OnJobNumberSelect}
              disabled={disabled}
              selectText={`${t("label.Select")} ${t("label.Job_number")}`}
            />
          )}
        {isLoadingTimeCodes ? (
          <div className="loader"></div>
        ) : (
            <Dropdown
              selected={selectedTimeCode.name}
              list={timeCodes}
              name={`${t("label.Select")} ${t("label.Code")}`}
              onChange={OnTimeCodeSelect}
              disabled={disabled}
              selectText={`${t("label.Select")} ${t("label.Code")}`}
            />
          )}
        <div className="row">
          {
            isTimeCode ?
              !isOverTime ?
                <>
                  <div className="col">
                    <InputBox
                      type="number"
                      heading={`${t("label.Regular")} ${t("label.Time")}`}
                      placeholder={t("label.Time")}
                      name="txtRegTime"
                      value={regTime}
                      update={OnUpdate}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col" style={{ marginTop: 35 }}>
                    <Button
                      loading={false}
                      className="btn btn-primary"
                      disabled={disabled ? disabled : disableRegularTimeButton}
                      onClick={(button) => this.props.OnInsert(regularTimeButton)}
                    >
                      {t("control.Add")}
                    </Button>
                  </div>
                </> :
                <>
                  <div className="w-100"></div>
                  <div className="col">
                    <InputBox
                      type="number"
                      heading={t("label.Overtime")}
                      placeholder={t("label.Time")}
                      name="txtOverTime"
                      value={overTime}
                      update={OnUpdate}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col" style={{ marginTop: 35 }}>
                    <Button
                      loading={false}
                      className="btn btn-primary"
                      disabled={disabled ? disabled : disableOverTimeButton}
                      onClick={(button) => this.props.OnInsert(overTimeButton)}
                    >
                      {t("control.Add")}
                    </Button>
                  </div>
                </> :
              <>
                <div className="col">
                  <InputBox
                    type="number"
                    heading={t("label.Quantity")}
                    placeholder={t("label.Quantity")}
                    name="txtQuantity"
                    value={quantity}
                    update={OnUpdate}
                    disabled={disabled}
                  />
                </div>
                <div className="col" style={{ marginTop: 35 }}>
                  <Button
                    loading={false}
                    disabled={disabled}
                    className="btn btn-primary"
                    onClick={(button) => this.props.OnInsert(quantityButton)}
                  >
                    {t("control.Add")}
                  </Button>
                </div>
              </>
          }
        </div>
        <InputBox
          heading={t("label.Notes")}
          placeholder={t("label.Notes")}
          name="txtNotes"
          textarea={true}
          value={notes}
          update={OnUpdate}
          disabled={disabled}
        />
      </form>
    );
  }
}

export default withTranslation()(TimeReportSiteForm);
