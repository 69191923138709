import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

import {
  getDetails,
  getActivities,
  getImages,
  saveImages,
  saveImageData,
  getImageData,
  saveDetails,
  saveActivities,
  getImagesTotal
} from "./actions";
import { takeOfflineInstPlan } from "../../offline/os/installationPlan/enteries";
import Button from "../Button";
import Checkbox from "../Checkbox";

class TakeOffline extends React.Component {
  constructor(props) {
    super(props);
    // to fix memory leak for async
    this._isMounted = false;
    this.state = {
      imgDownload: null,
      loading: false,
      images: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props && this.props.data && this.props.data.id) {
      let instPlan_id = this.props.data.id;
      this.setState({
        disabled: true,
        imgDownload: null
      });
      getImagesTotal(instPlan_id).then(imgDownload => {
        this.setState({
          imgDownload,
          disabled: false
        });
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      let instPlan_id = this.props.data.id;
      this.setState({
        disabled: true,
        imgDownload: null,
        images: false
      });
      this._isMounted &&
        getImagesTotal(instPlan_id).then(imgDownload => {
          this.setState({
            imgDownload,
            disabled: false
          });
        });
    }
  }
  componentWillUnmount() {
    this.setState({
      imgDownload: null,
      loading: false,
      images: false
    });
  }

  handleOnChange = (target, value) => {
    this.setState({
      [target]: value
    });
  };
  fetchData = data => {
    this.setState({ loading: true });
    let imgDownload = this.state.imgDownload;
    takeOfflineInstPlan(data)

    // openObjectStore(OBJECTSTORE_INSTPLANS, "readwrite")
    //   .then(objectStore => {
    //     let selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
    //     data["project_id"] = selectedProject.id;
    //     data["offline"] = true;
    //     return objectStore.put(data);
    //   })
      .then(k => {
        let { id } = data;
        Promise.all([
          getDetails(id).then(d => saveDetails(d)),
          getActivities(id).then(d => saveActivities(id, d)),
          getImages(id).then(d => saveImages(id, d))
        ]).then(all => {
          if (this.state.images && imgDownload && imgDownload.length > 0) {
            console.log("downloading data");
            Promise.all(imgDownload.map(getImageData))
              .then(imgToSave => Promise.all(imgToSave.map(saveImageData)))
              .then(k => {
                this.setState({ loading: false });
                this.props.toggle();
                this.props.putOffline(data);
              });
          } else {
            this.setState({ loading: false });
            this.props.toggle();
            this.props.putOffline(data);
          }
        });
      });
  };

  text = () => {
    let { imgDownload } = this.state;
    let { t } = this.props;
    return `${t("text.Download_images")} (${imgDownload.length})`;
  };

  render() {
    if (!this.props.enable) return null;
    let { t } = this.props;
    let { loading, imgDownload } = this.state;
    return (
      <div className="take-offline">
        <div className="main container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h5>{t("label.Work_offline")}</h5>
              <hr />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <h6>{t("text.Q_Take_this_installation_plan_offline")}</h6>
              <p className="mt-2 small">
                {t("text.Confirm_download_installation_plan_message")}
              </p>
            </div>
            <div className="col-lg-12">
              <p className="medium mb-2">{t("label.Optional")}</p>
              <div className="checkbox-box">
                {imgDownload ? (
                  imgDownload.length > 0 ? (
                    <Fragment>
                      <Checkbox
                        text={this.text()}
                        name="images"
                        value={this.state.images}
                        onChange={this.handleOnChange}
                      />
                      {this.state.images && (
                        <p className="mt-2 small">{t("text.Note_downloading_images_may_take_some_time")}</p>
                      )}
                    </Fragment>
                  ) : (
                    <p className="mt-2 small">{t("text.No_images_to_download")}</p>
                  )
                ) : (
                  <p className="mt-2 small dot-loader">{t("text.Loading_data")}</p>
                )}
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <Button
                type="submit"
                className="btn btn-primary btn-one"
                onClick={e => this.fetchData(this.props.data)}
                loading={this.state.loading}
                disabled={this.state.disabled}
              >
                {t("label.Confirm")}
              </Button>
              {!loading && <span onClick={loading ? undefined : this.props.toggle}>{t("text.Cancel")}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TakeOffline);
