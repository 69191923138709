import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore, { history } from "./configureStore";
import Routes from "./Routes";

//Axios config (interceptors etc)
import withDetector from "./hoc/withDetector";

import "./axios-config";

import "./assets/styles/index.scss";

const RoutesWithDetector = withDetector(Routes);
const store = configureStore(/* provide initial state if any */);

class Index extends React.Component {
  render() {
    return (
      <Suspense fallback={"Loading"}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ToastContainer position={"top-center"} autoClose={2300} />
            <RoutesWithDetector />
          </ConnectedRouter>
        </Provider>
      </Suspense>
    );
  }
}

export default Index;
