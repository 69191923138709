import React from "react";

let Button = ({ type, className, children, loading, disabled, onClick }) => {
  return (
    <button type={type ? type : "button"} className={className} onClick={onClick} disabled={disabled || loading}>
      {loading ? <div className="loader"></div> : children}
    </button>
  );
};

export default Button;
