/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const INSTPLANS_FETCH_INIT = "InstPlansPage/INSTPLANS_FETCH_INIT";
export const INSTPLANS_FETCH_SUCCESS = "InstPlansPage/INSTPLANS_FETCH_SUCCESS";
export const INSTPLANS_FETCH_ERROR = "InstPlansPage/INSTPLANS_FETCH_ERROR";

export const INSTPLAN_SELECT_INIT = "InstPlansPage/INSTPLAN_SELECT_INIT";
export const INSTPLAN_SELECT_SUCCESS = "InstPlansPage/INSTPLAN_SELECT_SUCCESS";
export const INSTPLAN_SELECT_ERROR = "InstPlansPage/INSTPLAN_SELECT_ERROR";
export const INSTPLAN_SELECT_PROJECT = "InstPlansPage/INSTPLAN_SELECT_PROJECT";

export const PROJECT_ACTIVITIES_FETCH_INIT = "InstPlansPage/PROJECT_ACTIVITIES_FETCH_INIT";
export const PROJECT_ACTIVITIES_FETCH_SUCCESS = "InstPlansPage/PROJECT_ACTIVITIES_FETCH_SUCCESS";
export const PROJECT_ACTIVITIES_FETCH_ERROR = "InstPlansPage/PROJECT_ACTIVITIES_FETCH_ERROR";

export const SITE_INSTALLATION_ROUTING_KEY = "installationplans";

