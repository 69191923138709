import React, { Component, Fragment } from "react";
const DRAWING_SHAPE_NONE = 0;
const DRAWING_SHAPE_LINE = 1;
const DRAWING_SHAPE_FREEHAND = 2;
const SHAPE_THICKNESS_DEFAULT = 3;
const SHAPE_THICKNESS_BOLDER = 5;
const STROKE_COLOR_YELLOW = "yellow";
const STROKE_COLOR_GREEN = "green";
const STROKE_COLOR_RED = "red";
const SCREEN_WIDTH_BUFFER = 35;

let imageCopyForUndo;
let imageCopyForRedo;
let straightLine;
let imageCopy;

const standardWidth = 950;
const standardHeight = 650;

class CanvasImage extends Component {
  state = {
    canvasWidth: standardWidth,
    canvasHeight: standardHeight,
    originalWidth: standardWidth,
    originalHeight: standardHeight,
    dimensionChanged: false,
    startPoint: { x: 0, y: 0 },
    endPoint: { x: 0, y: 0 },
    suposeToDraw: false,
    command: DRAWING_SHAPE_NONE,
    strokeColor: STROKE_COLOR_GREEN,
    isBold: false,
    undoAvailable: false,
    redoAvailable: false,
    scrollLock: false,
    numberOfDraws: 0,
    lineWidth: SHAPE_THICKNESS_DEFAULT
  };
  componentDidMount() {
    const image = new Image();
    let screenWidth = this.props.windowDimensions.width - SCREEN_WIDTH_BUFFER;
    image.src = this.props.img;
    const imageWidth = image.width;
    // Check with it from device then we have to get rid out out canvas scroll..
    if (screenWidth > standardWidth) {
      screenWidth = standardWidth;
    }
    if (imageWidth <= screenWidth) {
      this.setState({ canvasWidth: imageWidth, canvasHeight: image.height });
      this.canvasLine.width = imageWidth;
      this.canvasLine.height = image.height;
    } else {
      this.setState({ dimensionChanged: true, originalWidth: imageWidth, originalHeight: image.height });
      this.canvasHidden.width = imageWidth;
      this.canvasHidden.height = image.height;

      this.canvasLine.width = screenWidth;
      const scaleFactor = screenWidth / imageWidth;
      let imageHeight = image.height * scaleFactor;
      this.setState({ canvasHeight: imageHeight, canvasWidth: screenWidth });
      this.canvasLine.height = imageHeight;
    }

    this.showImage(this.canvasLine, "");
  }

  onCanvasMouseDown = mouseEvent => {
    if (mouseEvent.button !== 0 && !mouseEvent.touches) {
      return;
    }
    if (this.state.command === DRAWING_SHAPE_NONE) {
      return;
    }
    const point = this.relativeCoordinatesForEvent(mouseEvent);

    this.setState({
      suposeToDraw: true,
      startPoint: { x: point.x, y: point.y },
      endPoint: { x: point.x, y: point.y },
      undoAvailable: true,
      redoAvailable: false,
      numberOfDraws: this.state.numberOfDraws + 1
    });
    imageCopyForUndo = this.canvasLine.toDataURL();
    if (this.state.command === DRAWING_SHAPE_LINE) {
      straightLine = this.canvasLine.toDataURL();
    } else {
      straightLine = "";
    }
  };
  onCanvasMouseMove = mouseEvent => {
    const { suposeToDraw, command, startPoint, endPoint } = this.state;
    if (!suposeToDraw) {
      return;
    }
    const point = this.relativeCoordinatesForEvent(mouseEvent);
    this.setState({
      endPoint: { x: point.x, y: point.y }
    });
    if (this.state.command === DRAWING_SHAPE_LINE) {
      this.showImage(this.canvasLine, straightLine);
      this.drawLine(this.canvasLine, startPoint, endPoint);
    }
    if (command === DRAWING_SHAPE_FREEHAND) {
      this.drawDot(this.canvasLine, endPoint);
    }
  };
  onCanvasMouseUp = () => {
    const { command, startPoint, endPoint } = this.state;
    this.setState({
      suposeToDraw: false
    });
    if (command === DRAWING_SHAPE_LINE) {
      this.drawLine(this.canvasLine, startPoint, endPoint);
    }
    imageCopy = this.canvasLine.toDataURL();
    this.saveChangesToOriginalImage();
  };
  actionToDrawLine = () => {
    this.setState(prevState => ({
      command: prevState.command === DRAWING_SHAPE_LINE ? DRAWING_SHAPE_NONE : DRAWING_SHAPE_LINE,
      scrollLock:
        prevState.command === DRAWING_SHAPE_NONE || prevState.command === DRAWING_SHAPE_FREEHAND ? true : false
    }));
  };
  actionToDeleteDraw = () => {
    this.setState({
      command: DRAWING_SHAPE_NONE,
      isBold: false,
      undoAvailable: false,
      redoAvailable: false,
      scrollLock: false,
      numberOfDraws: 0
    });
    this.showImage(this.canvasLine, "");
  };
  actionToDrawFreeHand = () => {
    this.setState(prevState => ({
      command: prevState.command === DRAWING_SHAPE_FREEHAND ? DRAWING_SHAPE_NONE : DRAWING_SHAPE_FREEHAND,
      scrollLock: prevState.command === DRAWING_SHAPE_NONE || prevState.command === DRAWING_SHAPE_LINE ? true : false
    }));
  };
  actionChangeColor = color => {
    this.setState({
      strokeColor: color
    });
  };
  actionChangeLineWidth = flag => {
    this.setState({
      lineWidth: this.state.isBold ? SHAPE_THICKNESS_DEFAULT : SHAPE_THICKNESS_BOLDER,
      isBold: flag
    });
  };
  actionSaveCanvas = canvasLine => {
    let dataURL;
    if (this.state.dimensionChanged) {
      dataURL = this.canvasHidden.toDataURL("image/jpeg", 1);
    } else {
      dataURL = canvasLine.toDataURL("image/jpeg", 1);
    }
    if (this.state.numberOfDraws > 0) {
      this.props.saveImage(dataURL, true);
    }
    this.props.closeCanvas();
  };
  actionUndoCanvas = canvasLine => {
    if (!this.state.undoAvailable) {
      return;
    }
    imageCopyForRedo = canvasLine.toDataURL();
    this.showImage(canvasLine, imageCopyForUndo);
    imageCopy = imageCopyForUndo;
    this.saveChangesToOriginalImage();
    this.setState({
      undoAvailable: false,
      redoAvailable: true,
      numberOfDraws: this.state.numberOfDraws - 1
    });
  };
  actionRedoCanvas = canvasLine => {
    if (!this.state.redoAvailable) {
      return;
    }
    imageCopyForUndo = canvasLine.toDataURL();
    this.showImage(canvasLine, imageCopyForRedo);
    imageCopy = imageCopyForRedo;
    this.saveChangesToOriginalImage();
    this.setState({
      undoAvailable: true,
      redoAvailable: false,
      numberOfDraws: this.state.numberOfDraws + 1
    });
  };
  relativeCoordinatesForEvent(mouseEvent) {
    const boundingRect = this.canvasLine.getBoundingClientRect();
    const x = mouseEvent.clientX ? mouseEvent.clientX : mouseEvent.touches[0].clientX;
    const y = mouseEvent.clientY ? mouseEvent.clientY : mouseEvent.touches[0].clientY;
    return {
      x: x - boundingRect.left,
      y: y - boundingRect.top
    };
  }
  drawLine(canvasId, start, end) {
    const ctx = canvasId.getContext("2d");
    const { lineWidth, strokeColor } = this.state;
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
    ctx.closePath();
  }
  drawDot(canvasId, point) {
    const { startPoint } = this.state;
    this.drawLine(canvasId, startPoint, point);
    this.setState({
      startPoint: point
    });
  }
  showImage(canvasId, img) {
    const ctx = canvasId.getContext("2d");
    const image = new Image();
    image.src = img === "" ? this.props.img : img;
    image.onload = () => {
      ctx.drawImage(image, 0, 0, this.state.canvasWidth, this.state.canvasHeight);
    };
  }
  saveChangesToOriginalImage() {
    if (this.state.dimensionChanged) {
      const ctx = this.canvasHidden.getContext("2d");
      const image = new Image();
      image.src = imageCopy;
      image.onload = () => {
        ctx.drawImage(image, 0, 0, this.state.originalWidth, this.state.originalHeight);
      };
    }
  }

  render() {
    const { command, strokeColor, isBold, undoAvailable, redoAvailable, scrollLock, numberOfDraws } = this.state;
    return (
      <Fragment>
        <div ref={el => (this.container = el)} style={{ marginBottom: 40 }}>
          <div style={{ float: "left", width: "95%" }}>
            <div className="btn-group" style={customMargin}>
              <button
                onClick={this.actionToDrawLine}
                style={btnDrawingAdditionStyle(strokeColor)}
                className={"btn btn-info " + (command === DRAWING_SHAPE_LINE ? "active" : "")}
              >
                <i className="fa fa-bars" />
              </button>
              <button
                onClick={this.actionToDrawFreeHand}
                style={btnDrawingAdditionStyle(strokeColor)}
                className={"btn btn-info " + (command === DRAWING_SHAPE_FREEHAND ? "active" : "")}
              >
                <i className="fa fa-pencil" />
              </button>
            </div>
            <div className="btn-group" style={customMargin}>
              <button
                onClick={color => this.actionChangeColor(STROKE_COLOR_YELLOW)}
                style={{ color: STROKE_COLOR_YELLOW }}
                className={"btn btn-info " + (strokeColor === STROKE_COLOR_YELLOW ? "active" : "")}
              >
                <i className="fa fa-circle" />
              </button>
              <button
                onClick={color => this.actionChangeColor(STROKE_COLOR_RED)}
                style={{ color: STROKE_COLOR_RED }}
                className={"btn btn-info " + (strokeColor === STROKE_COLOR_RED ? "active" : "")}
              >
                <i className="fa fa-circle" />
              </button>
              <button
                onClick={color => this.actionChangeColor(STROKE_COLOR_GREEN)}
                style={{ color: STROKE_COLOR_GREEN }}
                className={"btn btn-info " + (strokeColor === STROKE_COLOR_GREEN ? "active" : "")}
              >
                <i className="fa fa-circle" />
              </button>
            </div>
            <button
              onClick={width => this.actionChangeLineWidth(!isBold)}
              style={customMargin}
              className={"btn btn-info fa fa-bold" + (isBold ? " active" : "")}
            />
            {undoAvailable && (
              <button
                onClick={canvasLine => this.actionUndoCanvas(this.canvasLine)}
                className="btn btn-info fa fa-undo"
                style={customMargin}
              />
            )}
            {redoAvailable && (
              <button
                onClick={canvasLine => this.actionRedoCanvas(this.canvasLine)}
                className="btn btn-info fa fa-repeat"
                style={customMargin}
              />
            )}
            {numberOfDraws > 0 && (
              <button onClick={this.actionToDeleteDraw} className="btn btn-info fa fa-trash" style={customMargin} />
            )}
            {numberOfDraws > 0 && (
              <button
                onClick={canvasLine => this.actionSaveCanvas(this.canvasLine)}
                className="btn btn-info fa fa-save"
              />
            )}
          </div>
          <div style={{ float: "right" }}>
            <span style={{ cursor: "pointer" }} onClick={this.props.closeCanvas}>
              <i className="fa fa-close"></i>
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            overflow: "auto",
            height: this.props.windowDimensions.height - 80,
            touchAction: scrollLock && "none"
          }}
        >
          <canvas
            ref={canvasLine => (this.canvasLine = canvasLine)}
            onTouchStart={e => this.onCanvasMouseDown(e)}
            onTouchMove={e => this.onCanvasMouseMove(e)}
            onTouchEnd={this.onCanvasMouseUp}
            onMouseDown={e => this.onCanvasMouseDown(e)}
            onMouseMove={e => this.onCanvasMouseMove(e)}
            onMouseUp={this.onCanvasMouseUp}
          ></canvas>
          <canvas ref={canvasHidden => (this.canvasHidden = canvasHidden)} style={{ display: "none" }}></canvas>
        </div>
      </Fragment>
    );
  }
}
const btnDrawingAdditionStyle = strokeColor => {
  return {
    color: strokeColor
  };
};
const customMargin = {
  marginRight: 3
};
export default CanvasImage;
