import * as constant from "./constants";
import axios from "axios";
import {
    dispatchInit,
    dispatchSuccess,
    dispatchPromise,
    dispatchError,
    getDateInFormat
} from "../../utils";

export const selectProject = () => dispatch => {
    dispatchSuccess(dispatch, constant.TIME_REPORTING_SELECT_PROJECT, {}, "/projects", "selectedProject");
};

export const getJobNumbers = () => dispatch => {
    // Generating API url
    let url = "/jobnumbers?isActive=true&limit=1000";

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_JOB_NUMBER_INIT_TO_FETCH);

    // axios class which will execute API and execute a promise of success.
    axios
        .get(url)
        .then((res) => {
            // Filtering active job number and mapping "Id - Name" to show an item in the dropdown
            let jobNumbers = res.data.filter(x => x.active === true)
                .map(x => x = {
                    ...x,
                    name: `${x.id} - ${x.name}`
                });
            dispatchSuccess(dispatch, constant.TIME_REPORTING_JOB_NUMBER_FETCH_SUCCESS, jobNumbers)
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_JOB_NUMBER_EXCEPTION_WHILE_FETCH, error)
        })
};

export const getTimeCodes = () => dispatch => {
    /*
    * Since, we have only one category at the moment (AEG) so we are fetching for index zero.
    * When there will be more categories in future then we will introduce a dropdown for it.
    */

    // Generating API url
    let url = `/timecodecategories?isActive=true&expand=timecodes`;

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_TIMECODE_INIT_TO_FETCH);

    // axios class which will execute API and execute a promise of success.
    axios
        .get(url)
        .then((res) => {
            dispatchSuccess(dispatch, constant.TIME_REPORTING_TIMECODE_FETCH_SUCCESS, res.data[0].timeCodes.filter(x => x.active));
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_TIMECODE_EXCEPTION_WHILE_FETCH, error)
        })
};

export const getTimeReportEntries = (date) => dispatch => {
    // Generating API url
    let url = `/timereportdays/${getDateInFormat(date)}?expand=dayentries`;

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_CRUD_INIT);

    // axios class which will execute API and execute a promise of success.
    axios
        .get(url)
        .then((res) => {
            let filteredtimeReport = res.data;
            let timeReportDayEntries = filteredtimeReport.dayEntries;
            let timeReportDayPermissions = {
                isLocked: filteredtimeReport.isLocked,
                isSubmitted: filteredtimeReport.isSubmitted
            }
            dispatchPromise(dispatch, constant.TIME_REPORTING_FETCH_DAY_PERMISSION, timeReportDayPermissions);
            dispatchPromise(dispatch, constant.TIME_REPORTING_CRUD, timeReportDayEntries);
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_EXCEPTION_WHILE_CRUD, error)
        })
}

export const insertTimeReportEntry = (timeReportEntries, timeReportEntry) => dispatch => {
    // Generating API url
    let url = "/timereportentries";

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_CRUD_INIT);

    // axios class which will execute API and execute a promise of success.
    axios
        .post(url, timeReportEntry)
        .then((res) => {
            dispatchPromise(dispatch, constant.TIME_REPORTING_CRUD, [res.data, ...timeReportEntries]);
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_EXCEPTION_WHILE_CRUD, error)
        })
};

export const deleteTimeReportEntry = (timeReportEntries, id) => dispatch => {
    // Generating API url
    let url = `/timereportentries/${id}`;

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_CRUD_INIT);

    // axios class which will execute API and execute a promise of success.
    axios
        .delete(url)
        .then((res) => {
            dispatchPromise(dispatch, constant.TIME_REPORTING_CRUD, timeReportEntries.filter(x => x.id !== id));
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_EXCEPTION_WHILE_CRUD, error)
        })
};

export const getTimeReportPayPeriods = (date) => dispatch => {
    // Generating API url
    let url = `timereportpayperiods?date=${getDateInFormat(date)}&expand=weeks.totals,weeks.days.dayentries`;

    // Initiating action which will display the loader image on UI
    dispatchInit(dispatch, constant.TIME_REPORTING_PAY_PERIODS_INIT_TO_FTECH);

    // axios class which will execute API and execute a promise of success.
    axios
        .get(url)
        .then((res) => {
            dispatchPromise(dispatch, constant.TIME_REPORTING_PAY_PERIODS_FETCH, res.data);
        })
        .catch(error => {
            dispatchError(dispatch, constant.TIME_REPORTING_PAY_PERIODS_EXCEPTION_WHILE_FETCH, error)
        })
};





