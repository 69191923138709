import React from "react";
import InputBox from "../../../components/Form/InputBox";
import { withTranslation } from "react-i18next";
class NotesForm extends React.Component {
  render() {
    let { data, t } = this.props;
    if (!data) return null;
    return (
      <form className="center-third" onSubmit={this.handleSubmit}>
        <InputBox
          heading={t("label.Notes")}
          placeholder={t("label.Notes")}
          name="inNotes"
          textarea={true}
          rows={8}
          value={data.inNotes}
          update={this.props.update}
        />
        {/* <div className="form-group">
            <label>Additional information</label>
            <p>info</p>
          </div> */}
      </form>
    );
  }
}

export default withTranslation()(NotesForm);
