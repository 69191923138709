import * as constant from "./constants";
import { getDateInFormat } from "../../utils";
const initialState = {
    activeDate: "",
    activeCustomerProjectId: "",
    activeCodeListTypeId: "",
    isPriceCodeListEmpty: false,
    isLoading: false,
    isLocked: false,
    isEntriesLoading: false,
    isEntryInserting: false,
    isEntryDeleting: false,
    error: false,
    errorMsg: null,
    projectAreas: [],
    projectAreaSections: [],
    codeGroups: [],
    codeGroupCodes: [],
    quantities: [],
    deletingQuantityId: 0,
    periodTotal: {},
};

const quantityReporting = (state = initialState, action) => {
    switch (action.type) {
        case constant.QUANTITY_REPORTING_ACTIVE_DATA:
            return {
                ...state,
                activeDate: action.payload.date,
                activeCustomerProjectId: action.payload.customerProjectId,
                activeCodeListTypeId: action.payload.codeListTypeId,
            }
        case constant.QUANTITY_REPORTING_DATA_INIT_TO_FETCH:
            return {
                ...state,
                isLoading: true,
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                errorMsg: null,
                projectAreas: action.payload.projectAreas,
                codeGroups: action.payload.codeGroups,
                quantities: action.payload.quantities
            }
        case constant.QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMsg: action.payload
            }
        case constant.QUANTITY_REPORTING_PROJECT_AREAS_SECTIONS:
            return {
                ...state,
                projectAreaSections: action.payload
            }
        case constant.QUANTITY_REPORTING_CODE_GROUPS_CODES:
            return {
                ...state,
                codeGroupCodes: action.payload
            }
        case constant.QUANTITY_REPORTING_ENTRIES_INIT_TO_FETCH:
            return {
                ...state,
                isEntriesLoading: true,
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_ENTRIES_FETCH_SUCCESS:
            return {
                ...state,
                isEntriesLoading: false,
                isEntryDeleting: false,
                error: false,
                errorMsg: null,
                quantities: action.payload
            }
        case constant.QUANTITY_REPORTING_ENTRIES_EXCEPTION_WHILE_FETCH:
            return {
                ...state,
                isEntriesLoading: false,
                error: true,
                errorMsg: action.payload
            }
        case constant.QUANTITY_REPORTING_ENTRY_INIT_TO_INSERT:
            return {
                ...state,
                isEntryInserting: true,
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_ENTRY_INSERT_SUCCESS:
            let existingQuantity = action.payload.existingQuantity;
            let newQuantity = action.payload.newQuantity;

            // When quantity entry belongs to shown detail list of UI, then need to update Redux store. 
            if (getDateInFormat(state.activeDate) === getDateInFormat(existingQuantity.date) &&
                state.activeCustomerProjectId === existingQuantity.projectId &&
                state.activeCodeListTypeId === existingQuantity.codeListTypeId) {

                // When quantity entry already exists in Redux store, then update it else add it on top of the list.
                let isAlreadyExists = state.quantities.some(x => x.id === existingQuantity.id);
                return {
                    ...state,
                    isEntryInserting: false,
                    error: false,
                    errorMsg: null,
                    quantities: !isAlreadyExists ? [newQuantity, ...state.quantities] :
                        state.quantities.map(x => x = x.id === existingQuantity.id ? newQuantity : x)
                }
            }

            // When quantity entry doesn't belong to shown detail list for UI, then no need to update Redux store. 
            return {
                ...state
            }
        case constant.QUANTITY_REPORTING_OFFLINE_ENTRY_INSERT_SUCCESS:
            let quantity = action.payload;

            // When quantity entry belong to shown detail list for UI, then need to update Redux store. 
            if (getDateInFormat(state.activeDate) === getDateInFormat(quantity.date) &&
                state.activeCustomerProjectId === quantity.projectId &&
                state.activeCodeListTypeId === quantity.codeListTypeId) {

                // When quantity entry doesn't exist in Redux store add it on top of the list else keep it as it is.
                let isAlreadyExists = state.quantities.some(x => x.id === quantity.id);
                return {
                    ...state,
                    isEntryInserting: false,
                    error: false,
                    errorMsg: null,
                    quantities: !isAlreadyExists ? [quantity, ...state.quantities] : state.quantities
                }
            }

            // When quantity entry doesn't belong to shown detail list for UI, then no need to update Redux store. 
            return {
                ...state
            }
        case constant.QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_INSERT:
            return {
                ...state,
                isEntryInserting: false,
                error: true,
                errorMsg: action.payload
            }
        case constant.QUANTITY_REPORTING_ENTRY_INIT_TO_DELETE:
            return {
                ...state,
                deletingQuantityId: action.payload,
                isEntryDeleting: true,
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_ENTRY_DELETE_SUCCESS:
            return {
                ...state,
                isEntryDeleting: false,
                quantities: state.quantities.filter(x => x.id !== action.payload),
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_ENTRY_OFFLINE_DELETE_SUCCESS:
            return {
                ...state,
                isEntryDeleting: false,
                quantities: state.quantities.map(x => x = x.id !== action.payload.id ? x : action.payload),
                error: false,
                errorMsg: null
            }
        case constant.QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_DELETE:
            return {
                ...state,
                isEntryDeleting: false,
                error: true,
                errorMsg: action.payload
            }
        case constant.QUANTITY_REPORTING_DAY_PERMISSION:
            return {
                ...state,
                isLocked: action.payload
            }
        case constant.QUANTITY_REPORTING_WEEKLY_TOTAL_INIT_TO_FETCH:
            return {
                ...state,
                isLoading: true
            }
        case constant.QUANTITY_REPORTING_WEEKLY_TOTAL_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                periodTotal: action.payload
            }
        case constant.QUANTITY_REPORTING_WEEKLY_TOTAL_EXCEPTION_WHILE_FETCH:
            return {
                ...state,
                isLoading: false,
                periodTotal: {}
            }
        case constant.QUANTITY_REPORTING_CHECK_PRICE_CODE_LIST:
            return {
                ...state,
                isPriceCodeListEmpty: action.payload,
            }
        default:
            return state;
    }
}

export default quantityReporting