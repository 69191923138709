import React, { Fragment } from "react";
import { table, thead, tbody, tr, th, td } from "react-super-responsive-table";
import { getLocaleDate } from "../../utils"
let TimeSheetHistoryTab = ({ list, t }) => {

  // Event to toggle weekly total information
  const toggleWeeklyTotal = (i) => {
    let icon = document.getElementById(`icon${i}`);
    let moreInfo = document.getElementById(`moreInfo${i}`);
    let lessInfo = document.getElementById(`lessInfo${i}`);
    if (icon.className.indexOf("plus") !== -1) {
      icon.className = "fa fa-minus";
      lessInfo.style["display"] = "none";
      moreInfo.style["display"] = "";
    }
    else {
      icon.className = "fa fa-plus";
      lessInfo.style["display"] = "";
      moreInfo.style["display"] = "none";
    }
  }
  return list.map((week, i) => (
    <Fragment key={"week" + i}>
      {i !== 0 && <hr />}
      <table className="table table-theme-2">
        <thead>
          <tr>
            <th scope="col" colSpan="3" className="heading-1">
              {week.name}
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th scope="col" className="heading-2">
              {t("label.Project")}
            </th>
            <th scope="col" className="heading-2">
              {t("label.Code")}
            </th>
            <th scope="col" className="heading-2" style={{ textAlign: "right" }}>
              {t("label.Quantity")}
            </th>
          </tr>
        </thead>
        <tbody>
          {week.days.map((day, j) => (
            <Fragment key={`week${i}_day${j}`}>
              <tr>
                <td colSpan="3" className="row-theme-1">{`${day.name} (${getLocaleDate(day.date)})`}</td>
              </tr>
              {day.dayEntries.map((dayEntry, k) => (
                <tr key={`week${i}_day${j}_timeReportEntry${k}`}>
                  <td className="row-theme-2">{dayEntry.jobNumberName}</td>
                  <td className="row-theme-2">{dayEntry.timeCodeName}</td>
                  <td className="row-theme-2" align="right">
                    {(dayEntry.hoursRegular || dayEntry.hoursOverTime || dayEntry.quantity) +
                      " " +
                      dayEntry.unitCode}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
          <tr>
            <td className="row-empty" colSpan="3"></td>
          </tr>
          <tr>
            <td className="row-theme-3" valign="top" style={{ cursor: "pointer" }} onClick={toggleWeeklyTotal.bind(this, i)}><>{t("label.Weekly_total")}&nbsp;<i id={`icon${i}`} className="fa fa-plus" style={{ fontSize: 11 }}></i></></td>
            <td className="row-theme-3" colSpan="2" align="right">
              <div id={`lessInfo${i}`}>
                {week.totals.map((e, j) => (
                  (j < 2 &&
                    <Fragment key={`week${i}_total${j}`}>
                      &nbsp;&nbsp;&nbsp;{e.name}:&nbsp;{e.total}&nbsp;{e.unitCode}
                    </Fragment>
                  )
                ))}
              </div>

              <div id={`moreInfo${i}`} style={{ display: "none" }}>
                {week.totals.map((e, j) => (
                  <Fragment key={`week${i}_total${j}`}>
                    {e.name}:&nbsp;{e.total}&nbsp;{e.unitCode}
                    <br />
                  </Fragment>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  ));
};
export default TimeSheetHistoryTab;
