
/*
 * This file act as the common business logic layer on top of the DataBase communication layer.
 * For example, any method/logic while performing DB CRUD operations before going to DB.js.
 */



/* Function used to create object store
 * db: db object
 * objectStore: table name
 * keyPath: table primary key collection (Single="id",multiple=["key1","key2"...])
 * indexArray(optional): collection of indexes on db table(["key1","key2"...] || ["key1, key2","key3, key4"...])
 */
export const createObjectStore = (db, objectStore, keyPath = "id", indexArray = []) => {
  if (!db.objectStoreNames.contains(objectStore)) {
    let store = db.createObjectStore(objectStore, { keyPath: keyPath });
    if (indexArray && indexArray.length > 0) {
      indexArray.forEach((keyName) => {
        if (keyName.indexOf(",") !== -1) {
          store.createIndex(keyName, keyName.split(",").map(x => x.toString().trim()));
        }
        else {
          store.createIndex(keyName, keyName);
        }
      });
    }
  }
};
