import axios from "axios";
import { push } from "connected-react-router";
import { checkEmptyObject, checkEmptyArray } from "../../utils";
import * as constant from "./constants";

/* Performing module prerequisite check  
 * - If offline, then prompt message and return to home page
 * - If it does not require to validate API data, then asking the user to select the missing prerequisite.
 * - If API data are invalid, then prompt message else asking the user to select the missing prerequisite.
 */
export const prerequisiteCheck = (
    object, api, page, messageOnFail, messageOnOffline, online
) => dispatch => {
    if (dispatch(internetCheck(online, messageOnOffline))) {
        return;
    }
    if (!api.length) {
        if (checkEmptyObject(object)) {
            dispatch(push(page));
        }
        return;
    }
    if (online && checkEmptyObject(object)) {
        axios
            .get(api)
            .then((res) => {
                if (checkEmptyArray(res.data)) {
                    dispatch({
                        type: constant.MODULE_PREREQUISITES_RESULT,
                        payload: { isFail: true, message: messageOnFail }
                    });
                }
                else {
                    dispatch(push(page));
                }
            })
            .catch((err) => {
                dispatch({
                    type: constant.MODULE_PREREQUISITES_RESULT,
                    payload: { isFail: true, message: err }
                });
            })
    }
}

/* Performing module prerequisite check  
 * - If offline, then prompt message
 */
export const internetCheck = (
    online, messageOnOffline
) => dispatch => {
    if (!online) {
        dispatch({
            type: constant.MODULE_PREREQUISITES_RESULT,
            payload: { isFail: true, message: messageOnOffline }
        });
        return true;
    }
    return false;
}