import React from "react";
import { Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";

export default class HelpMenu extends React.Component {
    constructor(props){
        super(props);
        this.state={
            dropdownOpen:false
        }
    }

    toggle=()=> {
        this.setState(prevState=>({
            dropdownOpen:!prevState.dropdownOpen
        }));
    }

    render(){
        const { home, t } = this.props;
        let uniqueKey=1;
        return(
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret className={"btn-navbar text-left " + this.props.cssClass}>{t("text.Help")}</DropdownToggle>
                <DropdownMenu className="z-index-top">
                    {
                        home.helpAndSupportLinks.map(x=>
                            {
                                uniqueKey +=1;
                                return(<DropdownItem key={`helpLink${uniqueKey}`} onClick={()=>{window.open(`${x.link}`, "_blank")}} >{x.name}</DropdownItem>)
                            }
                        )
                    }
                </DropdownMenu>
            </Dropdown>
        )
    }
}