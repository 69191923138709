import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { selectProject, getJobNumbers, getTimeCodes, getTimeReportEntries, insertTimeReportEntry, deleteTimeReportEntry, getTimeReportPayPeriods } from "./actions";
import DetailTabContext from "./DetailTabContext";
import HistoryTabContext from "./HistoryTabContext";
import { withTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import Tabs from "../../components/Tabs";
import SiteForm from "./Forms/SiteForm";
import { getDateInFormat } from "../../utils";
import Date from "../../components/DateInput";
import { toast } from "react-toastify";
import { selectModule } from "../Home/actions";
import { regularTimeButton, overTimeButton, quantityButton } from "./constants";

class TimeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      selectedDate: "",
      selectedJobNumber: {},
      selectedTimeCode: {},
      regTime: "",
      overTime: "",
      quantity: "",
      notes: "",
      regularTimeButtonDisable: true,
      overTimeButtonDisable: true,
      selectedSalaryPeriod: ""
    };
  }
  componentDidMount() {
    let today = getDateInFormat();
    this.props.getJobNumbers();
    this.props.getTimeCodes();
    this.props.getTimeReportEntries(today);
    this.setState({ selectedDate: today, selectedSalaryPeriod: today });
  }
  handleOnToggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });
    if (tab === 2) {
      this.props.getTimeReportPayPeriods(this.state.selectedSalaryPeriod);
    }
  };
  handleOnDateSelect = (date) => {
    this.props.getTimeReportEntries(date);
    if (this.props.timeReportDayPermissions.isLocked) {
      toast.error(this.props.t("label.You_can_not_edit_or_delete_a_time_report_that_is_locked"));
    }
    if (this.props.timeReportDayPermissions.isSubmitted) {
      toast.error(this.props.t("label.You_can_not_edit_or_delete_a_submitted_time_report"));
    }
    this.setState({ selectedDate: date });
  };
  handleOnJobNumberSelect = (item) => {
    this.setState({ selectedJobNumber: item });
  };
  handleOnTimeCodeSelect = (item) => {
    this.setState({ selectedTimeCode: item });
  };
  handleOnUpdateInputs = (name, value) => {
    if (name === "txtRegTime") {
      this.setState({
        regTime: value,
        overTime: "",
        quantity: "",
        regularTimeButtonDisable: false,
        overTimeButtonDisable: true,
      });
    }
    if (name === "txtOverTime") {
      this.setState({
        overTime: value,
        regTime: "",
        quantity: "",
        regularTimeButtonDisable: true,
        overTimeButtonDisable: false,
      });
    }
    if (name === "txtQuantity") {
      this.setState({
        overTime: "",
        regTime: "",
        quantity: value,
      });
    }
    if (name === "txtNotes") {
      this.setState({ notes: value });
    }
  };
  handleOnInsert = (button) => {
    let { selectedDate, selectedJobNumber, selectedTimeCode, regTime, overTime, quantity, notes } = this.state;
    if (selectedJobNumber.id === undefined || selectedJobNumber.id === null) {
      toast.error(`${this.props.t("text.Invalid")} ${this.props.t("label.Job_number")}.`);
      return false;
    }
    if (selectedTimeCode.id === undefined || selectedTimeCode.id === null) {
      toast.error(`${this.props.t("text.Invalid")} ${this.props.t("label.Code")}.`);
      return false;
    }
    this.props.insertTimeReportEntry(this.props.timeReportEntries, {
      jobNumberId: selectedJobNumber.id,
      date: selectedDate,
      timeCodeId: selectedTimeCode.id,
      hoursRegular: button === regularTimeButton && selectedTimeCode.isTimeCode ? regTime : 0,
      hoursOverTime: button === overTimeButton && selectedTimeCode.isTimeCode ? overTime : 0,
      quantity: quantityButton && !selectedTimeCode.isTimeCode ? quantity : 0,
      notes: notes,
    });

    this.clearInputData();
  };
  handleOnDelete = (id) => {
    this.props.deleteTimeReportEntry(this.props.timeReportEntries, id);
    this.clearInputData();
  };
  clearInputData = () => {
    this.setState({
      regTime: "",
      overTime: "",
      notes: "",
      quantity: "",
      regularTimeButtonDisable: true,
      overTimeButtonDisable: true,
    });
  };
  handleOnSalaryPeriodSelect = (period) => {
    this.setState({ selectedSalaryPeriod: period });
    this.props.getTimeReportPayPeriods(period);
  };

  render() {
    let { activeTab, selectedJobNumber, selectedTimeCode, regTime, overTime, quantity, notes, regularTimeButtonDisable, overTimeButtonDisable, selectedDate } = this.state;
    let { tabs, isLoadingJobNumbers, jobNumbers, isLoadingTimeCodes, timeCodes, isLoading, timeReportEntries, isLoadingPayPeriods, timeReportPayPeriods, timeReportDayPermissions, t } = this.props;
    let maxDate = getDateInFormat();
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5 m-even-padding top-margin-1rem">
            <div className="form-box bg-box">
              <Tabs tabs={tabs} activeTab={activeTab} toggle={this.handleOnToggle} />
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <SiteForm
                    dateId="txtDate"
                    maxDate={maxDate}
                    OnDateSelect={(item) => this.handleOnDateSelect(item)}
                    isLoadingJobNumbers={isLoadingJobNumbers}
                    jobNumbers={jobNumbers}
                    selectedJobNumber={selectedJobNumber}
                    OnJobNumberSelect={(item) => this.handleOnJobNumberSelect(item)}
                    isLoadingTimeCodes={isLoadingTimeCodes}
                    timeCodes={timeCodes}
                    selectedTimeCode={selectedTimeCode}
                    OnTimeCodeSelect={(item) => this.handleOnTimeCodeSelect(item)}
                    regTime={regTime}
                    overTime={overTime}
                    quantity={quantity}
                    notes={notes}
                    OnUpdate={(name, value) => this.handleOnUpdateInputs(name, value)}
                    OnInsert={(button) => this.handleOnInsert(button)}
                    disableRegularTimeButton={regularTimeButtonDisable}
                    disableOverTimeButton={overTimeButtonDisable}
                    regularTimeButton={regularTimeButton}
                    overTimeButton={overTimeButton}
                    quantityButton={quantityButton}
                    disabled={timeReportDayPermissions.isLocked || timeReportDayPermissions.isSubmitted}
                    permissions={timeReportDayPermissions}
                  />
                  <hr />
                  {
                    isLoading ?
                      <div className="loader"></div> :
                      <DetailTabContext
                        timeReportEntries={timeReportEntries}
                        selectedDate={selectedDate}
                        OnDelete={(id) => this.handleOnDelete(id)}
                        t={t}
                      ></DetailTabContext>
                  }
                </TabPane>
                <TabPane tabId={2}>
                  <Date
                    id="txtSalaryPeriod"
                    label={t("label.Date")}
                    onDateSelect={(period) => this.handleOnSalaryPeriodSelect(period)}
                    maxDate={maxDate}
                  ></Date>
                  {
                    isLoadingPayPeriods ?
                      <div className="loader"></div> :
                      timeReportPayPeriods.length > 0 ?
                        <HistoryTabContext
                          list={timeReportPayPeriods.weeks}
                          loading={isLoadingPayPeriods}
                          t={t}></HistoryTabContext> :
                        <div className="text-center">{t("label.No_reports")}</div>
                  }
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tabs: [
    {
      name: "control.Time_report",
      id: 1,
    },
    {
      name: "control.History",
      id: 2,
    },
  ],
  isLoadingJobNumbers: state.timeReport.isLoadingJobNumbers,
  jobNumbers: state.timeReport.jobNumbers,
  isLoadingTimeCodes: state.timeReport.isLoadingTimeCodes,
  timeCodes: state.timeReport.timeCodes,
  isLoading: state.timeReport.isLoading,
  timeReportEntries: state.timeReport.timeReportEntries,
  timeReportDayPermissions: state.timeReport.timeReportDayPermissions,
  isLoadingPayPeriods: state.timeReport.isLoadingPayPeriods,
  timeReportPayPeriods: state.timeReport.timeReportPayPeriods,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (path) => dispatch(push(path)),
  selectProject: () => dispatch(selectProject()),
  selectModule: (module) => dispatch(selectModule(module)),
  getJobNumbers: () => dispatch(getJobNumbers()),
  getTimeCodes: () => dispatch(getTimeCodes()),
  getTimeReportEntries: (date) => dispatch(getTimeReportEntries(date)),
  insertTimeReportEntry: (timeReportEntries, timeReportEntry) => dispatch(insertTimeReportEntry(timeReportEntries, timeReportEntry)),
  deleteTimeReportEntry: (timeReportEntries, id) => dispatch(deleteTimeReportEntry(timeReportEntries, id)),
  getTimeReportPayPeriods: (date) => dispatch(getTimeReportPayPeriods(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeReport));
