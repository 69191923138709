import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import { checkEmptyObject } from "../../../utils";
import { ErrorBox } from "../../../components/ErrorBox";
import ConfirmBox from "../../../components/ConfirmationBox";
import { fetchTeams, updateSelectedTeam } from "./actions";
import { MODULE_PREREQUISITES_COMPLETED } from "../../Home/constants";

class TeamSelectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowTeamChangeConfirmBox: false,
            confirmBoxPram: {}
        };
    }
    componentDidMount() {
        this.props.fetchTeams(this.props.user);
    }
    handleOnConfirmTeamChange = () => {
        this.setState({ isShowTeamChangeConfirmBox: false });
        this.props.updateSelectedTeam(this.props.user, this.state.confirmBoxPram);
    }
    handleOnCancelTeamChange = () => {
        this.setState({
            isShowTeamChangeConfirmBox: false
        });
    }
    handleOnTeamSelect = (team) => {
        let { online, local, t } = this.props;
        let { teams, selectedTeam, user } = local;
        // Users can not update the team, when the network is offline.
        if (teams.length > 1 && !online) {
            // Documentation: https://fkhadra.github.io/react-toastify/introduction/
            toast.error(`${t("text.You_need_to_be_online_to_perform_this_operation")}`,
                {
                    autoClose: 5000,
                    pauseOnHover: true
                });
        }
        else {
            // Confirming the user before changing their team.
            if (!checkEmptyObject(selectedTeam)) {
                if (team.id !== selectedTeam.id) {
                    this.setState({
                        isShowTeamChangeConfirmBox: true,
                        confirmBoxPram: team
                    });
                }
            }
            // If the user associates with the default team then, update the selected team without confirming.
            else {
                this.props.updateSelectedTeam(user, team);
            }
        }
    };
    btnContinueOnClick = () => {
        /*
         * If user route to team selection page from a module,
         * then route user back to selected module, else route user to the home page.
         */
        let { selectedModule, prerequisiteState } = this.props.home;
        if (!checkEmptyObject(selectedModule) && prerequisiteState === MODULE_PREREQUISITES_COMPLETED) {
            this.props.redirect(`/${selectedModule}`);
        }
        else {
            this.props.redirect("/home");
        }
    };
    render() {
        let { local, t } = this.props;
        let { isLoading, isUpdating, teams, selectedTeam, error } = local;
        let { isShowTeamChangeConfirmBox, confirmBoxPram } = this.state;
        let disabled = checkEmptyObject(selectedTeam);
        return (
            <>
                <div className="container h-100">
                    <div className="row justify-content-center mt-5">
                        <div className="col col-lg-8">
                            <div className="form-box padding-box bg-box">
                                <h5 className="mb-3">{t("text.Select_your_team")}</h5>
                                {
                                    isLoading || isUpdating ? <Loader /> :
                                        error ? <ErrorBox /> :
                                            <Dropdown
                                                selected={!checkEmptyObject(selectedTeam) ? selectedTeam.name : t("label.Select")}
                                                onChange={this.handleOnTeamSelect}
                                                list={teams}
                                                name={t("label.Team")}
                                                selectText={`${t("label.Select")} ${t("label.Team")}`}
                                            />
                                }
                                <div className="mt-4">
                                    <button disabled={disabled} className="btn btn-primary btn-one" onClick={this.btnContinueOnClick}>
                                        {t("control.Continue")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    isShowTeamChangeConfirmBox &&
                    <ConfirmBox
                        confirmButtonText={t("control.Yes_change_team")}
                        cancelButtonText={t("text.Cancel")}
                        heading={t("text.Confirm_your_change_of_team_header")}
                        text={`${t("text.Confirm_your_change_of_team_body")} ${confirmBoxPram.name}`}
                        onConfirm={this.handleOnConfirmTeamChange}
                        onCancel={this.handleOnCancelTeamChange}
                        t={t}></ConfirmBox>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    online: state.app.online,
    user: state.auth.user,
    home: state.modules,
    local: state.selectTeam
});

const mapDispatchToProps = dispatch => ({
    redirect: path => dispatch(push(path)),
    fetchTeams: (user) => dispatch(fetchTeams(user)),
    updateSelectedTeam: (user, selectedTeam) => dispatch(updateSelectedTeam(user, selectedTeam))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TeamSelectionPage));
