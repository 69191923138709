import React from "react";
import Button from "./Button";
import Checkbox from "./Checkbox";

let ConfirmationBox = ({ onConfirm, onCancel, heading, text, notes, confirmButtonText, cancelButtonText, checkboxes, buttomTheme = 0, isConfirmButtonVisible = true }) => {
    return (
        <div className="confirmation-box">
            <div className="main container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h5>{heading}</h5>
                        <hr />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <h6>{text}</h6>
                        {notes && <p className="mt-2 small">notes</p>}
                    </div>
                    {
                        checkboxes &&
                        (
                            <div className="col-lg-12">
                                <div className="checkbox-box">
                                    {
                                        checkboxes.map((checkbox, index) => {
                                            return (
                                                <Checkbox
                                                    key={checkbox.id}
                                                    text={checkbox.text}
                                                    name={checkbox.name}
                                                    onChange={checkbox.event}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        /* 
                        * Buttoms CSS and alignment themes
                        * default: Generic design (confirm: primary-btn, cancel: anchor link)
                        * 1: used for log out pop-up (confirm: primary-btn, cancel: btn-outline) with full width.
                        */
                        buttomTheme === 1 ?
                            <>
                                {
                                    isConfirmButtonVisible &&
                                    <div className="col-lg-12 mt-3">
                                        <Button
                                            type="button"
                                            className="btn btn-primary btn-one btn-100"
                                            onClick={onConfirm}
                                        >{confirmButtonText}</Button>
                                    </div>
                                }
                                <div className="col-lg-12 mt-3">
                                    <Button
                                        type="button"
                                        className="btn btn-primary btn-outline btn-100"
                                        onClick={onCancel}
                                    >{cancelButtonText}</Button>
                                </div>
                            </> :
                            // default buttoms theme
                            <div className="col-lg-12 mt-3">
                                {
                                    isConfirmButtonVisible &&
                                    <Button
                                        type="button"
                                        className="btn btn-primary btn-one"
                                        onClick={onConfirm}
                                    >{confirmButtonText}</Button>
                                }
                                <span onClick={onCancel}>{cancelButtonText}</span>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ConfirmationBox;
