import * as constant from "./constants";

const initialState = {
  isLoading: false,
  isUpdating: false,
  error: false,
  errorMsg: null,
  teams: [],
  selectedTeam: JSON.parse(localStorage.getItem("selectedTeam")) || {}
};

const team = (state = initialState, action) => {
  switch (action.type) {
    case constant.TEAM_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.TEAM_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMsg: null,
        teams: action.payload
      };
    case constant.TEAM_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMsg: action.payload,
        teams: []
      };
    case constant.TEAM_UPDATE_INIT:
      return {
        ...state,
        isUpdating: true
      };
    case constant.TEAM_UPDATE_SUCCESS:
      let selectedTeam = state.teams.filter(x => x.id === action.payload)[0];
      localStorage.setItem("selectedTeam", JSON.stringify(selectedTeam));
      return {
        ...state,
        isUpdating: false,
        error: false,
        errorMsg: null,
        selectedTeam: selectedTeam
      };
    case constant.TEAM_EXCEPTION_WHILE_UPDATE:
      return {
        ...state,
        isUpdating: false,
        error: true,
        errorMsg: action.payload
      };
    case constant.TEAM_SELECT:
      localStorage.setItem("selectedTeam", JSON.stringify(action.payload));
      return {
        ...state,
        selectedTeam: action.payload
      };
    case constant.LOGOUT_CLEAR_TEAM:
      localStorage.removeItem("selectedTeam");
      return {
        ...state,
        teams: [],
        selectedTeam: {}
      };
    default:
      return state;
  }
};

export default team;
