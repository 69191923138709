import React from "react";
import SubmissionBox from "../../../components/SubmissionBox";
import { PillTabs } from "../../../components/Tabs";
import { TabContent, TabPane } from "reactstrap";

class ImagesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      data: this.props.data
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data) {
        this.setState({
          data: this.props.data,
          activeTab: this.props.data[0].id
        });
      }
    }
  }

  handleChange = (name, data) => {
    console.log(data);
  };
  toggle = tab => {
    let { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });
  };

  render() {
    let { data } = this.state;
    if (!data) return null;
    let { activeTab } = this.state;
    return (
      <div>
        {/* Tabs for mainSectionName */}
        <PillTabs activeTab={activeTab} toggle={this.toggle} tabs={data} />
        {/* Switchable Content */}
        <TabContent activeTab={activeTab}>
          {data.map((main, mainIndex) => (
            <TabPane key={mainIndex} tabId={main.id}>
              {main.sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h3 className="font-box">{section.sectionName}</h3>
                  <div className="row row-reset">
                    {/* iterating imageGroups */}
                    {section.imageGroups.map((group, groupIndex) => {
                      return (
                        <div className="col-lg-6 m-padding-reset" key={groupIndex}>
                          <div className="image-group">
                            <h5>{group.name}</h5>
                            <p className="group-desc">{group.description}</p>
                            <SubmissionBox
                              mainIndex={mainIndex}
                              sectionIndex={sectionIndex}
                              groupIndex={groupIndex}
                              instPlan_id={this.props.instPlan_id}
                              imgGrp_id={group.id}
                              key={groupIndex}
                              images={group.images}
                              notes={group.notes}
                              noOfImages={group.noOfImages}
                              fetchImage={this.props.fetchImage}
                              updateImageGroupNote={this.props.updateImageGroupNote}
                              addImage={this.props.addImage}
                              updateImage={this.props.updateImage}
                              deleteImage={this.props.deleteImage}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </TabPane>
          ))}
        </TabContent>
      </div>
      //
    );
  }
}

export default ImagesForm;
