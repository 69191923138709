import React from "react";

const Checkbox = ({ name, value, text, disabled = false, onChange }) => {
  return (
    <div className="form-group checkboxed">
      <input
        disabled={disabled}
        id={name}
        onChange={e => onChange(name, e.target.checked)}
        checked={value}
        type="checkbox"
        className="form-control"
      />
      <label htmlFor={name}>{text}</label>
    </div>
  );
};

export default Checkbox;
