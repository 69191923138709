import React, { Fragment } from "react";
import { table, thead, tbody, tr, th, td } from "react-super-responsive-table";
import { getLocaleDate } from "../../utils"
const QuantityReportingDetailGridTab = (props) => {
    let { isLoading, isEntryDeleting, quantityReportingEntries, selectedDate, onDelete, deletingQuantityId, isTriggerByDetector, isLocked, t } = props;
    if (quantityReportingEntries === undefined) return null;
    // When isLocked property is true for selected date then delete button will hide.
    let tdSpan = isLocked ? 2 : 3;
    return (
        isLoading || isTriggerByDetector ?
            <div className="loader"></div> :
            <table className="table table-theme-1 table-without-bottom-margin">
                <thead>
                    <tr>
                        <th scope="col" colSpan={tdSpan} className="heading-1">
                            {getLocaleDate(selectedDate)}
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" className="heading-2">
                            {t("label.Code")}
                        </th>
                        <th scope="col" className="heading-2 align-right">
                            {t("label.Quantity")}
                        </th>
                        {!isLocked && (
                            <th scope="col" className="heading-2">
                                &nbsp;
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {quantityReportingEntries.length > 0 ? (
                        quantityReportingEntries.map((entry) => (
                            <Fragment key={entry.id}>
                                {
                                    deletingQuantityId === entry.id && isEntryDeleting ?
                                        <tr>
                                            <td colSpan={tdSpan}>
                                                <div className="loader"></div>
                                            </td>
                                        </tr> :
                                        <>
                                            <tr className={entry.isOffline ? "row-highlight " : ""}>
                                                <td className="row-theme-1">
                                                    {entry.isOffline && <footer className="blockquote-footer">offline</footer>}
                                                    <span style={entry.isDeleted ? { textDecoration: "line-through" } : {}}>{entry.roqCodeName}</span></td>
                                                <td className="row-theme-1 align-right" style={entry.isDeleted ? { textDecoration: "line-through" } : {}}>
                                                    {`${entry.quantity} ${entry.roqCodeUnitCode}`}
                                                </td>
                                                {!isLocked && entry.isDeletableByUser && (
                                                    <td className="row-theme-1 align-right">
                                                        {
                                                            !entry.isDeleted &&
                                                            <button
                                                                className="btn btn-xs btn-danger"
                                                                onClick={(id) => onDelete(entry.id)}
                                                                style={{ padding: "2px 10px" }}><span className="fa fa-trash"></span>
                                                            </button>
                                                        }
                                                    </td>
                                                )}
                                            </tr>
                                            {entry.notes !== "" && (
                                                <tr className={entry.isOffline ? "row-highlight " : ""}>
                                                    <td className="row-theme-2" colSpan={tdSpan} style={entry.isDeleted ? { textDecoration: "line-through" } : {}}>
                                                        {`${t("control.Notes")}: ${entry.notes}`}
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                }
                            </Fragment>
                        ))
                    ) : (
                            <tr>
                                <td colSpan={tdSpan} className="text-center">
                                    {t("label.No_reports")}
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
    );
};

export default QuantityReportingDetailGridTab;
