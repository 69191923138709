import React from "react";
const InputBox = ({
  heading = "",
  placeholder = "",
  value,
  name,
  type = "text",
  disabled,
  update,
  textarea,
  rows = 4,
  lgInline,
  alignCenter,
  className
}) => {
  return (
    <div
      className={`${className} ${!heading ? "" : "form-group"} row row-reset ${
        alignCenter ? "align-items-center" : ""
      }`}
    >
      {heading && (
        <div className={`padding-reset ${lgInline ? "col-lg-1 col-12" : "col-lg-12"}`}>
          <label htmlFor={name} className={`${lgInline ? "no-margin" : ""}`}>
            {heading}
          </label>
        </div>
      )}
      <div className={`padding-reset ${lgInline ? "col-lg-11 col-12" : "col-lg-12"}`}>
        {!textarea && (
          <input
            className="form-control"
            name={name}
            disabled={disabled}
            placeholder={placeholder || name}
            type={type}
            value={value || ""}
            onChange={e => update(name, e.target.value)}
          />
        )}
        {textarea && (
          <textarea
            className="form-control"
            name={name}
            disabled={disabled}
            placeholder={placeholder || name}
            type={type}
            value={value || ""}
            rows={rows}
            onChange={e => update(name, e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default InputBox;

export const LabelHeading = ({ heading, text, lgInline }) => {
  if (!lgInline) {
    return (
      <div className="form-group label-heading">
        <label>{heading}</label>
        <p>{text}</p>
      </div>
    );
  } else
    return (
      <div className="form-group label-heading row row-reset align-items-center">
        <div className="col-lg-1 col-12 padding-reset">
          <label className={`${lgInline ? "no-margin" : ""}`}>{heading}</label>
        </div>
        <div className="col-lg-11 col-12 padding-reset">
          <p>{text}</p>
        </div>
      </div>
    );
};
