/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */
export const TIME_REPORTING_ROUTING_KEY = "timereporting";
export const TIME_REPORTING_SELECT_PROJECT = "TimeReporting/TIME_REPORTING_SELECT_PROJECT";

export const TIME_REPORTING_JOB_NUMBER_INIT_TO_FETCH = "TimeReporting/TIME_REPORTING_JOB_NUMBER_INIT_TO_FETCH";
export const TIME_REPORTING_JOB_NUMBER_FETCH_SUCCESS = "TimeReporting/TIME_REPORTING_JOB_NUMBER_FETCH_SUCCESS";
export const TIME_REPORTING_JOB_NUMBER_EXCEPTION_WHILE_FETCH = "TimeReporting/TIME_REPORTING_JOB_NUMBER_EXCEPTION_WHILE_FETCH";

export const TIME_REPORTING_TIMECODE_INIT_TO_FETCH = "TimeReporting/TIME_REPORTING_TIMECODE_INIT_TO_FETCH";
export const TIME_REPORTING_TIMECODE_FETCH_SUCCESS = "TimeReporting/TIME_REPORTING_TIMECODE_FETCH_SUCCESS";
export const TIME_REPORTING_TIMECODE_EXCEPTION_WHILE_FETCH = "TimeReporting/TIME_REPORTING_TIMECODE_EXCEPTION_WHILE_FETCH";

export const TIME_REPORTING_CRUD_INIT = "TimeReporting/TIME_REPORTING_FETCH_INIT";
// Single constant has been used for Fetch, Insert & Delete operation of TimeReportEntry.
export const TIME_REPORTING_CRUD = "TimeReporting/TIME_REPORTING_CRUD";
export const TIME_REPORTING_FETCH_DAY_PERMISSION = "TimeReporting/TIME_REPORTING_FETCH_DAY_PERMISSION";
export const TIME_REPORTING_EXCEPTION_WHILE_CRUD = "TimeReporting/TIME_REPORTING_EXCEPTION_WHILE_CRUD";

export const TIME_REPORTING_PAY_PERIODS_INIT_TO_FTECH = "TimeReporting/TIME_REPORTING_PAY_PERIODS_INIT_TO_FTECH";
export const TIME_REPORTING_PAY_PERIODS_FETCH = "TimeReporting/TIME_REPORTING_PAY_PERIODS_FETCH";
export const TIME_REPORTING_PAY_PERIODS_EXCEPTION_WHILE_FETCH = "TimeReporting/TIME_REPORTING_PAY_PERIODS_EXCEPTION_WHILE_FETCH";

export const regularTimeButton = 1;
export const overTimeButton = 2;
export const quantityButton = 3;



