const config = {
  ...window["runConfig"],
  axiosHeader: {
    headers: { "Access-Control-Allow-Origin": "*" }
  },
};

export default {
  ...config
};
