import React from "react";
import { connect } from "react-redux";
import OfflineSync from "../components/OfflineSync";
import { triggerOfflineRoqEntries } from "../containers/ReportingOfQuantities/actions";

function withDetector(WrappedComponent) {
  class DetectorWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        enable: false,
      };
      window.addEventListener("online", (e) => this.updateOnlineStatus("online"));
      window.addEventListener("offline", (e) => this.updateOnlineStatus("offline"));
    }
    updateOnlineStatus = (offline_change) => {
      // NOTE: Temp. disabled popup for offline sync back
      // if (offline_change === "online") {
      //   this.setState({
      //     enable: true,
      //   });
      // }
      let status = navigator.onLine;
      this.props.onlineStatus(status);
      if (navigator.onLine) {
        // Sending data back to the server when user comes online..
        this.props.triggerOfflineRoqEntries();
      }
    };
    componentWillUnmount() {
      window.removeEventListener("online", this.updateOnlineStatus);
      window.removeEventListener("offline", this.updateOnlineStatus);
    }
    toggle = () => {
      this.setState({ enable: !this.state.enable });
    };
    render() {
      return (
        <>
          {this.state.enable && (
            <OfflineSync
              // putOffline={this.putOffline}
              toggle={this.toggle}
              // data={this.state.data}
              enable={this.state.enable}
            />
          )}
          <WrappedComponent {...this.props}>{this.props.children}</WrappedComponent>
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    online: state.app.online,
  });
  const mapDispatchToProps = (dispatch) => ({
    onlineStatus: (status) => dispatch({ type: "ROOT/ONLINE_STATUS", payload: status }),
    // onlineStatus: () => dispatch({ type: "ROOT/ONLINE_STATUS", payload: navigator.onLine }),
    triggerOfflineRoqEntries: () => dispatch(triggerOfflineRoqEntries())
  });
  return connect(mapStateToProps, mapDispatchToProps)(DetectorWrapper);
}

export default withDetector;
