import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./containers/LoginPage/reducer";
import modules from "./containers/Home/reducer";
import project from "./containers/ModuleInitials/Project/reducer";
import team from "./containers/ModuleInitials/Team/reducer";
import codeListType from "./containers/ModuleInitials/CodeListType/reducer";
import instPlans from "./containers/InstPlansPage/reducer";
import details from "./containers/DetailsPage/reducer";
import timeReport from "./containers/TimeReporting/reducer";
import quantityReporting from "./containers/ReportingOfQuantities/reducer";

// app reducer
const app = (
  state = {
    online: typeof navigator !== "undefined" && typeof navigator.onLine === "boolean" ? navigator.onLine : true
  },
  action
) => {
  switch (action.type) {
    case "ROOT/ONLINE_STATUS":
      return {
        ...state,
        online: action.payload
      };
    default:
      return state;
  }
};

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app: app,
    auth: auth,
    modules: modules,
    selectProject: project,
    selectTeam: team,
    selectCodeListType: codeListType,
    instPlans: instPlans,
    details: details,
    timeReport: timeReport,
    quantityReporting: quantityReporting
  });
export default createRootReducer;
