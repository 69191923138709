import React from "react";
import { getDateInFormat } from "../utils";
const isAppleDevice = navigator.vendor.includes('Apple');
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);
const isDateSelectTriggerOnBlur = isAppleDevice && !isSafari;
export default class DateInput extends React.Component {
    constructor(props) {
        super(props);
        let { initialDate } = this.props
        this.state = {
            selectedDate: (initialDate !== undefined && initialDate !== null && initialDate !== "" ? getDateInFormat(initialDate) : getDateInFormat()),
            previousDate: ""
        };
    }

    trigger = (e) => {
        if (isDateSelectTriggerOnBlur) {
            this.props.onDateSelect(e.target.value);
        }
    };

    handleChange = (e) => {
        if (isDateSelectTriggerOnBlur) {
            this.setState({ selectedDate: e.target.value });
        }
        else {
            let newDate = e.target.value;
            let objectId = this.props.id;
            this.setState(prevState => ({ previousDate: prevState.selectedDate, selectedDate: newDate }),
                () => {
                    // if only month and year changed.(mean user using scroll arrow from calender popup)
                    let prevDate = this.state.previousDate.toString().split("-");
                    let currentDate = this.state.selectedDate.toString().split("-");
                    if (prevDate[2] !== currentDate[2]) {
                        this.props.onDateSelect(this.state.selectedDate);
                        document.getElementById(objectId).blur();
                    }
                }
            );
        }
    };

    handleEnterKeyPress = (e) => {
        if (e.which === 13) {
            e.target.blur();
        }
        return false;
    }

    render() {

        let { selectedDate } = this.state;
        let { minDate, maxDate, label, disabled, lgInline, alignCenter, id } = this.props;
        return (
            <div className={`form-group row row-reset ${alignCenter ? "align-items-center" : ""}`}>
                <div className={`padding-reset ${lgInline ? "col-lg-1 col-12" : "col-lg-12"}`}>
                    <label className={`${lgInline ? "no-margin" : ""}`} htmlFor={id}>
                        {label}
                    </label>
                </div>
                <div className={`padding-reset ${lgInline ? "col-lg-11 col-12" : "col-lg-12"}`}>
                    <input
                        id={id}
                        name={id}
                        type="date"
                        value={selectedDate}
                        disabled={disabled}
                        onBlur={e => this.trigger(e)}
                        onChange={e => this.handleChange(e)}
                        onKeyPress={this.handleEnterKeyPress}
                        className="selected date-input"
                        min={minDate}
                        max={maxDate}></input>
                </div>
            </div>
        );
    }
}
