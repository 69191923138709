/*
 * Enumes
 */


export const electronicRegistration = {
    Staff: 1,
    StaffWithoutSKV: 2,
    Team: 4,
}
