import * as constant from "./constants";

const initialState = {
  isLoading: false,
  isUpdating: false,
  error: false,
  errorMsg: null,
  codeListTypes: [],
  selectedCodeListType: JSON.parse(localStorage.getItem("selectedCodeListType")) || {}
};

const codeListType = (state = initialState, action) => {
  switch (action.type) {
    case constant.CODE_LIST_TYPE_FETCH_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.CODE_LIST_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMsg: null,
        codeListTypes: action.payload
      };
    case constant.CODE_LIST_TYPE_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMsg: action.payload,
        codeListTypes: []
      };
    case constant.CODE_LIST_TYPE_UPDATE_INIT:
      return {
        ...state,
        isUpdating: true
      };
    case constant.CODE_LIST_TYPE_UPDATE_SUCCESS:
      let selectedCodeListType = state.codeListTypes.filter(x => x.codeListTypeId === action.payload)[0];
      if (selectedCodeListType) {
        localStorage.setItem("selectedCodeListType", JSON.stringify(selectedCodeListType));
      }
      return {
        ...state,
        isUpdating: false,
        error: false,
        errorMsg: null,
        selectedCodeListType: selectedCodeListType
      };
    case constant.CODE_LIST_TYPE_EXCEPTION_WHILE_UPDATE:
      return {
        ...state,
        isUpdating: false,
        error: true,
        errorMsg: action.payload
      };
    case constant.CODE_LIST_TYPE_SELECT:
      localStorage.setItem("selectedCodeListType", JSON.stringify(action.payload));
      return {
        ...state,
        selectedCodeListType: action.payload
      };
    case constant.LOGOUT_CLEAR_CODE_LIST:
      localStorage.removeItem("selectedCodeListType");
      return {
        ...state,
        codeListTypes: [],
        selectedCodeListType: {}
      }
    default:
      return state;
  }
};

export default codeListType;
