import { openObjectStore, getItemById, putInStore, ifOffline } from "../../db";

export const OBJECTSTORE_IMAGES = "offline_images";


export const saveImage = (instPlan_id, imgGrp_id, img_id, imgData, server) => {
  let id = `${instPlan_id}.${imgGrp_id}.${img_id}`;
  let obj = {
    id,
    instPlan_id,
    imgGrp_id,
    img_id,
    server: server ? true : false,
    data: imgData,
  };
  return new Promise((resolve, reject) => {
    ifOffline(instPlan_id, obj)
      .then((data) => putInStore(OBJECTSTORE_IMAGES, data))
      .then((k) => resolve(imgData))
      .catch(reject);
  });
};

export const getImage = (instPlan_id, imgGrp_id, img_id) => {
  let id = `${instPlan_id}.${imgGrp_id}.${img_id}`;
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_IMAGES, id)
      .then((obj) => resolve(obj.data))
      .catch(reject);
  });
};

export const deleteImageOffline = (instPlan_id, imgGrp_id, img_id) => {
  return new Promise((resolve, reject) => {
    openObjectStore(OBJECTSTORE_IMAGES, "readwrite")
      .then((objectStore) => {
        let id = `${instPlan_id}.${imgGrp_id}.${img_id}`;
        objectStore.delete(id);
        return resolve(true);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
