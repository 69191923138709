import eng from "./locales/en/translation.json";
import se from "./locales/se/translation.json";
import pt from "./locales/pt/translation.json";
import de from "./locales/de/translation.json";
import es from "./locales/es/translation.json";
import no from "./locales/no/translation.json";
import sl from "./locales/sl/translation.json";

import i18n from "i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const fallbackLng = ["en"];
// const availableLanguages = ["en", "sv", "pt"];

i18n
  // .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
  // .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    lng: localStorage.getItem("lng") || "en",
    // whitelist: availableLanguages,
    keySeparator: ".", // we do not use keys in form messages.welcome
    // keySeparator: true,
    resources: {
      en: {
        translation: eng
      },
      se: {
        translation: se
      },
      pt: {
        translation: pt
      },
      de: {
        translation: de
      },
      es: {
        translation: es
      },
      no: {
        translation: no
      },
      sl: {
        translation: sl
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
