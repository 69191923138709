import React from "react";
import ImageUpload from "./ImageUpload";
import InputBox from "./Form/InputBox";
import Button from "./Button";

export default class SubmissionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: this.props.notes,
      images: this.props.images,
      touchedImages: [],
      disabled: true,
      loading: false,
      saving: false,
      currentIndex: 0,
      translateValue: 0,
      slideWidth: 0
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.notes !== this.props.notes) {
      this.setState({
        notes: this.props.notes
      });
    }
    if (prevProps.images !== this.props.images) {
      this.setState({
        images: this.props.images
      });
    }
  }

  updateNotes = (data, value) => {
    this.setState({
      [data]: value,
      disabled: value === this.props.notes
    });
  };
  handleAdd = () => {
    let images = this.state.images;
    if (images.length < this.props.noOfImages) {
      images.push(null);
      this.setState({
        images
      });
    }
  };
  handledTouched = data => {
    let touchedImages = this.state.touchedImages;
    if (touchedImages.filter(x => x.index === data.index).length > 0) {
      touchedImages.find(x => x.index === data.index).img = data.img;
    } else {
      touchedImages.push(data);
    }
    this.setState({
      touchedImages,
      disabled: touchedImages.length === 0
    });
  };
  removeImageSlide = index => {
    let { touchedImages, images } = this.state;
    touchedImages = touchedImages.filter(o => o.index !== index);
    images.splice(index, 1);
    this.setState({ images, touchedImages });
  };
  deleteImage = (id, index) => {
    let { instPlan_id, imgGrp_id, deleteImage } = this.props;
    if (id) {
      deleteImage(instPlan_id, imgGrp_id, id).then(res => {
        this.removeImageSlide(index, id);
      });
    } else {
      this.removeImageSlide(index, id);
    }
    this.setState({
      disabled: true
    });
  };
  saveImgGrp = () => {
    let { instPlan_id, imgGrp_id, mainIndex, sectionIndex, groupIndex } = this.props;
    let { touchedImages } = this.state;
    this.setState({
      saving: true,
      disabled: true
    });
    if (touchedImages.length > 0) {
      Promise.all(
        touchedImages.map(o => {
          return o.id
            ? this.props.updateImage(instPlan_id, imgGrp_id, o.id, o.img, mainIndex, sectionIndex, groupIndex)
            : this.props.addImage(instPlan_id, imgGrp_id, o.img, mainIndex, sectionIndex, groupIndex);
        })
      ).then(resArray => {
        this.setState({
          saving: false,
          touchedImages: []
        });
      });
    }
    if (this.state.notes !== this.props.notes) {
      return this.props
        .updateImageGroupNote(instPlan_id, imgGrp_id, { notes: this.state.notes }, mainIndex, sectionIndex, groupIndex)
        .then(res => {
          this.setState({
            saving: false
          });
        });
    }
  };
  slideWidth = () => {
    // let k = document.querySelector('.slide').clientWidth
    // this.setState({
    //   slideWidth:k,
    // })

    // return k;
    return 300;
  };

  goToPrevSlide = () => {
    if (this.state.currentIndex === 0) return;

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }));
  };

  goToNextSlide = () => {
    // if (this.state.currentIndex === this.props.images.length) {
    //   return this.setState({
    //     currentIndex: 0,
    //     translateValue: 0
    //   });
    // }
    if (this.state.currentIndex === this.props.images.length) {
      return;
    }
    // This will not run if we met the if condition above
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue - this.slideWidth()
    }));
  };

  render() {
    let { noOfImages, fetchImage, instPlan_id, imgGrp_id } = this.props;
    let { images, notes, saving, disabled, currentIndex } = this.state;
    return (
      <div className="position-relative">
        <span>
          {images.length} out of {noOfImages}
        </span>
        <div className="slider">
          <div
            className="slider-wrapper"
            style={{
              transform: `translateX(${this.state.translateValue}px)`,
              transition: "transform ease-out 0.45s"
            }}
          >
            {images.map((e, index) => (
              <ImageUpload
                fetchImage={fetchImage}
                deleteImage={this.deleteImage}
                instPlan_id={instPlan_id}
                imgGrp_id={imgGrp_id}
                handledTouched={this.handledTouched}
                key={index}
                index={index}
                id={e}
                handleSaveImages={this.saveImgGrp}
              />
            ))}
            <PlaceHolderSlide handleAdd={this.handleAdd} images={this.props.images} noOfImages={noOfImages} />
          </div>
        </div>
        {!(noOfImages === 1) && <LeftArrow disabled={currentIndex === 0} goToPrevSlide={this.goToPrevSlide} />}
        {!(noOfImages === 1) && (
          <RightArrow
            disabled={(currentIndex + 1 === images.length && currentIndex + 1 === noOfImages) || images.length === 0}
            goToNextSlide={this.goToNextSlide}
          />
        )}
        <div className="mix-bag">
          <InputBox
            className="in"
            placeholder="NOTES"
            name="notes"
            textarea={true}
            rows="1"
            value={notes}
            update={this.updateNotes}
            noFormGroup
          />
          <Button
            onClick={this.saveImgGrp}
            className={`btn btn-primary btn-small ${disabled ? "btn-outline" : ""}`}
            disabled={disabled}
            loading={saving}
          >
            <i className="fa fa-save"></i>
          </Button>
        </div>
      </div>
    );
  }
}

const PlaceHolderSlide = ({ images, noOfImages, handleAdd }) => (
  <div className="img-upload-box slide">
    <div className="img-box">
      <div className="text-center">
        {images.length < noOfImages ? (
          <div className="text-center">
            <p>Add New Image Slide</p>
            <button onClick={handleAdd} className="btn btn-primary btn-small btn-outline">
              <span className="fa fa-plus"></span>
            </button>
          </div>
        ) : (
          <div>
            <p>End of Slide</p>
            <p>
              {images.length}/{noOfImages}
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
);

const LeftArrow = props => {
  return (
    <div className="backArrow arrow" onClick={props.goToPrevSlide}>
      <i className={`fa fa-arrow-left ${props.disabled ? "inactive" : ""}`} aria-hidden="true"></i>
    </div>
  );
};

const RightArrow = props => {
  return (
    <div className="nextArrow arrow" onClick={!props.disabled ? props.goToNextSlide : undefined}>
      <i className={`fa fa-arrow-right ${props.disabled ? "inactive" : ""}`} aria-hidden="true"></i>
    </div>
  );
};
