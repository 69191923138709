import { getItemById, putInStore, openDatabase, ifOffline } from "../../db";

export const OBJECTSTORE_DETAILS_IMG_META = "installation_images";

export const saveImagesMeta = (instPlan_id, data) => {
  return new Promise((resolve, reject) => {
    let obj = {
      id: instPlan_id,
      data: data,
    };
    ifOffline(instPlan_id, obj)
      .then((data) => putInStore(OBJECTSTORE_DETAILS_IMG_META, data))
      .then((k) => resolve(true))
      .catch(reject);
  });
};

export const getImagesMeta = (instPlan_id) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_DETAILS_IMG_META, instPlan_id)
      .then((obj) => resolve(obj.data))
      .catch(reject);
  });
};

export const updateImagesMetaOffline = (instPlan_id, mainIndex, sectionIndex, groupIndex, updateObj) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_DETAILS_IMG_META, instPlan_id)
      .then((obj) => {
        obj.data[mainIndex]["sections"][sectionIndex]["imageGroups"][groupIndex]["notes"] = updateObj.notes;
        openDatabase().then((db) => {
          if (!db) return reject(false);
          db.onsuccess = function (event) {
            let db = event.target.result;
            let tx = db.transaction(OBJECTSTORE_DETAILS_IMG_META, "readwrite");
            let objectStore = tx.objectStore(OBJECTSTORE_DETAILS_IMG_META);
            objectStore.put(obj);
            tx.oncomplete = (e) => resolve(updateObj);
            tx.onerror = (e) => reject();
          };
        });
      })
      .catch((err) => {
        return reject();
      });
  });
};

export const updateImagesMetaIdOffline = (instPlan_id, mainIndex, sectionIndex, groupIndex, img_id) => {
  return new Promise((resolve, reject) => {
    getItemById(OBJECTSTORE_DETAILS_IMG_META, instPlan_id)
      .then((obj) => {
        let imagesArray = obj.data[mainIndex]["sections"][sectionIndex]["imageGroups"][groupIndex].images;
        imagesArray.push(img_id);
        obj.data[mainIndex]["sections"][sectionIndex]["imageGroups"][groupIndex].images = imagesArray;
        openDatabase().then((db) => {
          if (!db) return reject(false);
          db.onsuccess = function (event) {
            let db = event.target.result;
            let tx = db.transaction(OBJECTSTORE_DETAILS_IMG_META, "readwrite");
            let objectStore = tx.objectStore(OBJECTSTORE_DETAILS_IMG_META);
            objectStore.put(obj);
            tx.oncomplete = (e) => resolve();
            tx.onerror = (e) => reject();
          };
        });
      })
      .catch((err) => {
        return reject();
      });
  });
};
