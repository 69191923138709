import React from "react";
import { withTranslation } from "react-i18next";

import {
  getDetails,
  getActivities,
  getImages,
  saveImages,
  saveImageData,
  getImageData,
  saveDetails,
  saveActivities,
  getImagesTotal,
} from "./actions";
import { takeOfflineInstPlan, getAllInstallationPlans } from "../../offline/os/installationPlan/enteries";
import Button from "../Button";
import Checkbox from "../Checkbox";

class OfflineSync extends React.Component {
  constructor(props) {
    super(props);
    // to fix memory leak for async
    this._isMounted = false;
    this.state = {
      imgDownload: null,
      loading: false,
      images: false,
      installationPlans: null,
      checkboxes:{

      }
    };
  }
  componentDidMount() {
    this._isMounted = true;
    getAllInstallationPlans().then((data) => {
      this.setState({
        installationPlans: data,
      });
    });

    // if (this.props && this.props.data && this.props.data.id) {
    //   let instPlan_id = this.props.data.id;
    //   this.setState({
    //     disabled: true,
    //     imgDownload: null
    //   });
    //   getImagesTotal(instPlan_id).then(imgDownload => {
    //     this.setState({
    //       imgDownload,
    //       disabled: false
    //     });
    //   });
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      let instPlan_id = this.props.data.id;
      this.setState({
        disabled: true,
        imgDownload: null,
        images: false,
      });
      this._isMounted &&
        getImagesTotal(instPlan_id).then((imgDownload) => {
          this.setState({
            imgDownload,
            disabled: false,
          });
        });
    }
  }
  componentWillUnmount() {
    this.setState({
      imgDownload: null,
      loading: false,
      images: false,
      installationPlans: null,
    });
  }

  handleOnChange = (target, value) => {
    let checkboxes={...this.state.checkboxes};
    checkboxes[target] = value
    this.setState({
      checkboxes
    });
  };
  fetchData = (data) => {
    this.setState({ loading: true });
    let imgDownload = this.state.imgDownload;
    takeOfflineInstPlan(data)
      // openObjectStore(OBJECTSTORE_INSTPLANS, "readwrite")
      //   .then(objectStore => {
      //     let selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
      //     data["project_id"] = selectedProject.id;
      //     data["offline"] = true;
      //     return objectStore.put(data);
      //   })
      .then((k) => {
        let { id } = data;
        Promise.all([
          getDetails(id).then((d) => saveDetails(d)),
          getActivities(id).then((d) => saveActivities(id, d)),
          getImages(id).then((d) => saveImages(id, d)),
        ]).then((all) => {
          if (this.state.images && imgDownload && imgDownload.length > 0) {
            console.log("downloading data");
            Promise.all(imgDownload.map(getImageData))
              .then((imgToSave) => Promise.all(imgToSave.map(saveImageData)))
              .then((k) => {
                this.setState({ loading: false });
                this.props.toggle();
                this.props.putOffline(data);
              });
          } else {
            this.setState({ loading: false });
            this.props.toggle();
            this.props.putOffline(data);
          }
        });
      });
  };

  text = () => {
    let { imgDownload } = this.state;
    let { t } = this.props;
    return `${t("text.Download_images")} (${imgDownload.length})`;
  };
  handleSubmit = ()=>{
    let installationPlans = this.state.checkboxes;
    console.log(installationPlans)
    // bulk sync back api here

    // triggger after done
    this.props.toggle();

  }

  render() {
    if (!this.props.enable) return null;
    let { t } = this.props;
    let { loading, installationPlans } = this.state;
    return (
      <div className="fixed-frame">
        <div className="main container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h5>Offline Sync Back</h5>
              <hr />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <h6>You've been working offline and you might've data that you want to serve back to server</h6>
              <p className="mt-2 small">
                By confirming, you'll be syncing and offloading your offline data to servers and delete the local copy
                to save space.
              </p>
            </div>

            <div className="col-lg-12">
              <hr />
              <p>Installtion Plans</p>
              <div className="checkbox-box">
                
                {installationPlans
                  ? installationPlans.map((o) => {
                      return (
                        <Checkbox
                        key={o.id}
                          text={o.subPropertyId}
                          name={o.id}
                          value={this.state.checkboxes[o.id]}
                          onChange={this.handleOnChange}
                        />
                      );
                    })
                  : null}
                {/* {installationPlans ? (
                  installationPlans.length > 0 ? (
                    installationPlans.map(o=>{
                      <Checkbox
                      text={this.text()}
                      name="images"
                      value={this.state.images}
                      onChange={this.handleOnChange}
                    />
                    })
                )} */}
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <Button
                type="submit"
                className="btn btn-primary btn-one"
                onClick={this.handleSubmit}
                loading={this.state.loading}
                disabled={this.state.disabled}
              >
                {t("label.Confirm")}
              </Button>
              {!loading && <span onClick={loading ? undefined : this.props.toggle}>{t("text.Cancel")}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(OfflineSync);
