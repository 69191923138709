import * as constant from "./constants";
// import axios from "axios";

const initialState = {
  isLoading: false,
  isLoggedIn: localStorage.getItem("token") ? true : false,
  error: null,
  isRoqOfflineDataExists: false,
  user: JSON.parse(localStorage.getItem("user")) || {}
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case constant.USER_LOGIN_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        token: action.payload.token,
        user: action.payload.user
      };
    case constant.USER_LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        error: action.payload,
        user: {}
      };
    case constant.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        token: null,
        user: {},
        isRoqOfflineDataExists: false
      };
    case constant.USER_UPDATE:
      return {
        ...state,
        user: action.payload
      };
    case constant.USER_ROQ_OFFLINE_DATA_EXISTS:
      return {
        ...state,
        isRoqOfflineDataExists: action.payload
      };
    default:
      return state;
  }
};

export default auth;
