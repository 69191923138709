import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { withTranslation } from "react-i18next";
class Tabs extends React.Component {
  render() {
    const { tabs, activeTab, toggle, t } = this.props;
    return (
      <Nav className="m-tabs">
        {tabs.map(e => {
          return (
            <NavItem key={e.id}>
              <NavLink
                className={activeTab === e.id ? "active" : null}
                onClick={() => {
                  toggle(e.id);
                }}
              >
                {e.icon ? <><span className={e.icon}></span> <p>{t(e.name)}</p></> : <p style={{ display: "block", textTransform: "capitalize" }}>{t(e.name)}</p>}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  }
}
export const PillTabs = ({ tabs, activeTab, toggle }) => {
  return (
    <Nav className="pill-tabs">
      {tabs.map(e => {
        return (
          <NavItem key={e.id} className={activeTab === e.id ? "active" : null}>
            <NavLink
              onClick={() => {
                toggle(e.id);
              }}
            >
              {e.mainSectionName}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default withTranslation()(Tabs);
