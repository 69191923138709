import axios from "axios";
import * as constant from "./constants";
import { dispatchPromise, dispatchInit, dispatchError, dispatchSuccess } from "../../../utils";
import { saveDataInStore, getDataFromStore } from "../../../offline/db";
import { OBJECTSTORE_TEAMS } from "../../../offline/os/common/teams";
import { USER_UPDATE } from "../../LoginPage/constants";

export const fetchTeams = (user) => dispatch => {
  let teams = [];
  // Initiating action which will display the loader image on UI
  dispatchInit(dispatch, constant.TEAM_FETCH_INIT);
  // Generating API urls to get the Teams
  let teamsApiUrl = `/teams?companyId=${user.companyId}&isActive=true`;
  // axios class which will execute API and execute a promise on success.
  axios
    .get(teamsApiUrl)
    .then((res) => {
      teams = res.data;
      // Save the Teams to the offline store.
      saveDataInStore(OBJECTSTORE_TEAMS, teams);
      // Provide Teams to component's Redux store to map it on the UI
      dispatchPromise(dispatch, constant.TEAM_FETCH_SUCCESS, teams)
        // The user should be associated with the a team, 
        .then(() => {
          if (teams.some(team => team.id === user.teamId)) {
            dispatch({
              type: constant.TEAM_SELECT,
              payload: teams.filter(team => team.id === user.teamId)[0]
            });
          }
        });
    })
    .catch(error => {
      // API fails to succeed request, so fetch Teams from offline store and update the component's Redux store to map it on the UI.
      getDataFromStore(OBJECTSTORE_TEAMS)
        .then(teams => {
          // List to bind drop-down
          dispatchPromise(dispatch, constant.TEAM_FETCH_SUCCESS, teams)
            // User's selected Team 
            .then(dispatchSuccess(dispatch, constant.TEAM_SELECT, teams.filter(x => x.id === user.teamId)[0]));
        })
        .catch(err => dispatchError(dispatch, constant.TEAM_EXCEPTION_WHILE_FETCH, error));
    })
};

export const updateSelectedTeam = (user, selectedTeam) => dispatch => {
  // Initiate API call
  dispatchInit(dispatch, constant.TEAM_UPDATE_INIT);
  // Prepare API Url to update user's selected Team
  let url = `/users/${user.id}`;
  axios
    .patch(url, { teamId: selectedTeam.id })
    .then(() => dispatch(updateUser(user)))
    .catch(error => dispatchError(dispatch, constant.TEAM_EXCEPTION_WHILE_UPDATE, error));
};

export const updateUser = (user) => dispatch => {
  // Prepare API Url to update user's info
  let url = `/users/${user.id}?expand=CompanyId,CompanyName,TeamId,TeamName,Roles,CodeListTypeId,Modules,LanguageCode`;
  axios
    .get(url)
    .then((res) => {
      // Update the logged-in user's information
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatchPromise(dispatch, USER_UPDATE, res.data)
        // Update user's selected Team
        .then(() => dispatchSuccess(dispatch, constant.TEAM_UPDATE_SUCCESS, res.data.teamId));
    })
    .catch(error => dispatchError(dispatch, constant.TEAM_EXCEPTION_WHILE_UPDATE, error));
};


