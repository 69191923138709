/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const ROQ_ROUTING_KEY = "reportingofquantities";
export const QUANTITY_REPORTING_SELECT_PROJECT = "ReportingOfQuantities/QUANTITY_REPORTING_SELECT_PROJECT";

// To fetch all data for the Quantity Reporting's component on load 
export const QUANTITY_REPORTING_ACTIVE_DATA = "ReportingOfQuantities/QUANTITY_REPORTING_ACTIVE_DATA";
export const QUANTITY_REPORTING_DATA_INIT_TO_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_DATA_INIT_TO_FETCH";
export const QUANTITY_REPORTING_DATA_FETCH_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_DATA_FETCH_SUCCESS";
export const QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_DATA_EXCEPTION_WHILE_FETCH";

export const QUANTITY_REPORTING_PROJECT_AREAS_SECTIONS = "ReportingOfQuantities/QUANTITY_REPORTING_PROJECT_AREAS_SECTIONS";
export const QUANTITY_REPORTING_CODE_GROUPS_CODES = "ReportingOfQuantities/QUANTITY_REPORTING_CODE_GROUPS_CODES";

// Fetch the entries of the Quantity Reporting
export const QUANTITY_REPORTING_ENTRIES_INIT_TO_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRIES_INIT_TO_FETCH";
export const QUANTITY_REPORTING_ENTRIES_FETCH_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRIES_FETCH_SUCCESS";
export const QUANTITY_REPORTING_ENTRIES_EXCEPTION_WHILE_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRIES_EXCEPTION_WHILE_FETCH";

// Insert an entry of the Quantity Reporting
export const QUANTITY_REPORTING_ENTRY_INIT_TO_INSERT = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_INIT_TO_INSERT";
export const QUANTITY_REPORTING_ENTRY_INSERT_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_INSERT_SUCCESS";
export const QUANTITY_REPORTING_OFFLINE_ENTRY_INSERT_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_OFFLINE_ENTRY_INSERT_SUCCESS";
export const QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_INSERT = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_INSERT";

// Delete an entry of the Quantity Reporting
export const QUANTITY_REPORTING_ENTRY_INIT_TO_DELETE = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_INIT_TO_DELETE";
export const QUANTITY_REPORTING_ENTRY_DELETE_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_DELETE_SUCCESS";
export const QUANTITY_REPORTING_ENTRY_OFFLINE_DELETE_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_OFFLINE_DELETE_SUCCESS";
export const QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_DELETE = "ReportingOfQuantities/QUANTITY_REPORTING_ENTRY_EXCEPTION_WHILE_DELETE";

// Fetch Weekly Total
export const QUANTITY_REPORTING_WEEKLY_TOTAL_INIT_TO_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_WEEKLY_TOTAL_INIT_TO_FETCH";
export const QUANTITY_REPORTING_WEEKLY_TOTAL_FETCH_SUCCESS = "ReportingOfQuantities/QUANTITY_REPORTING_WEEKLY_TOTAL_FETCH_SUCCESS";
export const QUANTITY_REPORTING_WEEKLY_TOTAL_EXCEPTION_WHILE_FETCH = "ReportingOfQuantities/QUANTITY_REPORTING_WEEKLY_TOTAL_EXCEPTION_WHILE_FETCH";
export const ROQ_WEEKLY_TOTAL_ADD = "ADD";
export const ROQ_WEEKLY_TOTAL_DELETE = "DELETE";

// Day permission
export const QUANTITY_REPORTING_DAY_PERMISSION = "ReportingOfQuantities/QUANTITY_REPORTING_DAY_PERMISSION";

// Checking condition on the module load
export const QUANTITY_REPORTING_CHECK_PRICE_CODE_LIST = "ReportingOfQuantities/QUANTITY_REPORTING_CHECK_PRICE_CODE_LIST";
