import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import { checkEmptyObject } from "../../../utils";
import { ErrorBox } from "../../../components/ErrorBox";
import { fetchCodeListTypes, updateSelectedCodeListType } from "./actions";
import { MODULE_PREREQUISITES_COMPLETED } from "../../Home/constants";

class CodeListTypeSelectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        let { selectedProject } = this.props;
        if (!checkEmptyObject(selectedProject)) {
            // We then fetch the RoQ price list by parentProjectId instead project id.
            this.props.fetchCodeListTypes(this.props.user, selectedProject.parentProjectId);
        }
    }
    handleOnCodeListTypeSelect = (codeListType) => {
        // User can not update code list type in offline mode.
        if (!this.props.online) {
            // Documentation: https://fkhadra.github.io/react-toastify/introduction/
            toast.error(`${this.props.t("text.You_need_to_be_online_to_perform_this_operation")}`,
                {
                    autoClose: 5000,
                    pauseOnHover: true
                });
        }
        else {
            this.props.updateSelectedCodeListType(this.props.user, codeListType);
        }
    };
    btnContinueOnClick = () => {
        /*
         * If user route to codelistType selection page from a module,
         * then route user back to selected module, else route user to the home page.
         */
        let { selectedModule, prerequisiteState } = this.props.home;
        if (!checkEmptyObject(selectedModule) && prerequisiteState === MODULE_PREREQUISITES_COMPLETED) {
            this.props.redirect(`/${selectedModule}`);
        }
        else {
            this.props.redirect("/home");
        }
    };
    render() {
        let { local, t } = this.props;
        let { isLoading, isUpdating, codeListTypes, selectedCodeListType, error } = local;
        let disabled = checkEmptyObject(selectedCodeListType);
        return (
            <div className="container h-100">
                <div className="row justify-content-center mt-5">
                    <div className="col col-lg-8">
                        <div className="form-box padding-box bg-box">
                            <h5 className="mb-3">{`${t("label.Select")} ${t("control.Code_list")}`}</h5>
                            {
                                isLoading || isUpdating ? <Loader /> :
                                    error ? <ErrorBox /> :
                                        <Dropdown
                                            selected={!checkEmptyObject(selectedCodeListType) ? selectedCodeListType.codeListTypeName : t("label.Select")}
                                            onChange={this.handleOnCodeListTypeSelect}
                                            list={codeListTypes}
                                            name={t("control.Code_list")}
                                            selectText={`${t("label.Select")} ${t("control.Code_list")}`}
                                        />
                            }
                            <div className="mt-4">
                                <button disabled={disabled} className="btn btn-primary btn-one" onClick={this.btnContinueOnClick}>
                                    {t("control.Continue")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    online: state.app.online,
    user: state.auth.user,
    home: state.modules,
    selectedProject: state.selectProject.selectedProject,
    local: state.selectCodeListType
});

const mapDispatchToProps = dispatch => ({
    redirect: path => dispatch(push(path)),
    fetchCodeListTypes: (user, parentProjectId) => dispatch(fetchCodeListTypes(user, parentProjectId)),
    updateSelectedCodeListType: (user, selectedCodeListType) => dispatch(updateSelectedCodeListType(user, selectedCodeListType))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CodeListTypeSelectionPage));
