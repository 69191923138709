import React from "react";
import InputBox from "../../../components/Form/InputBox";
import { withTranslation } from "react-i18next";

class SiteForm extends React.Component {
  render() {
    let { data, t } = this.props;
    if (!data) return null;
    return (
      <form className="center-third" onSubmit={this.handleSubmit}>
        <InputBox
          heading={t("label.Site_ID")}
          placeholder={t("label.Site_ID")}
          name="subPropertyId"
          value={data.subPropertyId}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Latitude")}
          placeholder={t("label.Latitude")}
          name="subCoordinatesLat"
          value={data.subCoordinatesLat}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Longitude")}
          placeholder={t("label.Longitude")}
          name="subCoordinatesLng"
          value={data.subCoordinatesLng}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Address")}
          placeholder={t("label.Address")}
          name="subAddress"
          value={data.subAddress}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Regional")}
          placeholder={t("label.Regional")}
          name="subPropertyArea"
          value={data.subPropertyArea}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.City")}
          placeholder={t("label.City")}
          name="subCity"
          value={data.subCity}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.State")}
          placeholder={t("label.State")}
          name="subState"
          value={data.subState}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Vendor")}
          placeholder={t("label.Vendor")}
          name="inVendor"
          value={data.inVendor}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Type_of_structure")}
          placeholder={t("label.Type_of_structure")}
          name="subStructure"
          value={data.subStructure}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Type_of_solution")}
          placeholder={t("label.Type_of_solution")}
          name="inSolution"
          value={data.inSolution}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Notes")}
          placeholder={t("label.Notes")}
          name="subExtraInfo"
          textarea={true}
          value={data.subExtraInfo}
          update={this.props.update}
        />
      </form>
    );
  }
}

export default withTranslation()(SiteForm);
