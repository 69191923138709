import { dispatchPromise, dispatchSuccess, dispatchInit, checkEmptyObject, checkEmptyArray } from "../../utils";
import * as constant from "./constants";
import { TIME_REPORTING_ROUTING_KEY } from "../TimeReporting/constants";
import { ROQ_ROUTING_KEY } from "../ReportingOfQuantities/constants";
import { SITE_INSTALLATION_ROUTING_KEY } from "../InstPlansPage/constants";
import { getDataFromStore } from "../../offline/db";
import { OBJECTSTORE_ROQ_CODE_CATEGORIES } from "../../offline/os/reportingOfQuantities/priceLists";
import { prerequisiteCheck, internetCheck } from "./prerequisites";
import axios from "axios";

const roqPrerequisites = (props) => dispatch => {
    const { online, customerProject, customerTeam, customerCodeListType, t } = props;
    /*
     * The quantity of the reporting module supports offline functionality. 
     * - The user must have associated with a project, a team, and a codeListType, and must have cached his/her selection for both offline and online use. 
     * - Redirect user to:
     *      1. Select a project in case the project is not selected by the user.
     *      2. Select a team if the user is associated with the default team. Note: "No team" is a valid team if no more teams are available in the list to select.  
     *      3. Select a codeListType in case a list is available for the project and selection not cached.
     * - Message if:
     *      A list of customer -> projects are not available to select. 
     *      A list of codeListType is not available to select based on the selected project.
     *      To report a quantity in offline mode, users should cache area, price list.
     */

    if (!checkEmptyObject(customerProject) && !checkEmptyObject(customerTeam) && !checkEmptyObject(customerCodeListType)) {
        if (online) {
            dispatchSuccess(dispatch, constant.MODULE_SELECT, ROQ_ROUTING_KEY, ROQ_ROUTING_KEY, "selectedModule");
        }
        else {
            /*
            * Before we try fetch the RoQ price list for "Project X" we check the cached "Project X" object and read parentProjectId.
            * We then fetch the RoQ price list by parentProjectId instead.
            */
            getDataFromStore(OBJECTSTORE_ROQ_CODE_CATEGORIES, "projectId, codeListTypeId", [customerProject.parentProjectId, customerCodeListType.codeListTypeId])
                .then(codeGroups => {
                    if (checkEmptyArray(codeGroups)) {
                        dispatch({
                            type: constant.MODULE_PREREQUISITES_RESULT,
                            payload: { isFail: true, message: t("text.Prerequisite_message_1") }
                        });
                    }
                    else {
                        dispatchSuccess(dispatch, constant.MODULE_SELECT, ROQ_ROUTING_KEY, ROQ_ROUTING_KEY, "selectedModule");
                    }
                });
        }
    }
    else {
        dispatch(prerequisiteCheck(customerProject, "/customers", "/projects", t("text.Prerequisite_message_2"), t("text.Prerequisite_message_4"), online));

        if (!checkEmptyObject(customerProject)) {
            dispatch(prerequisiteCheck(customerTeam, "", "/teams", "", "", true));
        }

        if (!checkEmptyObject(customerProject) && !checkEmptyObject(customerTeam) && checkEmptyObject(customerCodeListType)) {
            // We then fetch the RoQ price list by parentProjectId instead project id.
            let codeListTypesApi = `/roqpricelists?projectId=${customerProject.parentProjectId}&expand=codelisttypeName&forUserRegistration=true`;
            dispatch(prerequisiteCheck(customerCodeListType, codeListTypesApi, "/codelisttypes", t("text.Prerequisite_message_5"), t("text.Prerequisite_message_4"), online));
        }
    }
}
const timeReportingPrerequisites = (props) => dispatch => {
    const { online, t } = props;
    /*
     * Since the time reporting dont support offline functionality, 
     * so the user can only perform Time Reporting when having the internet. 
     */
    if (!dispatch(internetCheck(online, t("text.Prerequisite_message_4")))) {
        dispatchSuccess(dispatch, constant.MODULE_SELECT, TIME_REPORTING_ROUTING_KEY, TIME_REPORTING_ROUTING_KEY, "selectedModule");
    }
}
const siteInstallationPlanPrerequisites = (props) => dispatch => {
    const { online, customerProject, t } = props;
    /*
     * The site installation plan module supports offline functionality. 
     * - The user must have associated with a project and  must have cached his/her selection for both offline and online use. 
     * - Redirect user to:
     *      1. Select a project in case the project is not selected by the user.
     * - Message if:
     *      A list of customer -> projects are not available to select. 
     */
    if (!checkEmptyObject(customerProject)) {
        dispatchSuccess(dispatch, constant.MODULE_SELECT, SITE_INSTALLATION_ROUTING_KEY, SITE_INSTALLATION_ROUTING_KEY, "selectedModule");
    }
    else {
        dispatch(prerequisiteCheck(customerProject, "/customers", "/projects", t("text.Prerequisite_message_2"), t("text.Prerequisite_message_4"), online));
    }
}
export const redirectOnModule = (module, props) => dispatch => {
    dispatchPromise(dispatch, constant.MODULE_SELECT_INIT, module)
        .then(() => {
            switch (module) {
                case TIME_REPORTING_ROUTING_KEY:
                    dispatch(timeReportingPrerequisites(props));
                    break;
                case ROQ_ROUTING_KEY:
                    dispatch(roqPrerequisites(props));
                    break;
                case SITE_INSTALLATION_ROUTING_KEY:
                    dispatch(siteInstallationPlanPrerequisites(props));
                    break;
                default:
                    dispatchSuccess(dispatch, constant.MODULE_SELECT, module, `${module}`, "selectedModule");
                    break;
            }
        });
};
export const selectModule = module => dispatch => {
    dispatchSuccess(dispatch, constant.MODULE_SELECT, module);
};
export const removeModule = module => dispatch => {
    dispatchInit(dispatch, constant.MODULE_REMOVE);
};
export const fetchHelpAndSupportLinks = () => dispatch => {
    let url = `/helpmenu/`;
    axios
      .get(url)
      .then((res) =>dispatchSuccess(dispatch, constant.HELP_AND_SUPPORT_LINKS, res.data))
};
