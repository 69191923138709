import React, { Fragment } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import ProgressBar from "../../components/ProgressBar";
let InstallationTable = ({ list, selectIPlan, saveOffline, trans }) => (
  <Table className="table">
    <Thead>
      <Tr>
        <Th>{trans("label.Site_ID")}</Th>
        <Th>{trans("label.Area")}</Th>
        <Th>{trans("label.Address")}</Th>
        {/* <Th>Required</Th> */}
        <Th>{trans("text.Edit")}</Th>
        <Th>{trans("text.Offline")}</Th>
      </Tr>
    </Thead>
    {list.length > 0 && (
      <Tbody>
        {list.map(e => (
          <Tr key={e.id}>
            <Td>{e.subPropertyId}</Td>
            <Td>{e.subPropertyArea}</Td>
            <Td>{e.subAddress}</Td>
            {/* <Td className="td-min-width">
              <ProgressBar
                small={true}
                missing={e.noOfReqFieldsMissing}
                max={10}
              />
            </Td> */}
            <Td>
              <button onClick={o => selectIPlan(e)} className="btn btn-primary btn-small">
                <span className="fa fa-edit"></span>
              </button>
            </Td>
            <Td className="text-center">
              {e.offline ? (
                <Fragment>
                  <button className="btn btn-primary btn-small success">
                    <span className="fa fa-check"></span>
                  </button>
                  <span className="ml-2">{trans("text.Available")}</span>
                </Fragment>
              ) : (
                <Fragment>
                  <button onClick={o => saveOffline(e)} className="btn btn-primary btn-small">
                    <span className="fa fa-download"></span>
                  </button>
                  <span className="ml-2">{trans("control.Download")}</span>
                </Fragment>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    )}

    {list.length === 0 && (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center">
            {trans("text.The_search_returned_no_results")}
          </td>
        </tr>
      </tbody>
    )}
  </Table>
);

export default InstallationTable;
