import React from "react";
// import sampleImg from "../sampleimg";
import ImageEdit from "./ImageEdit";
import loadImage from "blueimp-load-image";

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      id: this.props.id,
      error: null,
      uploading: false,
      notes: this.props.notes,
      loading: false,
      editImage: false
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({
        id: this.props.id
      });
    }
    if (prevProps.notes !== this.props.notes) {
      this.setState({
        notes: this.props.notes
      });
    }
  }
  onImageChange = e => {
    const file = e.target.files[0];

    if (file.size > 15000000) {
      return this.setState({
        error: `'${file.name}' é muito grande, escolha um arquivo menor`
      });
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      loadImage(
        reader.result,
        canvas => {
          this.setState(
            {
              img: canvas.toDataURL()
            },
            () => {
              // wait while image reading
              setTimeout(this.compressImage, 500);
            }
          );
        },
        { orientation: true }
      );
    };
  };
  compressImage = () => {
    const standardWidth = 950;
    let canvasWidth, canvasHeight;
    const image = new Image();
    image.src = this.state.img;
    const imageWidth = image.width;
    if (imageWidth < standardWidth) {
      canvasWidth = imageWidth;
      canvasHeight = image.height;
    } else {
      canvasWidth = standardWidth;
      const scaleFactor = standardWidth / imageWidth;
      canvasHeight = image.height * scaleFactor;
    }
    const canvas = this.imageContainer;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext("2d");
    image.onload = () => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };
    // wait while image drawing
    setTimeout(() => {
      const dataURL = this.imageContainer.toDataURL("image/jpeg", 0.5);
      this.setState({ img: dataURL }, () => {
        this.props.handledTouched({ id: this.state.id, index: this.props.index, img: dataURL });
      });

      this.setState({ uploading: true });
    }, 500);
  };
  downloadImage = () => {
    this.setState({
      loading: true
    });
    let { id, fetchImage, instPlan_id, imgGrp_id } = this.props;
    fetchImage(instPlan_id, imgGrp_id, id).then(res => {
      this.setState({
        img: res,
        loading: false
      });
    });
  };

  // to delete an image we are sending id (if any) and it's index
  deleteImage = () => {
    let { id, index } = this.props;
    this.props.deleteImage(id, index);
  };

  openImageForEdit = () => {
    if (this.state.img) this.setState({ editImage: true });
  };
  saveImageIfEdited = (img, isEdit) => {
    this.setState({ editImage: false });
    if (isEdit) {
      this.setState({ img: img }, () => {
        this.props.handledTouched({ id: this.props.id, index: this.props.index, img: img });
        setTimeout(() => {
          this.props.handleSaveImages();
        }, 500);
      });
    }
  };

  render() {
    let { loading, img, id, editImage } = this.state;
    let imgStyle = {
      backgroundImage: img ? `url(${img})` : "none"
    };
    let imgAvailableOnServer = id && !img;

    return (
      <div className="img-upload-box slide">
        <div className="img-box" style={imgStyle} onClick={this.openImageForEdit}>
          <div className="img-num">{`# ${this.props.index + 1}`}</div>
          {!loading ? (
            imgAvailableOnServer ? (
              <DownloadImg onClick={this.downloadImage} />
            ) : !img ? (
              <UploadPrompt />
            ) : null
          ) : (
            <Loading />
          )}
        </div>
        <div className="slider-buttons">
          {!imgAvailableOnServer && (
            <React.Fragment>
              <IconButton icon="camera" type="file" onChange={this.onImageChange} />
              <IconButton icon="trash" onClick={this.deleteImage} />
            </React.Fragment>
          )}
          {img && <IconButton icon="edit" onClick={this.openImageForEdit} />}
        </div>
        {editImage && <ImageEdit className="modal-dialog" img={img} saveImage={this.saveImageIfEdited} />}
        <canvas className="display-none" ref={imageContainer => (this.imageContainer = imageContainer)}></canvas>
      </div>
    );
  }
}
const Loading = () => {
  return <p className="text-center">Loading...</p>;
};
const UploadPrompt = () => (
  <p className="text-center">
    No Image
    <br />
    (Upload an Image)
  </p>
);
const DownloadImg = ({ onClick }) => {
  return (
    <div className="text-center">
      <p>Image Available</p>
      <button onClick={onClick} className="btn-card m-auto">
        <i className="fa fa-cloud-download"></i>
      </button>
    </div>
  );
};

const IconButton = ({ onChange, onClick, icon, type }) => {
  if (type === "file") {
    return (
      <label className="btn-label">
        <span className={`fa fa-${icon}`}></span>
        <input accept="image/*" type="file" onChange={onChange} />
      </label>
    );
  }
  return (
    <button className="btn-card" onClick={onClick}>
      <span className={`fa fa-${icon}`}></span>
    </button>
  );
};
