import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import { checkEmptyObject } from "../../../utils";
import { ErrorBox } from "../../../components/ErrorBox";
import { fetchCustomersAndProjects, fetchCustomers, fetchCustomerProjects, updateSelectedCustomer, updateSelectedProject, removeSelectedProject } from "./actions";
import { MODULE_PREREQUISITES_COMPLETED } from "../../Home/constants";

class ProjectSelectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        let { local, t } = this.props;
        let { selectedCustomer } = local;
        if (!checkEmptyObject(selectedCustomer)) {
            this.props.fetchCustomersAndProjects(selectedCustomer, t);
        }
        else {
            this.props.fetchCustomers(t)
        }
    }
    handleOnCustomerSelect = (customer) => {
        let { online, local, t } = this.props;
        // User can not update customer in offline mode.
        if (!online) {
            this.notifyUser();
        }
        else {
            if (customer.id === local.selectedCustomer.id) return false;
            this.props.updateSelectedCustomer(customer);
            // Remove existing selected project on customer change
            this.props.removeSelectedProject();
            // Fetch the list of projects for selected customer
            this.props.fetchCustomerProjects(customer, t);
        }
    };
    handleOnProjectSelect = (project) => {
        // User can not update project in offline mode.
        if (!this.props.online) {
            this.notifyUser();
        }
        else {
            let { user, local } = this.props;
            let { selectedProject } = local;
            let isChangeRequire = false;
            // Check if selected Project in cache is empty
            if (checkEmptyObject(selectedProject)) {
                isChangeRequire = true
            }
            // Check if existing selected project id is same as new project select id, then we dont need to update redux.
            if (!isChangeRequire) {
                if (selectedProject.id !== project.id) {
                    isChangeRequire = true
                }
            }
            // update selected project
            if (isChangeRequire) {
                this.props.updateSelectedProject(project, user);
            }
        }
    };
    btnContinueOnClick = () => {
        /*
         * If user route to project selection page from a module,
         * then route user back to selected module, else route user to the home page.
         */
        let { selectedModule, prerequisiteState } = this.props.home;
        if (!checkEmptyObject(selectedModule) && prerequisiteState === MODULE_PREREQUISITES_COMPLETED) {
            this.props.redirect(`/${selectedModule}`);
        }
        else {
            this.props.redirect("/home");
        }
    };
    notifyUser = () => {
        toast.error(`${this.props.t("text.You_need_to_be_online_to_perform_this_operation")}`,
            {
                autoClose: 5000,
                pauseOnHover: true
            });
    };
    render() {
        let { local, t } = this.props;
        let { isLoadingCustomers, isLoadingProjects, customers, selectedCustomer, projects, selectedProject, error } = local;
        return (
            <div className="container h-100">
                <div className="row justify-content-center mt-5">
                    <div className="col col-lg-8">
                        <div className="form-box padding-box bg-box">
                            <h5 className="mb-3">{`${t("label.Select")} ${t("label.Project")}`}</h5>
                            {
                                isLoadingCustomers ? <Loader /> :
                                    error ? <ErrorBox /> :
                                        <>
                                            <Dropdown
                                                selected={selectedCustomer.name}
                                                onChange={this.handleOnCustomerSelect}
                                                list={customers}
                                                name={t("label.Customer")}
                                                selectText={`${t("label.Select")} ${t("label.Customer")}`}
                                            />
                                            {
                                                projects.length > 0 &&
                                                (isLoadingProjects ?
                                                    <Loader /> :
                                                    error ? <ErrorBox /> :
                                                        <Dropdown
                                                            selected={selectedProject.name}
                                                            onChange={this.handleOnProjectSelect}
                                                            list={projects}
                                                            name={t("label.Project")}
                                                            selectText={`${t("label.Select")} ${t("label.Project")}`}
                                                        />
                                                )
                                            }
                                        </>
                            }
                            <div className="mt-4">
                                <button disabled={checkEmptyObject(selectedProject)} className="btn btn-primary btn-one" onClick={this.btnContinueOnClick}>
                                    {t("control.Continue")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    online: state.app.online,
    user: state.auth.user,
    local: state.selectProject,
    home: state.modules,
});

const mapDispatchToProps = dispatch => ({
    redirect: path => dispatch(push(path)),
    fetchCustomersAndProjects: (selectedCustomer, t) => dispatch(fetchCustomersAndProjects(selectedCustomer, t)),
    fetchCustomers: (t) => dispatch(fetchCustomers(t)),
    fetchCustomerProjects: (selectedCustomer, t) => dispatch(fetchCustomerProjects(selectedCustomer, t)),
    updateSelectedCustomer: (selectedCustomer) => dispatch(updateSelectedCustomer(selectedCustomer)),
    updateSelectedProject: (selectedProject, user) => dispatch(updateSelectedProject(selectedProject, user)),
    removeSelectedProject: () => dispatch(removeSelectedProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectSelectionPage));
