import { getDataFromStore } from "../../db";

export const OBJECTSTORE_ROQ_ENTRIES = "RoqEntries";

// Function to check offline data available or not. 
export const isRoqOfflineEntryExists = () => {
    return new Promise((resolve) => {
        getDataFromStore(OBJECTSTORE_ROQ_ENTRIES)
            .then(
                entries => {
                    return resolve(entries.some(x => x.isOffline));
                })
            .catch(() => resolve(false))
    })
};



