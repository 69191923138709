/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */
export const MODULE_SELECT = "Modules/MODULE_SELECT";
export const MODULE_SELECT_INIT = "Modules/MODULE_SELECT_INIT";
export const MODULE_REMOVE = "Modules/MODULE_REMOVE";
export const MODULE_PREREQUISITES_RESULT = "Modules/MODULE_PREREQUISITES_RESULT";
export const LOGOUT_CLEAR_MODULE = "Modules/LOGOUT_CLEAR_MODULE";
export const MODULE_PREREQUISITES_NOT_SET = 0;
export const MODULE_PREREQUISITES_INIT = 1;
export const MODULE_PREREQUISITES_COMPLETED = 2;
export const HELP_AND_SUPPORT_LINKS = 'Modules/HELP_AND_SUPPORT_LINKS';