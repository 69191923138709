import * as constant from "./constants";
const initialState = {
  isLoading: false,
  error: false,
  errorMsg: null,
  isLoadingTimeCodes: false,
  timeCodes: [],
  isLoadingJobNumbers: false,
  jobNumbers: [],
  timeReportEntries: [],
  timeReportDayPermissions: {},
  selectedProject: JSON.parse(localStorage.getItem("selectedProject")) || {},
  isLoadingPayPeriods: false,
  timeReportPayPeriods: {}
};

const timeReport = (state = initialState, action) => {
  switch (action.type) {
    case constant.TIME_REPORTING_JOB_NUMBER_INIT_TO_FETCH:
      return {
        ...state,
        isLoadingJobNumbers: true
      };
    case constant.TIME_REPORTING_JOB_NUMBER_FETCH_SUCCESS:
      return {
        ...state,
        isLoadingJobNumbers: false,
        error: false,
        errorMsg: null,
        jobNumbers: action.payload
      };
    case constant.TIME_REPORTING_JOB_NUMBER_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoadingJobNumbers: false,
        error: true,
        errorMsg: action.payload
      };

    case constant.TIME_REPORTING_TIMECODE_INIT_TO_FETCH:
      return {
        ...state,
        isLoadingTimeCodes: true
      };
    case constant.TIME_REPORTING_TIMECODE_FETCH_SUCCESS:
      return {
        ...state,
        isLoadingTimeCodes: false,
        error: false,
        errorMsg: null,
        timeCodes: action.payload
      };
    case constant.TIME_REPORTING_TIMECODE_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoadingTimeCodes: false,
        error: true,
        errorMsg: action.payload
      };
    case constant.TIME_REPORTING_CRUD_INIT:
      return {
        ...state,
        isLoading: true
      };
    case constant.TIME_REPORTING_CRUD:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMsg: null,
        timeReportEntries: action.payload
      };
    case constant.TIME_REPORTING_FETCH_DAY_PERMISSION:
      return {
        ...state,
        timeReportDayPermissions: action.payload
      };
    case constant.TIME_REPORTING_EXCEPTION_WHILE_CRUD:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMsg: action.payload
      };
    case constant.TIME_REPORTING_PAY_PERIODS_INIT_TO_FTECH:
      return {
        ...state,
        isLoadingPayPeriods: true
      };
    case constant.TIME_REPORTING_PAY_PERIODS_FETCH:
      return {
        ...state,
        isLoadingPayPeriods: false,
        error: false,
        errorMsg: null,
        timeReportPayPeriods: action.payload
      };
    case constant.TIME_REPORTING_PAY_PERIODS_EXCEPTION_WHILE_FETCH:
      return {
        ...state,
        isLoadingPayPeriods: false,
        error: true,
        errorMsg: action.payload
      };
    default:
      return state;
  }
};

export default timeReport;
