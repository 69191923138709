import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast } from "react-toastify";

export default class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      availableLanguages: {
        de: { name: "Deutsch", value: "de" },
        en: { name: "English", value: "en" },
        es: { name: "Español", value: "es" },
        no: { name: "Norsk", value: "no" },
        pt: { name: "Português", value: "pt" },
        se: { name: "Svenska", value: "se" },
        sl: { name: "Slovenščina", value: "sl" }
      },
      lng: localStorage.getItem("lng") || "en"
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };
  componentDidMount() {
    this.props.i18n.changeLanguage(this.state.lng);
  }
  changeLanguage = lng => {
    this.setState({ dropdownOpen: true });
    let { online, user, i18n, t } = this.props;
    // Since only the app has a language selection option, 
    // so we need to patch the user's selected language for .net application use.
    // And the user does not suppose to change their language in offline mode.
    if (online) {
      i18n.changeLanguage(lng);
      this.setState({ lng });
      localStorage.setItem("lng", lng);
      // Patch user's selected language to API.
      this.props.updateUserSelectedLanguage(user, lng);
    }
    else {
      toast.error(`${t("text.You_need_to_be_online_to_perform_this_operation")}`,
        {
          autoClose: 5000,
          pauseOnHover: true
        });
    }
  };
  render() {
    let { availableLanguages, lng } = this.state;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret className={"btn-navbar text-left " + this.props.cssClass}>
          Lang <span className="upperText">{availableLanguages[lng].value}</span>
        </DropdownToggle>
        <DropdownMenu className="z-index-top">
          {Object.keys(availableLanguages).map((x, i) => {
            return (
              <DropdownItem key={i} onClick={e => this.changeLanguage(availableLanguages[x].value)}>
                {availableLanguages[x].name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown >
    );
  }
}
