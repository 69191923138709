import React, { Fragment } from "react";
import { checkEmptyObject } from "../../utils";
let QuantityReportingPeriodTotalsTab = ({ isLoading, list, t }) => {
    if (checkEmptyObject(list)) return null;
    return (
        isLoading ?
            <div className="loader"></div> :
            (<table className="table table-theme-1 table-without-bottom-margin">
                {
                    list.totals.filter(x => x.quantity > 0).length === 0 ?
                        <tbody>
                            <tr>
                                <td colSpan="2" align="center">{t("text.No_data")}</td>
                            </tr>
                        </tbody> :
                        <>
                            <thead>
                                <tr>
                                    <th scope="col" className="heading-2">
                                        {t("label.Code")}
                                    </th>
                                    <th scope="col" className="heading-2 align-right">
                                        {t("label.Quantity")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.totals.filter(x => x.quantity > 0).map((entry, index) => (
                                        <Fragment key={index}>
                                            <tr>
                                                <td className="row-theme-1">
                                                    {entry.roqCodeName}</td>
                                                <td className="row-theme-1 align-right">
                                                    {`${entry.quantity} ${entry.unitCode}`}
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))
                                }
                            </tbody>
                        </>
                }
            </table>)
    );
};
export default QuantityReportingPeriodTotalsTab;
