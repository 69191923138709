import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";

import {
  fetchDetails,
  fetchActivities,
  updateActivities,
  fetchImagesMeta,
  updateImageGroupNote,
  updateDetails,
  fetchImage,
  addImage,
  updateImage,
  deleteImage
} from "./actions";
import { TabContent, TabPane } from "reactstrap";
import Tabs from "../../components/Tabs";

import MaterialForm from "./Forms/MaterialForm";
import SiteForm from "./Forms/SiteForm";
import NotesForm from "./Forms/NotesForm";
import StatusForm from "./Forms/StatusForm";
import ImagesForm from "./Forms/ImagesForm";
import Button from "../../components/Button";
import { getSibiling, checkEmptyObject } from "../../utils";

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      error: null,
      detailsList: this.props.detailsList,
      touchedData: {},
      activitiesList: this.props.activitiesList,
      imagesList: this.props.imagesList,
      loading: true,
      uploading: false,
      img: "https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
    };
  }
  updateDetails = () => {
    let { instPlan_id } = this.props;
    let { touchedData } = this.state;
    this.props.updateDetails(instPlan_id, touchedData).then(e => {
      this.setState({ touchedData: {} });
    });
  };

  updateActivities = (parent, child) => {
    let { instPlan_id } = this.props;
    if (child.id) {
      this.props.updateActivities(instPlan_id, parent.id, { statusId: child.id }, parent.timeStamp);
    } else {
      this.props.updateActivities(instPlan_id, parent.id, { remark: child }, parent.timeStamp);
    }
  };

  toggle = tab => {
    let { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });
  };
  componentDidMount() {
    let { instPlan_id, redirect } = this.props;
    if (instPlan_id) {
      // to scroll to top
      window.scrollTo(0, 0);

      // fetch api
      this.props.fetchDetails(instPlan_id);
      this.props.fetchActivities(instPlan_id);
      this.props.fetchImagesMeta(instPlan_id);
    } else {
      redirect("/installationplans");
    }
  }

  updateForm = (name, data) => {
    let originalList = this.props.detailsList;
    let detailsList = { ...this.state.detailsList };
    let touchedData = { ...this.state.touchedData };

    touchedData[name] = detailsList[name] = data;
    /* eslint-disable */
    if (touchedData[name] == originalList[name]) delete touchedData[name];
    /* eslint-enable */
    this.setState({ detailsList, touchedData });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detailsList !== this.props.detailsList) {
      this.setState({ detailsList: this.props.detailsList });
    }
    if (prevProps.activitiesList !== this.props.activitiesList) {
      this.setState({ activitiesList: this.props.activitiesList });
    }
    if (prevProps.imagesList !== this.props.imagesList) {
      this.setState({ imagesList: this.props.imagesList });
    }
  }

  render() {
    let { detailsList, activitiesList, imagesList } = this.state;
    let { activeTab } = this.state;
    let { tabs, details, t } = this.props;
    let { updatingActivity } = details;

    let ButtonList = tabs.filter(e => e.manualSave).map(e => e.id);
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5 m-no-padding-lr">
            <div className="form-box bg-box">
              <Tabs tabs={this.props.tabs} activeTab={activeTab} toggle={this.toggle} />

              <TabContent activeTab={activeTab}>
                <div className="row">
                  <div className="col-lg-8">
                    <h1>{t(getSibiling(tabs, "id", "name", activeTab))}</h1>
                  </div>
                  <div className="col-lg-4 text-right">
                    {ButtonList.includes(activeTab) ? (
                      !checkEmptyObject(this.state.touchedData) ? (
                        <div className="form-group">
                          <label className="label-button">{t("text.Save_your_changes")}</label>
                          <Button
                            onClick={this.updateDetails}
                            loading={details.saving}
                            className="btn btn-primary wm-100"
                          >
                            {t("control.Save")}
                          </Button>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <hr />

                <TabPane tabId={1}>
                  <SiteForm update={this.updateForm} data={detailsList} />
                </TabPane>
                <TabPane tabId={2}>
                  <MaterialForm update={this.updateForm} data={detailsList} />
                </TabPane>
                <TabPane tabId={3}>
                  <ImagesForm {...this.props} data={imagesList} />
                </TabPane>
                <TabPane tabId={4}>
                  <NotesForm update={this.updateForm} data={detailsList} />
                </TabPane>
                <TabPane tabId={5}>
                  <StatusForm update={this.updateActivities} data={activitiesList} loading={updatingActivity} />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  detailsList: state.details.detailsList,
  instPlan_id: state.instPlans.selectedInstPlan.id,
  details: state.details,
  activitiesList: state.details.activitiesList,
  imagesList: state.details.imagesList,
  tabs: [
    {
      name: "label.Site_details",
      id: 1,
      manualSave: true,
      icon: "fa fa-book"
    },
    {
      name: "label.Materials",
      id: 2,
      manualSave: true,
      icon: "fa fa-cube"
    },
    {
      name: "control.Images",
      id: 3,
      manualSave: false,
      icon: "fa fa-photo"
    },
    {
      name: "label.Notes",
      id: 4,
      manualSave: true,
      icon: "fa fa-sticky-note"
    },
    {
      name: "label.Status",
      id: 5,
      manualSave: false,
      icon: "fa fa-hourglass"
    }
  ]
});

const mapDispatchToProps = dispatch => ({
  redirect: path => dispatch(push(path)),
  fetchDetails: instPlan_id => dispatch(fetchDetails(instPlan_id)),
  fetchImagesMeta: instPlan_id => dispatch(fetchImagesMeta(instPlan_id)),
  fetchActivities: instPlan_id => dispatch(fetchActivities(instPlan_id)),
  updateActivities: (instPlan_id, activity_id, data, etag) =>
    dispatch(updateActivities(instPlan_id, activity_id, data, etag)),
  updateDetails: (id, data) => dispatch(updateDetails(id, data)),
  fetchImage: (instPlan_id, imgGrp_id, img_id) => dispatch(fetchImage(instPlan_id, imgGrp_id, img_id)),
  updateImage: (instPlan_id, imgGrp_id, img_id, imageData) =>
    dispatch(updateImage(instPlan_id, imgGrp_id, img_id, imageData)),
  updateImageGroupNote: (instPlan_id, imgGrp_id, data, mainIndex, sectionIndex, groupIndex) =>
    dispatch(updateImageGroupNote(instPlan_id, imgGrp_id, data, mainIndex, sectionIndex, groupIndex)),
  deleteImage: (instPlan_id, imgGrp_id, img_id) => dispatch(deleteImage(instPlan_id, imgGrp_id, img_id)),
  addImage: (instPlan_id, imgGrp_id, imageData, mainIndex, sectionIndex, groupIndex) =>
    dispatch(addImage(instPlan_id, imgGrp_id, imageData, mainIndex, sectionIndex, groupIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DetailsPage));
