import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import CanvasImage from "./CanvasImage";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const ImageEdit = props => {
  const { className, img, saveImage } = props;
  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
    saveImage(img, false);
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={`modal-canvas ${className}`}
        size="lg"
        style={{
          zIndex: 9999,
          maxWidth: 1000,
          maxHeight: 750
        }}
      >
        <ModalBody
          style={{
            padding: ".5rem"
          }}
        >
          <CanvasImage
            windowDimensions={windowDimensions}
            img={img}
            closeCanvas={toggle}
            saveImage={saveImage}
          ></CanvasImage>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ImageEdit;
