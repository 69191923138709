/*
 * constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

export const CUSTOMER_FETCH_INIT = "ModuleInitials/CUSTOMER_FETCH_INIT";
export const CUSTOMER_FETCH_SUCCESS = "ModuleInitials/CUSTOMER_FETCH_SUCCESS";
export const CUSTOMER_EXCEPTION_WHILE_FETCH = "ModuleInitials/CUSTOMER_EXCEPTION_WHILE_FETCH";
export const CUSTOMER_PROJECT_FETCH_INIT = "ModuleInitials/CUSTOMER_PROJECT_FETCH_INIT";
export const CUSTOMER_PROJECT_FETCH_SUCCESS = "ModuleInitials/CUSTOMER_PROJECT_FETCH_SUCCESS";
export const CUSTOMER_PROJECT_EXCEPTION_WHILE_FETCH = "ModuleInitials/CUSTOMER_PROJECT_EXCEPTION_WHILE_FETCH";
export const CUSTOMER_SELECT = "ModuleInitials/CUSTOMER_SELECT";
export const CUSTOMER_PROJECT_SELECT = "ModuleInitials/CUSTOMER_PROJECT_SELECT";
export const CUSTOMER_PROJECT_REMOVE = "ModuleInitials/CUSTOMER_PROJECT_REMOVE";
export const CUSTOMER_REMOVE = "ModuleInitials/CUSTOMER_REMOVE";
export const LOGOUT_CLEAR_PROJECT = "ModuleInitials/LOGOUT_CLEAR_PROJECT";
