import axios from "axios";
import * as constant from "./constants";
import { dispatchPromise, dispatchInit, dispatchError } from "../../../utils";
import { saveDataInStore, getDataFromStore, clearObjectStore } from "../../../offline/db";
import { OBJECTSTORE_CUSTOMERS, OBJECTSTORE_CUSTOMER_PROJECTS } from "../../../offline/os/common/projects";
import { OBJECTSTORE_CODE_LIST_TYPES, assignCodeListTypeToLocalStore } from "../../../offline/os/common/codeListTypes";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

export const fetchCustomersAndProjects = (selectedCustomer, t) => dispatch => {
  // Initiating action which will display the loader image on UI
  dispatchInit(dispatch, constant.CUSTOMER_FETCH_INIT);
  // Generating API urls to get the customers and projects
  let urlTofetchCustomers = '/customers';
  let urlToFetchProjects = `/projects?customerId=${selectedCustomer.id}&isActive=true`;
  // Setup the requests to axios
  const customersRequest = axios.get(urlTofetchCustomers);
  const projectsRequest = axios.get(urlToFetchProjects);
  // axios class which will execute API and execute a promise on success.
  axios
    .all([customersRequest, projectsRequest])
    .then(axios.spread((...res) => {
      let customers = res[0].data;
      let projects = res[1].data;
      // Notify user, when the user is not linked with any customer's project
      if (projects.length === 0) {
        toast.error(`${t("text.Invalid")} ${t("label.Project")}!`);
      }
      // Save the customers and projects to the offline store.
      saveDataInStore(OBJECTSTORE_CUSTOMERS, customers);
      saveDataInStore(OBJECTSTORE_CUSTOMER_PROJECTS, projects);
      // Provide customers and projects to component's Redux store to map it on the UI
      dispatchPromise(dispatch, constant.CUSTOMER_FETCH_SUCCESS, customers)
        .then(dispatchPromise(dispatch, constant.CUSTOMER_PROJECT_FETCH_SUCCESS, projects))
    }))
    .catch(error => {
      // API fails to succeed request, so fetch customers and projects from offline store and update the component's Redux store to map it on the UI.
      getDataFromStore(OBJECTSTORE_CUSTOMERS)
        .then(customers => {
          getDataFromStore(OBJECTSTORE_CUSTOMER_PROJECTS)
            .then(projects => {
              // Provide customers and projects to component's Redux store to map it on the UI
              dispatchPromise(dispatch, constant.CUSTOMER_FETCH_SUCCESS, customers)
                .then(dispatchPromise(dispatch, constant.CUSTOMER_PROJECT_FETCH_SUCCESS, projects))
            })
        })
        .catch(err => dispatchError(dispatch, constant.CUSTOMER_EXCEPTION_WHILE_FETCH, error));
    })
};
export const fetchCustomers = (t) => dispatch => {
  // Initiating action which will display the loader image on UI
  dispatchInit(dispatch, constant.CUSTOMER_FETCH_INIT);
  // Generating API urls to get the customers
  let urlTofetchCustomers = '/customers';
  // axios class which will execute API and execute a promise on success.
  axios
    .get(urlTofetchCustomers)
    .then(res => {
      let customers = res.data;
      // Notify user, when the user is not linked with any customer's project
      // And redirecting user to home page.
      if (customers.length === 0) {
        toast.error(`${t("text.Invalid")} ${t("label.Project")}!`);
        dispatch(push("/home"));
      }
      // Save the customers to the offline store.
      saveDataInStore(OBJECTSTORE_CUSTOMERS, customers);
      // Provide customers to component's Redux store to map it on the UI
      dispatchPromise(dispatch, constant.CUSTOMER_FETCH_SUCCESS, customers)
    })
    .catch(error => {
      // API fails to succeed request, so fetch customers from offline store and update the component's Redux store to map it on the UI.
      getDataFromStore(OBJECTSTORE_CUSTOMERS)
        .then(customers => {
          // Provide customers to component's Redux store to map it on the UI
          dispatchPromise(dispatch, constant.CUSTOMER_FETCH_SUCCESS, customers)
        })
        .catch(err => dispatchError(dispatch, constant.CUSTOMER_EXCEPTION_WHILE_FETCH, error));
    })
};
export const fetchCustomerProjects = (selectedCustomer, t) => dispatch => {
  // Initiating action which will display the loader image on UI
  dispatchInit(dispatch, constant.CUSTOMER_PROJECT_FETCH_INIT);
  // Generating API urls to get the projects
  let urlToFetchProjects = `/projects?customerId=${selectedCustomer.id}&isActive=true`;
  // axios class which will execute API and execute a promise on success.
  axios
    .get(urlToFetchProjects)
    .then(res => {
      let projects = res.data;
      // Notify user, when the user is not linked with any customer's project
      if (projects.length === 0) {
        toast.error(`${t("text.Invalid")} ${t("label.Project")}!`);
      }
      // Save the projects to the offline store.
      saveDataInStore(OBJECTSTORE_CUSTOMER_PROJECTS, projects);
      // Provide projects to component's Redux store to map it on the UI
      dispatchPromise(dispatch, constant.CUSTOMER_PROJECT_FETCH_SUCCESS, projects)
    })
    .catch(error => {
      // API fails to succeed request, so fetch projects from offline store and update the component's Redux store to map it on the UI.
      getDataFromStore(OBJECTSTORE_CUSTOMER_PROJECTS)
        .then(projects => {
          // Provide projects to component's Redux store to map it on the UI
          dispatchPromise(dispatch, constant.CUSTOMER_PROJECT_FETCH_SUCCESS, projects)
        })
        .catch(err => dispatchError(dispatch, constant.CUSTOMER_PROJECT_EXCEPTION_WHILE_FETCH, error));
    })
};
export const updateSelectedCustomer = (selectedCustomer) => dispatch => {
  dispatchPromise(dispatch, constant.CUSTOMER_SELECT, selectedCustomer);
};
export const updateSelectedProject = (selectedProject, user) => dispatch => {
  //On project change fetching codeListTypes, Since that depending on project Id
  // Get a selected code type and cached them for further use in related components
  let codeListTypesApiUrl = `/roqpricelists?projectId=${selectedProject.parentProjectId}&expand=codelisttypeName&forUserRegistration=true`;
  // Return anothor axios class which will execute API and execute a promise on success.
  axios
    .get(codeListTypesApiUrl)
    .then((res) => {
      let codeListTypes = res.data;
      codeListTypes = codeListTypes.map(x => x = { ...x, name: x.codeListTypeName });
      // Save the code types to the offline store.
      clearObjectStore(OBJECTSTORE_CODE_LIST_TYPES).then(() => saveDataInStore(OBJECTSTORE_CODE_LIST_TYPES, codeListTypes));
      assignCodeListTypeToLocalStore(user.codeListTypeId, codeListTypes, dispatch);
      dispatchPromise(dispatch, constant.CUSTOMER_PROJECT_SELECT, selectedProject);
    })
};
export const removeSelectedProject = () => dispatch => {
  dispatchInit(dispatch, constant.CUSTOMER_PROJECT_REMOVE);
};


