import React from "react";
import InputBox from "../../../components/Form/InputBox";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Button";
import Date from "../../../components/DateInput";
import { withTranslation } from "react-i18next";
import { checkEmptyObject } from "../../../utils";

class QuantityReportingSiteForm extends React.Component {
    render() {
        // properties
        let { isLoading, dateId, initialDate, maxDate, quantity, notes, disabled,
            selectedProjectArea, selectedProjectAreaSection, selectedCodeGroup, selectedCodeGroupCode,
            projectAreas, projectAreaSections, codeGroups, codeGroupCodes, isEntryInserting, isTriggerByDetector, t } = this.props;
        // events
        let { onDateSelect, onProjectAreaSelect, onProjectAreaSectionSelect, onCodeGroupSelect, onCodeGroupCodeSelect, onUpdate, onInsert } = this.props
        /* 
        * When new entries inserted offline and from a background process posting those entries to server then 
        * we do not need to show loader image on add button
        */
        if (isTriggerByDetector) isEntryInserting = false;

        return (
            isLoading ?
                <div className="loader"></div> :
                <form className="center-third quantity-reporting">
                    <Date
                        id={dateId}
                        label={t("label.Date")}
                        onDateSelect={onDateSelect}
                        initialDate={initialDate}
                        maxDate={maxDate}
                    ></Date>
                    {
                        projectAreas.length > 0 &&
                        <Dropdown
                            selected={selectedProjectArea.name}
                            list={projectAreas}
                            name={t("label.Area")}
                            onChange={onProjectAreaSelect}
                            selectText={`${t("label.Select")} ${t("label.Area")}`}
                        />
                    }
                    {
                        selectedProjectArea.hasSections > 0 &&
                        <Dropdown
                            selected={selectedProjectAreaSection.name}
                            list={projectAreaSections}
                            name={t("label.Section")}
                            onChange={onProjectAreaSectionSelect}
                            selectText={`${t("label.Select")} ${t("label.Section")}`}
                        />
                    }
                    <hr style={{ marginBottom: ".5rem" }} />
                    {
                        codeGroups.length > 0 &&
                        <>
                            <Dropdown
                                selected={selectedCodeGroup.name}
                                list={codeGroups}
                                name={t("label.Code_category")}
                                onChange={onCodeGroupSelect}
                                selectText={`${t("label.Select")} ${t("label.Code_category")}`}
                            />
                            {
                                !checkEmptyObject(selectedCodeGroup) && codeGroupCodes.length > 0 &&
                                <>
                                    <Dropdown
                                        selected={selectedCodeGroupCode.name}
                                        list={codeGroupCodes}
                                        name={t("label.Code")}
                                        onChange={onCodeGroupCodeSelect}
                                        customClassName={selectedCodeGroupCode.mapIntegration ? "blue-border" : ""}
                                        selectText={`${t("label.Select")} ${t("label.Code")}`}
                                    />
                                    {
                                        selectedCodeGroupCode.mapIntegration &&
                                        <span className="map-integration-note"><i className="fa fa-globe" aria-hidden="true"></i> {t("label.Display_note_when_map_integration_is_true")}</span>
                                    }
                                </>
                            }

                        </>
                    }
                    <div className="row">
                        <>
                            <div className="col">
                                <InputBox
                                    type="number"
                                    heading={t("label.Quantity")}
                                    placeholder={t("label.Quantity")}
                                    name="txtQuantity"
                                    value={quantity}
                                    update={onUpdate}
                                />
                            </div>
                            <div className="col" style={{ marginTop: 35 }}>
                                <Button
                                    loading={isEntryInserting}
                                    disabled={disabled}
                                    className="btn btn-primary"
                                    onClick={(button) => onInsert()}>{t("control.Add")}</Button>
                            </div>
                        </>
                    </div>
                    <InputBox
                        heading={t("label.Notes")}
                        placeholder={t("label.Notes")}
                        name="txtNotes"
                        textarea={true}
                        value={notes}
                        update={onUpdate}
                    />
                </form>
        );
    }
}

export default withTranslation()(QuantityReportingSiteForm);
