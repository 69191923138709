import React from "react";
import InputBox from "../../../components/Form/InputBox";
import { withTranslation } from "react-i18next";

class MaterialForm extends React.Component {
  render() {
    let { data, t } = this.props;
    if (!data) return null;
    return (
      <form className="center-third" onSubmit={this.handleSubmit}>
        <InputBox
          heading={t("label.Length_sector")+' 1'}
          placeholder={t("label.Length_sector")+' 1'}
          name="inMaterialLength1"
          value={data.inMaterialLength1}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Length_sector")+' 2'}
          placeholder={t("label.Length_sector")+' 2'}
          name="inMaterialLength2"
          value={data.inMaterialLength2}
          update={this.props.update}
        />
        <InputBox
          heading={t("label.Length_sector") + ' 3'}
          placeholder={t("label.Length_sector") + ' 3'}
          name="inMaterialLength3"
          value={data.inMaterialLength3}
          update={this.props.update}
        />
        <div className="doc-box">
          <label>{t("label.Documents_and_files")}</label>
          <div>
            {data.documents.length !== 0 ? (
              data.documents.map((o, i) => (
                <a href={o.url} target="_blank" rel="noopener noreferrer" key={i}>
                  <i className="fa fa-file-text"></i>
                  {o.name}
                </a>
              ))
            ) : (
              <div className="label-heading">
                <p>-------</p>
              </div>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(MaterialForm);
